import { css, cx } from "@emotion/css";
import { Alert, Button, Form, Input, Space } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import { formValidationSchemas } from "../forms/validation";

export interface IPolicyStatusFormValues {
  reason: string;
}

export interface IPolicyStatusFormProps {
  isSubmitting?: boolean;
  error?: string;
  onSave: (value: IPolicyStatusFormValues) => void | Promise<void>;
  onCancel: () => void;
}

const initialValues: IPolicyStatusFormValues = {
  reason: "",
};

const PolicyStatusForm: React.FC<IPolicyStatusFormProps> = (props) => {
  const { error, isSubmitting, onSave, onCancel } = props;
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      reason: formValidationSchemas.strRequired,
    }),
    onSubmit: (data) => {
      onSave({
        reason: data.reason,
      });
    },
  });

  const reasonNode = (
    <Form.Item
      required
      label="Reason"
      help={
        formik.touched?.reason &&
        formik.errors?.reason && (
          <FunErrorMessages message={formik.errors.reason}>
            <FormError error={formik.errors.reason} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        autoFocus
        name="reason"
        value={formik.values.reason}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter your reason"
        disabled={isSubmitting}
        autoSize={{
          minRows: 2,
          maxRows: 4,
        }}
      />
    </Form.Item>
  );

  return (
    <div className={cx(formClasses.body, css({ padding: 0 }))}>
      {error && (
        <Form.Item>
          <Alert message={error} type="error" />
        </Form.Item>
      )}
      <form onSubmit={formik.handleSubmit}>
        {reasonNode}
        <Form.Item
          className={css({ marginTop: "32px !important", marginBottom: "0" })}
        >
          <Space size={"middle"}>
            <Button
              danger
              htmlType="button"
              loading={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </form>
    </div>
  );
};

export default PolicyStatusForm;
