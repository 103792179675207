import { ICountryInfo } from "../../definitions/countries";
import { ILowercasedEnum } from "../../definitions/utils";
import { netAddrs } from "../addr";
import { invokeEndpoint } from "../invokeREST";

const sectionURL = `${netAddrs.serverAddr}/resources`;
const getCountryCodesURL = `${sectionURL}/GetCountryCode`;
const getCountryCodesObjURL = `${sectionURL}/GetCountryCodeObj`;
const getCountryStatesAndCitiesURL = `${sectionURL}/GetCountryStateCities`;
const getStatusTypesURL = `${sectionURL}/GetStatusType`;
const getTicketRelatedServicesURL = `${sectionURL}/GetTicketRelatedServices`;
const getDashboardStatisticFilterURL = `${sectionURL}/GetDateFreqType`;

export type IGetCountryCodesResult = Array<string>;

async function getCountryCodes(): Promise<IGetCountryCodesResult> {
  const result = await invokeEndpoint<IGetCountryCodesResult>({
    path: getCountryCodesURL,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export interface IGetCountryCodesObjProps {
  query?: string;
}

export type IGetCountryCodesObjResult = Array<ICountryInfo>;

async function getCountryCodesObj(
  props: IGetCountryCodesObjProps = {}
): Promise<IGetCountryCodesObjResult> {
  const params = new URLSearchParams();

  if (props.query) {
    params.set("query", props.query);
  }

  const fullPath = props.query
    ? `${getCountryCodesObjURL}?${params.toString()}`
    : getCountryCodesObjURL;

  const result = await invokeEndpoint<IGetCountryCodesObjResult>({
    path: fullPath,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export interface IGetCountryStatesAndCitiesProps {
  countryCode: string;
  state?: string;
}

export type IGetCountryStatesAndCitiesResult = Array<string>;

async function getCountryStatesAndCities(
  props: IGetCountryStatesAndCitiesProps
): Promise<IGetCountryStatesAndCitiesResult> {
  const params = new URLSearchParams();
  params.set("country", props.countryCode);

  if (props.state) {
    params.set("state", props.state);
  }

  const fullPath = `${getCountryStatesAndCitiesURL}?${params.toString()}`;
  const result = await invokeEndpoint<IGetCountryStatesAndCitiesResult>({
    path: fullPath,
    method: "GET",
    defaultValue: [],
  });

  result.sort();
  return result;
}

export type IGetStatusTypesResult = Array<string>;

async function getStatusTypes(): Promise<IGetStatusTypesResult> {
  return invokeEndpoint<IGetStatusTypesResult>({
    path: getStatusTypesURL,
    method: "GET",
    defaultValue: [],
  });
}

export type IGetTicketRelatedServicesResult = Array<string>;

async function getTicketRelatedServices(): Promise<IGetTicketRelatedServicesResult> {
  return invokeEndpoint<IGetTicketRelatedServicesResult>({
    path: getTicketRelatedServicesURL,
    method: "GET",
    defaultValue: [],
  });
}

async function getDashboardStatisticFilter(): Promise<ILowercasedEnum[]> {
  return invokeEndpoint<ILowercasedEnum[]>({
    path: getDashboardStatisticFilterURL,
    method: "GET",
    defaultValue: [],
  });
}

export default class ResourcesAPI {
  public static getCountryCodes = getCountryCodes;
  public static getCountryCodesObj = getCountryCodesObj;
  public static getCountryStatesAndCities = getCountryStatesAndCities;
  public static getStatusTypes = getStatusTypes;
  public static getTicketRelatedServices = getTicketRelatedServices;
  public static getDashboardStatisticFilter = getDashboardStatisticFilter;
}
