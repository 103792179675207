import { useRequest } from "ahooks";
import React from "react";
import GroupPolicyAPI from "../../api/endpoints/group-policy";
import { IRolePermission } from "../../definitions/group-policy";

function useRolePermissions(roleType: string) {
  const [permissions, setRolePermissions] = React.useState<IRolePermission[]>(
    []
  );

  const getRolePermissions = React.useCallback(async (argRoleType: string) => {
    const permissionsResult = await GroupPolicyAPI.getRolePermissions({
      roleType: argRoleType,
    });

    setRolePermissions(permissionsResult);
  }, []);

  const {
    loading: loadingPermissions,
    error: loadPermissionsError,
    run: loadPermissions,
  } = useRequest(getRolePermissions, { manual: true });

  React.useEffect(() => {
    loadPermissions(roleType);
  }, [roleType, loadPermissions]);

  return {
    permissions,
    loadingPermissions,
    loadPermissionsError,
    loadPermissions,
  };
}

export default useRolePermissions;
