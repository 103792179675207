import useRequest from "@ahooksjs/use-request";
import { Space, Typography } from "antd";
import DOMPurify from "dompurify";
import React from "react";
import TicketAPI from "../../api/endpoints/ticket";
import { ITicket } from "../../definitions/ticket";
import PageError from "../utils/PageError";
import { appClasses } from "../utils/theme";
import TicketActivities from "./TicketActivities";
import TicketActivityFormContainer from "./TicketActivityFormContainer";
import TicketSecondaryDetails from "./TicketSecondaryDetails";

export interface ITicketProps {
  ticket: ITicket;
  onCompleteUpdateStatus: () => void;
}

const Ticket: React.FC<ITicketProps> = (props) => {
  const { ticket, onCompleteUpdateStatus } = props;
  const getTicketActivities = React.useCallback(() => {
    return TicketAPI.getTicketActivities({ ticketId: ticket.TicketId });
  }, [ticket]);

  const {
    loading: loadingTicketActivities,
    error: ticketActivitiesError,
    data: ticketActivities,
    run: reloadTicketActivities,
  } = useRequest(getTicketActivities);

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", padding: "16px" }}
    >
      <Typography.Title level={5}>{ticket.Subject}</Typography.Title>
      {ticket.Message && (
        <Typography.Text className={appClasses.paragraph}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(ticket.Message),
            }}
          />
        </Typography.Text>
      )}
      <TicketSecondaryDetails
        ticket={ticket}
        onCompleteUpdateStatus={onCompleteUpdateStatus}
      />
      <Typography.Title level={5} style={{ margin: 0 }}>
        Activities
      </Typography.Title>
      {ticketActivitiesError ? (
        <PageError
          messageText={ticketActivitiesError.message}
          actions={[
            {
              key: "reload",
              text: "Reload Ticket Activities",
              onClick: reloadTicketActivities,
            },
          ]}
        />
      ) : (
        <TicketActivities
          loading={loadingTicketActivities}
          activities={ticketActivities || []}
        />
      )}
      <Typography.Title level={5} style={{ margin: 0 }}>
        Reply
      </Typography.Title>
      <TicketActivityFormContainer
        orgId={ticket.OrganizationId}
        ticketId={ticket.TicketId}
        onCompleteSubmitActivity={reloadTicketActivities}
      />
    </Space>
  );
};

export default Ticket;
