import { useRequest } from "ahooks";
import React from "react";
import StatusForm, { IStatusFormValues } from "./StatusForm";

export interface IStatusFormContainerProps {
  name: string;
  initialStatus?: string | null;
  showStatusReason?: boolean;
  showStatusReasonTitle?: string;
  submitFn: (props: IStatusFormValues) => void;
  onCancel: () => void;
}

const StatusFormContainer: React.FC<IStatusFormContainerProps> = (props) => {
  const {
    name,
    initialStatus,
    showStatusReason,
    showStatusReasonTitle,
    onCancel,
    submitFn,
  } = props;

  const { loading, error, run } = useRequest(submitFn, { manual: true });

  return (
    <StatusForm
      showStatusReason={showStatusReason}
      showStatusReasonTitle={showStatusReasonTitle}
      isSubmitting={loading}
      error={error?.message}
      onSave={run}
      name={name}
      onCancel={onCancel}
      initialStatus={initialStatus}
    />
  );
};

export default React.memo(StatusFormContainer);
