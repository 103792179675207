import { appConstants } from "../definitions/utils";
import { AppLocalStorage, AppSessionStorage } from "./storage";

function getKey(key: string) {
  return `${appConstants.appShortName}.session.${key}`;
}

export const sessionVariables = {
  token: getKey("t"),
  refreshToken: getKey("refreshToken"),
};

// a list of keys to delete from storage when the user logouts
const userAttachedVariables = [
  sessionVariables.token,
  sessionVariables.refreshToken,
];

function getUserToken() {
  return AppLocalStorage.getItem(sessionVariables.token, "string") as
    | string
    | undefined;
}

function getRefreshToken() {
  return AppLocalStorage.getItem(sessionVariables.refreshToken, "string") as
    | string
    | undefined;
}

function saveUserToken(token: string) {
  AppLocalStorage.setItem(sessionVariables.token, token);
}

function saveRefreshToken(token: string) {
  AppLocalStorage.setItem(sessionVariables.refreshToken, token);
}

function deleteUserToken() {
  AppLocalStorage.removeItem(sessionVariables.token);
}

function saveTokenIfExists(token: string) {
  if (getUserToken()) {
    saveUserToken(token);
  }
}

function saveRefreshTokenIfExists(token: string) {
  if (getRefreshToken()) {
    saveRefreshToken(token);
  }
}

function deleteUserVariables() {
  userAttachedVariables.forEach((key) => AppLocalStorage.removeItem(key));
  userAttachedVariables.forEach((key) => AppSessionStorage.removeItem(key));
}

export default class SessionStorageFuncs {
  public static getUserToken = getUserToken;
  public static getRefreshToken = getRefreshToken;
  public static saveUserToken = saveUserToken;
  public static saveRefreshToken = saveRefreshToken;
  public static deleteUserToken = deleteUserToken;
  public static saveTokenIfExists = saveTokenIfExists;
  public static saveRefreshTokenIfExists = saveRefreshTokenIfExists;
  public static deleteUserVariables = deleteUserVariables;
}
