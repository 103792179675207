import { Modal, Switch } from "antd";
import React from "react";
import PolicyStatusForm, { IPolicyStatusFormValues } from "./PolicyStatusForm";

export interface IPolicyToggleStatusProps {
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  status: boolean;
  onChange: (value: boolean, reason: string) => Promise<void>;
}

const PolicyToggleStatus: React.FC<IPolicyToggleStatusProps> = (props) => {
  const { loading, status, disabled, error, onChange } = props;
  const [showReasonForm, setShowReasonForm] = React.useState(false);
  const [value, setValue] = React.useState(status);
  const intermediateOnChange = React.useCallback((selectedValue: boolean) => {
    setValue(selectedValue);
    setShowReasonForm(true);
  }, []);

  const onCloseReasonForm = React.useCallback(() => {
    setValue(status);
    setShowReasonForm(false);
  }, [status]);

  const onSave = React.useCallback(
    async (input: IPolicyStatusFormValues) => {
      await onChange(value, input.reason);
      setShowReasonForm(false);
    },
    [value, onChange]
  );

  const reasonFormNode = showReasonForm ? (
    <Modal
      destroyOnClose
      visible={showReasonForm}
      title="Update Policy Status"
      footer={null}
      onCancel={onCloseReasonForm}
    >
      <PolicyStatusForm
        onCancel={onCloseReasonForm}
        onSave={onSave}
        isSubmitting={loading}
        error={error}
      />
    </Modal>
  ) : null;

  return (
    <React.Fragment>
      {reasonFormNode}
      <Switch
        loading={loading}
        checked={status}
        onChange={intermediateOnChange}
        disabled={disabled}
      />
    </React.Fragment>
  );
};

export default PolicyToggleStatus;
