import React from "react";
import { Select, Typography } from "antd";
import useCountries from "../hooks/useCountries";
import InlineLoading from "../utils/InlineLoading";
import FormError from "./FormError";
import { ICountryInfo } from "../../definitions/countries";

export interface ICountryInputProps {
  useCountryCode?: boolean;
  value?: string;
  disabled?: boolean;
  onChange: (value: ICountryInfo) => void;
  onInit: (value: ICountryInfo) => void;
}

const CountryInput: React.FC<ICountryInputProps> = (props) => {
  const { value, disabled, onChange, onInit } = props;
  const { fetching, error, countries } = useCountries();
  const [initialized, setInit] = React.useState(false);

  const [optionsNode, countriesMap] = React.useMemo(() => {
    const map: Record<string, ICountryInfo> = {};
    const nodes = countries?.map((country) => {
      map[country.Code] = country;
      return (
        <Select.Option
          key={country.Code}
          value={country.Code}
          title={country.Name}
        >
          <Typography.Text>
            <Typography.Text type="secondary">({country.Code})</Typography.Text>{" "}
            {country.Name}
          </Typography.Text>
        </Select.Option>
      );
    });

    return [nodes, map];
  }, [countries]);

  React.useEffect(() => {
    if (value && !fetching && !error && !initialized) {
      setInit(true);
      const country = countries?.find((country) => country.Name === value);

      if (country) {
        onInit(country);
      }
    }
  }, [value, initialized, countries, fetching, error, onInit]);

  if (fetching) {
    return <InlineLoading />;
  } else if (error) {
    return <FormError>{error}</FormError>;
  }

  return (
    <Select
      showSearch
      disabled={disabled}
      value={value}
      onChange={(val) => {
        onChange(countriesMap[val]);
      }}
      placeholder="Select country"
      optionFilterProp="title"
      filterOption={(input, option) =>
        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.title.toLowerCase().localeCompare(optionB.title.toLowerCase())
      }
    >
      {optionsNode}
    </Select>
  );
};

export default CountryInput;
