import React from "react";
import PageError from "../utils/PageError";
import PageLoading from "../utils/PageLoading";
import Tracker from "./Tracker";
import useRequest from "@ahooksjs/use-request";
import PageNothingFound from "../utils/PageNothingFound";
import TrackerControls from "./TrackerControls";
import useSectionCurrentPage from "../hooks/useSectionCurrentPage";
import { appLoggedInPaths } from "../../definitions/paths";
import TrackerAPI from "../../api/endpoints/tracking";

const TrackerRoot: React.FC<{}> = (props) => {
  const { selectedItemId, gotoSelectedItem } = useSectionCurrentPage({
    baseURL: appLoggedInPaths.tracking,
    hasCreateForm: false,
    hasSelectedItemPage: true,
    hasUpdateForm: false,
    isComponentReady: true,
  });

  const onSearch = React.useCallback((input: string) => {
    if (input) {
      return TrackerAPI.getTrackingInformation({
        trackingId: input,
      });
    }

    throw new Error("Please enter a package's tracking number");
  }, []);

  const { loading, error, data, run } = useRequest(onSearch, { manual: true });

  React.useEffect(() => {
    if (selectedItemId) {
      run(selectedItemId);
    }
  }, [selectedItemId, run]);

  let contentNode: React.ReactNode = (
    <PageNothingFound messageText="Enter a package's tracking number to begin" />
  );

  if (error) {
    contentNode = (
      <PageError
        messageText={error?.message || "Error loading tracking information"}
        actions={[{ text: "Retry", onClick: selectedItemId && onSearch }]}
      />
    );
  } else if (loading) {
    contentNode = <PageLoading />;
  } else if (data && data[0] && selectedItemId) {
    contentNode = <Tracker trackingNumber={selectedItemId} tracker={data[0]} />;
  }

  return (
    <div>
      <TrackerControls
        disabled={loading}
        onEnterSearchQuery={gotoSelectedItem}
      />
      {contentNode}
    </div>
  );
};

export default TrackerRoot;
