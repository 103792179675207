import { Modal } from "antd";
import React from "react";
import StatusInput from "../../forms/StatusInput";
import { IStatusFormValues } from "./StatusForm";
import StatusFormContainer from "./StatusFormContainer";

export interface ISubmitStatusFnProps extends IStatusFormValues {
  id: string;
}

export interface IStatusProps {
  id: string;
  name: string;
  status: string;
  statusColor: string;
  showStatusReason?: boolean;
  showStatusReasonTitle?: string;
  modalTitle?: string;
  submitFn: (props: ISubmitStatusFnProps) => void;
  onCompleteUpdateStatus: () => void;
}

const Status: React.FC<IStatusProps> = (props) => {
  const {
    name,
    status,
    id,
    statusColor,
    showStatusReason,
    showStatusReasonTitle,
    modalTitle,
    submitFn,
    onCompleteUpdateStatus,
  } = props;

  const [showReasonInput, setReasonInputVisibility] = React.useState(false);
  const [cachedStatus, setCachedStatus] = React.useState<string | null>(null);

  const onCancelUpdate = React.useCallback(() => {
    setReasonInputVisibility(false);
    setCachedStatus(null);
  }, []);

  const onInitialSelectStatus = React.useCallback((value: string) => {
    setCachedStatus(value);
    setReasonInputVisibility(true);
  }, []);

  const internalSubmitFn = React.useCallback(
    async (submitProps: IStatusFormValues) => {
      await submitFn({ ...submitProps, id });
      setCachedStatus(null);
      setReasonInputVisibility(false);
      onCompleteUpdateStatus();
    },
    [id, onCompleteUpdateStatus, submitFn]
  );

  return (
    <React.Fragment>
      {showReasonInput ? (
        <Modal
          destroyOnClose
          visible={showReasonInput}
          title={modalTitle || "Update Status"}
          footer={null}
          onCancel={onCancelUpdate}
        >
          <StatusFormContainer
            onCancel={onCancelUpdate}
            initialStatus={cachedStatus}
            name={name}
            submitFn={internalSubmitFn}
            showStatusReason={showStatusReason}
            showStatusReasonTitle={showStatusReasonTitle}
          />
        </Modal>
      ) : (
        <StatusInput
          useDropdown
          onChange={onInitialSelectStatus}
          value={status}
          statusColor={statusColor}
        />
      )}
    </React.Fragment>
  );
};

export default Status;
