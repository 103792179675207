import useRequest from "@ahooksjs/use-request";
import { Select, Form } from "antd";
import { defaultTo } from "lodash";
import React from "react";
import TicketAPI from "../../api/endpoints/ticket";
import { CACHE_STALE_TIME, DataCacheKeys } from "../utils/cache-keys";

export interface ITicketsSelectOrganizationProps {
  disabled?: boolean;
  orgId?: string;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
}

const TicketsSelectOrganization: React.FC<ITicketsSelectOrganizationProps> = (
  props
) => {
  const { disabled, orgId, style, onChange } = props;
  const { data, error, loading } = useRequest(
    TicketAPI.getTicketOrganizations,
    {
      cacheKey: DataCacheKeys.Ticket_SelectOrganization,
      staleTime: CACHE_STALE_TIME,
    }
  );

  const organizations = data || [];

  return (
    <Form.Item
      validateStatus={error && "error"}
      help={error}
      style={{ ...defaultTo(style, {}), margin: 0 }}
    >
      <Select
        allowClear
        showSearch
        placeholder="Select organization"
        optionFilterProp="title"
        loading={loading}
        disabled={disabled || !!error}
        value={orgId}
        onChange={onChange}
        filterOption={(input, option) =>
          option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {organizations.map((org) => (
          <Select.Option
            key={org.OrganizationId}
            value={org.OrganizationId}
            title={org.OrganizationName}
          >
            {org.OrganizationName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default TicketsSelectOrganization;
