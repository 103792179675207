import { List, Skeleton } from "antd";
import React from "react";

const MAX_ROW_COUNT = 2;

export interface IListSkeletonProps {
  rowCount?: number;
}

const ListSkeleton: React.FC<IListSkeletonProps> = (props) => {
  const { rowCount } = props;
  return (
    <List
      split
      bordered
      itemLayout="horizontal"
      dataSource={Array(
        rowCount && rowCount < MAX_ROW_COUNT ? rowCount : MAX_ROW_COUNT
      ).fill(1)}
      renderItem={(_, index) => {
        return (
          <List.Item key={index}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </List.Item>
        );
      }}
    />
  );
};

export default ListSkeleton;
