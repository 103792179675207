import {
  IAdminCompany,
  ICreateAdminCompanyInput,
  IUpdateAdminCompanyInput,
} from "../../definitions/company";
import {
  IAuthUser,
  ISignedInUser,
  IUserPasswordChangeInput,
  IUpdateAdminUserInput,
} from "../../definitions/user";
import { netAddrs } from "../addr";
import { invokeEndpoint, invokeEndpointWithAuth } from "../invokeREST";
import { IEndpointMessageResult } from "../types";

// signup
const signupSectionURL = `${netAddrs.serverAddr}/MoveBotSignUp`;
const signupURL = `${signupSectionURL}/MoveBotAccountSetup`;

// authentication
const authSectionURL = `${netAddrs.serverAddr}/Authentication`;
const forgotPasswordURL = `${authSectionURL}/InitiateForgotPwd`;
const loginURL = `${authSectionURL}/Login`;
const refreshSessionURL = `${authSectionURL}/RefreshSessionLogin`;
const changePasswordWithCodeURL = `${authSectionURL}/UpdateForgotPwd`;
const getProfileURL = `${authSectionURL}/ProfileInfo`;

// profile
const profileSectionURL = `${netAddrs.serverAddr}/Profile`;
const getUserProfileURL = `${profileSectionURL}/Get`;
const getCompanyProfileURL = `${profileSectionURL}/GetCompany`;
const updateProfileURL = `${profileSectionURL}/Update`;
const updatePasswordURL = `${profileSectionURL}/UpdatePwd`;
const updateCompanyURL = `${profileSectionURL}/UpdateCompanyProfile`;

async function signup(props: ICreateAdminCompanyInput): Promise<string> {
  const result = await invokeEndpoint<string>({
    path: signupURL,
    data: props,
    method: "POST",
  });

  return result;
}

export interface ILoginProps {
  emailOrPhoneNumber: string;
  password: string;
}

export interface ILoginResult {
  Token: string;
  RefreshToken: string;
}

async function login(props: ILoginProps): Promise<ILoginResult> {
  const result = await invokeEndpoint<ILoginResult>({
    path: loginURL,
    method: "POST",
    data: props,
  });

  return result;
}

export interface IChangePasswordWithTokenCodeProps {
  tokenCode: string;
  newPassword: string;
}

async function changePasswordWithCode(
  props: IChangePasswordWithTokenCodeProps
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpoint<IEndpointMessageResult>({
    path: changePasswordWithCodeURL,
    data: props,
    method: "PUT",
  });

  return result;
}

export interface IForgotPasswordAPIProps {
  phoneNumberOrEmail: string;
}

async function forgotPassword(
  props: IForgotPasswordAPIProps
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  params.set("phoneNumberOrEmail", props.phoneNumberOrEmail);
  const fullPath = `${forgotPasswordURL}?${params.toString()}`;
  const result = await invokeEndpoint<IEndpointMessageResult>({
    path: fullPath,
    method: "GET",
  });

  return result;
}

export interface IRefreshSessionLoginProps {
  refreshKey: string;
}

async function refreshSessionLogin(
  props: IRefreshSessionLoginProps
): Promise<ILoginResult> {
  const params = new URLSearchParams();
  params.set("refreshKey", props.refreshKey);
  const fullPath = `${refreshSessionURL}?${params.toString()}`;
  const result = await invokeEndpoint<ILoginResult>({
    path: fullPath,
    method: "GET",
    data: props,
  });

  return result;
}

async function getAuthProfile(): Promise<IAuthUser> {
  const result = await invokeEndpointWithAuth<IAuthUser>({
    path: getProfileURL,
    method: "GET",
  });

  return result;
}

async function updateProfile(
  user: IUpdateAdminUserInput
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: updateProfileURL,
    method: "PUT",
    data: user,
  });

  return result;
}

async function updateCompanyProfile(
  data: IUpdateAdminCompanyInput
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: updateCompanyURL,
    method: "PUT",
    data: data,
  });

  return result;
}

async function updatePassword(
  data: IUserPasswordChangeInput
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: updatePasswordURL,
    method: "PUT",
    data: data,
  });

  return result;
}

async function getUserProfile(): Promise<ISignedInUser> {
  const result = await invokeEndpointWithAuth<ISignedInUser>({
    path: getUserProfileURL,
    method: "GET",
  });

  return result;
}

async function getCompanyProfile(): Promise<IAdminCompany> {
  const result = await invokeEndpointWithAuth<IAdminCompany>({
    path: getCompanyProfileURL,
    method: "GET",
  });

  return result;
}

export default class UserAPI {
  public static signup = signup;
  public static login = login;
  public static changePasswordWithCode = changePasswordWithCode;
  public static forgotPassword = forgotPassword;
  public static refreshSessionLogin = refreshSessionLogin;
  public static getAuthProfile = getAuthProfile;
  public static updateProfile = updateProfile;
  public static updateCompanyProfile = updateCompanyProfile;
  public static updatePassword = updatePassword;
  public static getCompanyProfile = getCompanyProfile;
  public static getUserProfile = getUserProfile;
}
