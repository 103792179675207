import { ReloadOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import React from "react";
import InlineLoading from "./InlineLoading";

export interface IInlineStateProps {
  loading?: boolean;
  loadingText?: string;
  error?: string | null;
  onReload?: () => void;
}

const InlineState: React.FC<IInlineStateProps> = (props) => {
  const { loading, loadingText, error, onReload, children } = props;

  if (loading) {
    return <InlineLoading text={loadingText} />;
  } else if (error) {
    return (
      <Alert
        type="error"
        message={error}
        action={
          onReload && (
            <Button type="primary" icon={<ReloadOutlined />} onClick={onReload}>
              Reload
            </Button>
          )
        }
      />
    );
  }

  return <>{children}</>;
};

export default InlineState;
