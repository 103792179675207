import { OutgoingHttpHeaders } from "http";
import SessionStorageFuncs from "../browser-storage/session";
import { EndpointError } from "../definitions/errors";
import { messages } from "../definitions/messages";
import SessionActions from "../redux/session/actions";
import SessionSelectors from "../redux/session/selectors";
import store from "../redux/store";
import cast from "../utils/cast";
import UserAPI from "./endpoints/user";
import { IEndpointMessageResult } from "./types";

export interface IInvokeEndpointParams<DefaultValue = any> {
  data?: any;
  headers?: OutgoingHttpHeaders;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  defaultValue?: DefaultValue;
  path: string;
  skipProcessing?: boolean;
}

const TOKEN_EXPIRED_STATUS_CODE = 401;
const NOT_FOUND_STATUS_CODE = 404;

export function getEndpointResultMessage(result: {
  Message?: string;
  message?: string;
}) {
  return result?.Message || result?.message;
}

export async function invokeEndpoint<T>(
  props: IInvokeEndpointParams<T>
): Promise<T> {
  const { data, path, defaultValue } = props;
  try {
    const headers = {
      "Content-Type": "application/json",
      ...(props.headers || {}),
    };

    const result = await fetch(path, {
      headers,
      body: props.skipProcessing ? data : JSON.stringify(data),
      method: props.method || "POST",
      mode: "cors",
    });

    const contentType = result.headers.get("Content-Type");
    const isResultJSON =
      contentType?.includes("application/json") ||
      contentType?.includes("text/json");
    const isResultText = contentType?.includes("text/plain");
    if (result.ok) {
      if (isResultJSON) {
        const json = cast<T>(await result.json());
        if (cast<IEndpointMessageResult>(json)?.Message) {
          cast<IEndpointMessageResult>(json).message =
            cast<IEndpointMessageResult>(json)?.Message;
        }

        return json;
      } else if (isResultText) {
        return cast<T>(await result.text());
      }
    }

    if (result.status === NOT_FOUND_STATUS_CODE && defaultValue) {
      return defaultValue;
    }

    let message = result.statusText || messages.requestError;
    if (isResultJSON) {
      const body = cast<IEndpointMessageResult>(await result.json());
      message = body?.Message || body?.message || message;
    }

    throw new EndpointError(message, result.status, result.statusText);
  } catch (error) {
    if (error instanceof EndpointError) {
      throw error;
    } else {
      console.error(error);
      throw new Error(messages.requestError);
    }
  }
}

export function getToken() {
  return (
    SessionSelectors.getUserToken(store.getState()) ||
    SessionStorageFuncs.getUserToken()
  );
}

function getRefreshToken() {
  return (
    SessionSelectors.getRefreshToken(store.getState()) ||
    SessionStorageFuncs.getRefreshToken()
  );
}

function saveUserTokenAndRefreshKey(token: string, refreshKey: string) {
  store.dispatch(SessionActions.update({ token, refreshToken: refreshKey }));
  SessionStorageFuncs.saveTokenIfExists(token);
  SessionStorageFuncs.saveRefreshTokenIfExists(refreshKey);
}

export interface IInvokeEndpointWithAuthParams extends IInvokeEndpointParams {
  token?: string;
}

export async function invokeEndpointWithAuth<T>(
  props: IInvokeEndpointWithAuthParams,
  doNotRefresh = false
): Promise<T> {
  const requestToken = props.token || getToken();
  if (!requestToken) {
    throw new Error("Not signed in");
  }

  try {
    const result = invokeEndpoint<T>({
      ...props,
      headers: {
        Authorization: `Bearer ${requestToken}`,
        ...props.headers,
      },
    });

    return result;
  } catch (error) {
    const refreshToken = getRefreshToken();

    // TODO: should we throw a login again error instead?

    if (
      error instanceof EndpointError &&
      error.statusCode === TOKEN_EXPIRED_STATUS_CODE &&
      refreshToken &&
      !doNotRefresh
    ) {
      const refreshResult = await UserAPI.refreshSessionLogin({
        refreshKey: refreshToken,
      });

      saveUserTokenAndRefreshKey(
        refreshResult.Token,
        refreshResult.RefreshToken
      );

      return invokeEndpointWithAuth<T>(props, true);
    }

    throw error;
  }
}
