import React from "react";
import { css, cx } from "@emotion/css";
import logo from "../../resources/movebot-png-logo.png";

const WIDTH = 180;
const HEIGHT = 55;

const Logo: React.FC<JSX.IntrinsicElements["img"]> = (props) => {
  return (
    <img
      {...props}
      src={logo}
      alt="MoveBot logo"
      className={cx(
        css({
          width: WIDTH,
          height: HEIGHT,
        }),
        props.className
      )}
    />
  );
};

export default Logo;
