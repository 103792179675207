import { css } from "@emotion/css";
import { Typography } from "antd";
import React from "react";
import { messages } from "../../definitions/messages";

export interface IFunErrorMessagesProps {
  message?: string;
}

const funClassName = css({
  display: "inline-block",
  marginTop: "4px",
});

const FunErrorMessages: React.FC<IFunErrorMessagesProps> = (props) => {
  const { message, children } = props;

  switch (message) {
    case messages.funName:
      return (
        <Typography.Text className={funClassName}>
          Allowed characters are <Typography.Text keyboard>A</Typography.Text>-
          <Typography.Text keyboard>Z</Typography.Text>,{" "}
          <Typography.Text keyboard>a</Typography.Text>-
          <Typography.Text keyboard>z</Typography.Text>,{" "}
          <Typography.Text keyboard>0</Typography.Text>-
          <Typography.Text keyboard>9</Typography.Text>
        </Typography.Text>
      );

    case messages.funPassword: {
      const chars = "!()?_`~#$^&*+=";
      return (
        <Typography.Text className={funClassName}>
          Allowed characters are <Typography.Text keyboard>A</Typography.Text>-
          <Typography.Text keyboard>Z</Typography.Text>,{" "}
          <Typography.Text keyboard>a</Typography.Text>-
          <Typography.Text keyboard>z</Typography.Text>,{" "}
          <Typography.Text keyboard>0</Typography.Text>-
          <Typography.Text keyboard>9</Typography.Text>,
          {chars.split("").map((char) => (
            <Typography.Text keyboard>{char}</Typography.Text>
          ))}
        </Typography.Text>
      );
    }

    case messages.funSocialMediaHandle: {
      const chars = "@-_.";
      return (
        <Typography.Text className={funClassName}>
          Allowed characters are <Typography.Text keyboard>A</Typography.Text>-
          <Typography.Text keyboard>Z</Typography.Text>,{" "}
          <Typography.Text keyboard>a</Typography.Text>-
          <Typography.Text keyboard>z</Typography.Text>,{" "}
          <Typography.Text keyboard>0</Typography.Text>-
          <Typography.Text keyboard>9</Typography.Text>,
          {chars.split("").map((char) => (
            <Typography.Text keyboard>{char}</Typography.Text>
          ))}
        </Typography.Text>
      );
    }
  }

  return <>{children}</>;
};

export default FunErrorMessages;
