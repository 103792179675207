import { Alert, Button, Form, Input } from "antd";
import React from "react";
import * as yup from "yup";
import FormError from "../forms/FormError";
import FunErrorMessages from "../forms/FunErrorMessages";
import { formValidationSchemas } from "../forms/validation";
import useFormHelpers from "../hooks/useFormHelpers";
import ImageProviderAndUpload from "../utils/ImageProviderAndUpload";

const validationSchema = yup.object().shape({
  message: formValidationSchemas.strRequired,
});

export interface ITicketActivityFormValues {
  imageCount?: number;
  message: string;
}

export interface ITicketActivityFormProps {
  error?: string;
  isSubmitting?: boolean;
  attachmentId: string;
  onSubmit: (values: ITicketActivityFormValues) => void | Promise<void>;
}

const initialValues: ITicketActivityFormValues = {
  message: "",
};

const ATTACHMENT_WIDTH = 80;

const TicketActivityForm: React.FC<ITicketActivityFormProps> = (props) => {
  const { isSubmitting, error, attachmentId, onSubmit } = props;

  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      onSubmit: async (data, bag) => {
        await onSubmit(data);
        bag.resetForm();
      },
      initialValues: { ...initialValues },
    },
  });

  const messageNode = (
    <Form.Item
      required
      label="Message"
      help={
        formik.touched?.message &&
        formik.errors?.message && (
          <FunErrorMessages message={formik.errors?.message}>
            <FormError error={formik.errors?.message} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ maxWidth: "420px" }}
    >
      <Input.TextArea
        name="message"
        value={formik.values.message}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter ticket message"
        disabled={isSubmitting}
        autoSize={{ minRows: 2 }}
      />
    </Form.Item>
  );

  const setFieldValue = formik.setFieldValue;
  const setImageCount = React.useCallback(
    (count) => setFieldValue("imageCount", count),
    [setFieldValue]
  );

  const attachmentNode = (
    <Form.Item
      label="Attachments"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ maxWidth: "420px" }}
    >
      <ImageProviderAndUpload
        allowDelete
        id={attachmentId}
        imageAltBase="Ticket attachment"
        count={0}
        width={ATTACHMENT_WIDTH}
        height={ATTACHMENT_WIDTH}
        onCountChange={setImageCount}
      />
    </Form.Item>
  );

  return (
    <div>
      {error && (
        <Form.Item>
          <Alert message={error} type="error" />
        </Form.Item>
      )}
      <form onSubmit={formik.handleSubmit}>
        {messageNode}
        {attachmentNode}
        <Form.Item style={{ maxWidth: "420px" }}>
          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            {isSubmitting ? "Sending" : "Send"}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
};

export default React.memo(TicketActivityForm);
