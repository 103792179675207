import { List } from "antd";
import React from "react";
import { ITicket } from "../../definitions/ticket";
import ListSkeleton from "../utils/ListSkeleton";
import PageNothingFound from "../utils/PageNothingFound";
import { appClasses } from "../utils/theme";
import TicketListItem from "./TicketListItem";

export interface ITicketListProps {
  loading?: boolean;
  tickets: Array<ITicket>;
  onCompleteUpdateStatus: () => void;
}

const TicketList: React.FC<ITicketListProps> = (props) => {
  const { loading, tickets, onCompleteUpdateStatus } = props;

  if (loading) {
    return <ListSkeleton />;
  }

  if (tickets.length === 0) {
    return <PageNothingFound />;
  }

  return (
    <List
      split
      bordered
      loading={loading}
      itemLayout="horizontal"
      dataSource={tickets}
      renderItem={(item) => {
        return (
          <React.Fragment key={item.TicketId}>
            <TicketListItem
              ticket={item}
              onCompleteUpdateStatus={onCompleteUpdateStatus}
            />
          </React.Fragment>
        );
      }}
      className={appClasses.list}
    />
  );
};

export default TicketList;
