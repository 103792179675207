import { Button, Modal, Tooltip } from "antd";
import React from "react";
import { ICompany } from "../../definitions/company";
import DeleteCompanyReasonFormContainer from "./DeleteCompanyReasonFormContainer";
import { DeleteOutlined } from "@ant-design/icons";

export interface IDeleteCompanyActionProps {
  company: ICompany;
  onCompleteDelete: () => void;
}

const DeleteCompanyAction: React.FC<IDeleteCompanyActionProps> = (props) => {
  const { company, onCompleteDelete } = props;
  const [showReasonInput, setReasonInputVisibility] = React.useState(false);

  const onCancelUpdate = React.useCallback(() => {
    setReasonInputVisibility(false);
  }, []);

  const internalOnCompleteDelete = React.useCallback(() => {
    setReasonInputVisibility(false);
    onCompleteDelete();
  }, [onCompleteDelete]);

  const internalOnDelete = React.useCallback(async () => {
    Modal.confirm({
      title: "Are you sure you want to delete this company?",
      okText: "Yes",
      cancelText: "No",
      okType: "primary",
      okButtonProps: { danger: true },
      onOk: () => {
        setReasonInputVisibility(true);
      },
      onCancel() {
        // do nothing
      },
    });
  }, []);

  return (
    <React.Fragment>
      {showReasonInput ? (
        <Modal
          destroyOnClose
          visible={showReasonInput}
          title="Delete Company"
          footer={null}
          onCancel={onCancelUpdate}
        >
          <DeleteCompanyReasonFormContainer
            onCancel={onCancelUpdate}
            company={company}
            onCompleteDelete={internalOnCompleteDelete}
          />
        </Modal>
      ) : (
        <Tooltip title="delete">
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={internalOnDelete}
          />
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default DeleteCompanyAction;
