export const appBasePaths = {
  signup: "/signup",
  forgotPassword: "/forgot-password",
  login: "/login",
  app: "/app",
  changePassword: "/change-password",
  confirmPhoneNumber: "/confirm-phone-number",
};

export const appLoggedInPaths = {
  dashboard: `${appBasePaths.app}/dashboard`,
  marketplace: `${appBasePaths.app}/marketplace`,
  tracking: `${appBasePaths.app}/tracking`,
  groupPolicy: `${appBasePaths.app}/group-policy`,
  employees: `${appBasePaths.app}/employees`,
  company: `${appBasePaths.app}/company`,
  support: `${appBasePaths.app}/support`,
  audit: `${appBasePaths.app}/audit`,
  ticket: `${appBasePaths.app}/ticket`,
  payment: `${appBasePaths.app}/payment`,
  subscription: `${appBasePaths.app}/subscription`,
  profile: `${appBasePaths.app}/profile`,
  requests: `${appBasePaths.app}/requests`,
  partnerWithUs: `${appBasePaths.app}/requests/partner-with-us`,
  talkToUs: `${appBasePaths.app}/requests/talk-to-us`,
  analysis: `${appBasePaths.app}/analysis`,
  analysisMain: `${appBasePaths.app}/analysis/main`,
  analysisFeedback: `${appBasePaths.app}/analysis/feedback`,
};

export const appAuxiliaryPaths = {
  form: "/form",
};
