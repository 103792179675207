import { css } from "@emotion/css";

export const formClasses = {
  body: css({
    padding: "16px 16px",
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
  }),
  bodyInner: css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
    margin: "auto",
  }),
  centeredFormContainer: css({
    height: "100%",
    justifyContent: "center",
  }),
};
