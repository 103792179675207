import React from "react";
import { IAnalysisPaymentMethodStat } from "../../definitions/analysis";
import CountList from "../utils/CountList";
import LabeledCount from "../utils/LabeledCount";

export interface IAnalysisPaymentMethodCountStatsProps {
  stats: IAnalysisPaymentMethodStat;
}

const AnalysisPaymentMethodCountStats: React.FC<
  IAnalysisPaymentMethodCountStatsProps
> = (props) => {
  const { stats } = props;
  return (
    <CountList>
      <LabeledCount label="Cash Count" count={stats.CashCount} />
      <LabeledCount label="POS Count" count={stats.PosCount} />
      <LabeledCount label="Transfer Count" count={stats.TransferCount} />
      <LabeledCount label="Others Count" count={stats.OthersCount} />
    </CountList>
  );
};

export default AnalysisPaymentMethodCountStats;
