import React from "react";
import useSessionType from "./components/hooks/useSessionType";
import { SessionType } from "./redux/session/types";
import { css } from "@emotion/css";
import "./App.less";
import { appBasePaths } from "./definitions/paths";
import { useHistory } from "react-router-dom";
import AppRoutes from "./components/app-home/AppRoutes";

const App: React.FC<{}> = () => {
  const history = useHistory();
  const sessionType = useSessionType();

  const renderInitializing = () => (
    <div
      className={css({
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "32px",
      })}
    >
      ...
    </div>
  );

  React.useEffect(() => {
    if (
      sessionType === SessionType.App &&
      !window.location.pathname.includes(appBasePaths.app)
    ) {
      history.push(appBasePaths.app);
    }

    if (
      sessionType === SessionType.Web &&
      window.location.pathname.includes(appBasePaths.app)
    ) {
      history.push(appBasePaths.login);
    }
  }, [sessionType, history]);

  if (
    sessionType === SessionType.Uninitialized ||
    sessionType === SessionType.Initializing
  ) {
    return renderInitializing();
  }

  return <AppRoutes />;
};

export default App;
