import { Table, TableColumnType } from "antd";
import React from "react";
import { IAnalysisDriverFeedback } from "../../definitions/analysis";
import TableSkeleton from "../utils/TableSkeleton";

export interface IAnalysisFeedbackTableProps {
  loading?: boolean;
  data: IAnalysisDriverFeedback[];
}

const AnalysisFeedbackTable: React.FC<IAnalysisFeedbackTableProps> = (
  props
) => {
  const { data, loading } = props;
  const columns: TableColumnType<IAnalysisDriverFeedback>[] = [
    // {
    //   title: "",
    //   dataIndex: "SubmittedBy",
    //   key: "user-image",
    //   render(id) {
    //     return (
    //       <Avatar
    //         icon={<UserOutlined />}
    //         src={getLoadSingleImagePath(id)}
    //         alt={"Feedback submitter's avatar picture"}
    //         shape="circle"
    //       />
    //     );
    //   },
    //   width: 50,
    // },
    {
      title: "Order No.",
      dataIndex: "OrderNo",
      key: "OrderNo",
      width: 130,
    },
    {
      title: "Comment",
      dataIndex: "Comment",
      key: "Comment",
    },
    {
      title: "Rating",
      dataIndex: "Rating",
      key: "Rating",
      sorter: (a, b) => a.Rating - b.Rating,
      width: 80,
    },
    {
      title: "Date Submitted",
      dataIndex: "DateSubmitted",
      key: "DateSubmitted",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.DateSubmitted).valueOf() -
        new Date(b.DateSubmitted).valueOf(),
      width: 230,
    },
  ];

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey={(item) => {
        return item.OrderNo;
      }}
    />
  );
};

export default AnalysisFeedbackTable;
