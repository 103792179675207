import {
  IAuditLogisticCompanyFilter,
  IAuditLogItem,
  IAuditReferenceType,
} from "../../definitions/audit";
import {
  IPaginatedDataInput,
  IPaginatedDataResult,
} from "../../definitions/utils";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { selectEndpointParam, setEndpointParam } from "../utils";

const auditURL = `${netAddrs.serverAddr}/Audit`;
const pageURL = `${auditURL}/Page`;
const getReferenceTypesURL = `${auditURL}/ReferenceTypes`;
const getLogisticCompaniesURL = `${auditURL}/GetLogisticCompany`;

export interface IGetAuditLogEndpointResult
  extends IPaginatedDataResult<IAuditLogItem> {}

export interface IGetAuditLogEndpointParams extends IPaginatedDataInput {
  type?: string;
  date?: string;
  logisticCompanyId?: string;
}

async function getAuditLogs(
  props: IGetAuditLogEndpointParams = {}
): Promise<IGetAuditLogEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "type", props.type);
  setEndpointParam(params, "date", props.date);
  setEndpointParam(params, "logisticCompanyId", props.logisticCompanyId);

  const endpointURL = selectEndpointParam(pageURL, params, props);
  const result = await invokeEndpointWithAuth<IGetAuditLogEndpointResult>({
    path: endpointURL,
    method: "GET",
  });

  // Data gotten from the API doesn't have ID, and we need a unique identifier
  // when displaying the data
  // TODO: Tell Kingley to add id from the server-side if possible
  result.Data.forEach((item) => {
    item.itemId = Math.random().toString();
  });

  return result;
}

export type IGetReferenceTypesEndpointResult = Array<IAuditReferenceType>;

async function getReferenceTypes(): Promise<IGetReferenceTypesEndpointResult> {
  const result = await invokeEndpointWithAuth<IGetReferenceTypesEndpointResult>(
    {
      path: getReferenceTypesURL,
      method: "GET",
    }
  );

  return result;
}

export type IGetLogisticCompaniesEndpointResult =
  Array<IAuditLogisticCompanyFilter>;

async function getLogisticCompanies(): Promise<IGetLogisticCompaniesEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IGetLogisticCompaniesEndpointResult>({
      path: getLogisticCompaniesURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export default class AuditLogAPI {
  public static getAuditLogs = getAuditLogs;
  public static getLogisticCompanies = getLogisticCompanies;
  public static getReferenceTypes = getReferenceTypes;
}
