import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, List, Space, Spin, Tooltip, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { IGroupPolicy } from "../../definitions/group-policy";
import { appLoggedInPaths } from "../../definitions/paths";
import { appConstants } from "../../definitions/utils";
import DeleteAction, {
  IDeleteActionDeleteFnProps,
} from "../utils/delete/DeleteAction";
import PolicyListItemRoleAndPermissions from "./PolicyListItemRoleAndPermissions";
import PolicyToggleStatusContainer from "./PolicyToggleStatusContainer";
import PolicyUpdateInfo from "./PolicyUpdateInfo";

export interface IPolicyListItemProps {
  deleting?: boolean;
  className?: string;
  policy: IGroupPolicy;
  onEdit: () => void;
  onDelete: (props: IDeleteActionDeleteFnProps) => void;
  onCompleteUpdatePolicy: () => void;
  onCompleteDeletePolicy: () => void;
}

const PolicyListItem: React.FC<IPolicyListItemProps> = (props) => {
  const {
    deleting,
    policy,
    className,
    onEdit,
    onDelete,
    onCompleteUpdatePolicy,
    onCompleteDeletePolicy,
  } = props;

  const isSystemManagedPolicy = policy.UpdatedBy === appConstants.systemName;
  return (
    <Spin
      spinning={deleting}
      tip="Deleting group policy..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <List.Item
        className={className}
        actions={[
          <Tooltip title="edit policy" key="edit">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={onEdit}
              disabled={isSystemManagedPolicy}
            />
          </Tooltip>,
          <DeleteAction
            id={policy.GroupPolicyId}
            name={policy.GroupName}
            deleteFn={onDelete}
            onCompleteDelete={onCompleteDeletePolicy}
            modalTitle="Delete Group Policy"
            promptMessage="Are you sure you want to delete this group policy?"
          />,
        ]}
      >
        <List.Item.Meta
          title={
            <Link
              to={`${appLoggedInPaths.groupPolicy}/${policy.GroupPolicyId}`}
            >
              {policy.GroupName}
            </Link>
          }
          description={
            <Space direction="vertical">
              <Typography.Text>{policy.Description}</Typography.Text>
              {isSystemManagedPolicy && (
                <Typography.Text type="danger">
                  This policy is managed by the system and cannot be modified.
                </Typography.Text>
              )}
              <PolicyListItemRoleAndPermissions policy={policy} />
              <PolicyUpdateInfo policy={policy} />
            </Space>
          }
          avatar={
            <Tooltip title="Update policy status">
              <PolicyToggleStatusContainer
                policy={policy}
                onCompleteUpdatePolicy={onCompleteUpdatePolicy}
                disabled={isSystemManagedPolicy}
              />
            </Tooltip>
          }
        />
      </List.Item>
    </Spin>
  );
};

export default PolicyListItem;
