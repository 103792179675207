import { useRequest } from "ahooks";
import { Select } from "antd";
import React from "react";
import GroupPolicyAPI from "../../api/endpoints/group-policy";
import { IRole } from "../../definitions/group-policy";
import InlineState from "../utils/InlineState";

export interface IRolesInputProps {
  useRoleType?: boolean;
  value?: string;
  disabled?: boolean;
  className?: string;
  allowEmpty: boolean;
  onChange: (val?: IRole) => void;
  onInit: (value: IRole) => void;
}

function findRole(data?: IRole[], value?: string, useRoleType?: boolean) {
  return data?.find((item) =>
    useRoleType ? item._RoleType === value : item.RoleId === value
  );
}

const RolesInput: React.FC<IRolesInputProps> = (props) => {
  const {
    useRoleType,
    value,
    disabled,
    className,
    allowEmpty,
    onChange,
    onInit,
  } = props;

  const { loading, error, data, refresh } = useRequest(GroupPolicyAPI.getRoles);
  const [initialized, setInit] = React.useState(false);

  const optionNodes: React.ReactNode[] = [];

  React.useEffect(() => {
    if (value && data && !initialized) {
      setInit(true);
      const role = findRole(data, value, useRoleType);

      if (role) {
        onInit(role);
      }
    }
  }, [useRoleType, value, initialized, data, onInit]);

  data?.forEach((role) =>
    optionNodes.push(
      <Select.Option
        key={role.RoleId}
        value={useRoleType ? role._RoleType : role.RoleId}
      >
        {role._RoleType}
      </Select.Option>
    )
  );

  return (
    <InlineState
      loading={loading}
      error={error?.message}
      onReload={refresh}
      loadingText="Loading roles..."
    >
      <Select
        allowClear={allowEmpty}
        value={value}
        style={{ width: "100%" }}
        placeholder="Select role"
        onChange={(val) => {
          const role = findRole(data, val, useRoleType);

          if (role) {
            onChange(role);
          } else {
            onChange();
          }
        }}
        disabled={disabled}
        className={className}
      >
        {optionNodes}
      </Select>
    </InlineState>
  );
};

export default RolesInput;
