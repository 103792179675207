import { Table, TableColumnType } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { appLoggedInPaths } from "../../definitions/paths";
import { ITalkToUsRequest } from "../../definitions/requests";
import SelectTicketStatusContainer from "../ticket/SelectTicketStatusContainer";
import TableSkeleton from "../utils/TableSkeleton";

export interface ITalkToUsRequestsProps {
  loading?: boolean;
  data: ITalkToUsRequest[];
  onReloadPage: () => void;
}

const TalkToUsRequestsTable: React.FC<ITalkToUsRequestsProps> = (props) => {
  const { loading, data, onReloadPage } = props;
  const columns: TableColumnType<ITalkToUsRequest>[] = [
    {
      title: <div style={{ minWidth: "120px" }}>Ticket Number</div>,
      dataIndex: "TicketNumber",
      key: "TicketNumber",
      render: (text, record) => {
        return (
          <Link to={`${appLoggedInPaths.ticket}/${record.TicketNumber}`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: <div style={{ minWidth: "120px" }}>Status</div>,
      dataIndex: "Status_",
      key: "Status_",
      render: (text, record) => (
        <SelectTicketStatusContainer
          useTags
          bordered={false}
          onCompleteUpdate={onReloadPage}
          status={record.Status.Name}
          statusColor={record.Status.Color}
          ticketId={record.Id}
        />
      ),
    },
    {
      title: <div style={{ minWidth: "200px" }}>Client Name</div>,
      dataIndex: "ClientName",
      key: "ClientName",
    },
    {
      title: <div style={{ minWidth: "160px" }}>Email Address</div>,
      dataIndex: "ClientEmailAddress",
      key: "ClientEmailAddress",
    },
    {
      title: <div style={{ minWidth: "240px" }}>Note</div>,
      dataIndex: "Note",
      key: "Note",
    },
    {
      title: <div style={{ minWidth: "120px" }}>Date Created</div>,
      dataIndex: "DateCreated_",
      key: "DateCreated_",
    },
    {
      title: <div style={{ minWidth: "120px" }}>Last Updated</div>,
      dataIndex: "LastUpdated",
      key: "LastUpdated",
    },
  ];

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      rowKey={(row) => row.Id}
      pagination={false}
    />
  );
};

export default TalkToUsRequestsTable;
