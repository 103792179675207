export enum DashboardTimeFilterValue {
  Today = "Today",
  ThisWeek = "ThisWeek",
  TwoWeeks = "TwoWeeks",
  ThisMonth = "ThisMonth",
  _3Months = "_3Months",
  All = "All",
}

export interface IDashboardStaticCounts {
  TotalTransaction: number;
  TotalSuccessfulTransaction: number;
  TotalMoveBotDrivers: number;
  TotalMoveBotCompanys: number;
}

export interface IDashboardRecentOrderUpdate {
  TrackingNumber: string;
  CompanyAbbreviation: string;
  CompanyId: string;
  EstimatedArrivalDate: string;
  DeliveryLocation: string;
}

export interface IDashboardOrderCreationStatics {
  TotalCreated: number;
  DateCreated: string;
}

export interface IDashboardRecentOpenTicket {
  TicketId: string;
  TicketNumber: string;
  Subject: string;
  Priority: string;
  OrganizationArcoynm: string;
  Timestamp: string;
}
