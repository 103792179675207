import { Alert, Button, Form, Input, Space, Typography } from "antd";
import React from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { appBasePaths } from "../../definitions/paths";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import useFormHelpers from "../hooks/useFormHelpers";
import { utilClassNames } from "../utils/util-class-names";
import FormLogo from "../forms/FormLogo";
import { cx } from "@emotion/css";
import { formValidationSchemas } from "../forms/validation";

const validationSchema = yup.object().shape({
  emailOrPhoneNumber: formValidationSchemas.emailOrPhoneNumberRequired,
});

export interface IForgotPasswordFormValues {
  emailOrPhoneNumber: string;
}

export interface IForgotPasswordFormProps {
  onSubmit: (values: IForgotPasswordFormValues) => void | Promise<void>;
  isSubmitting?: boolean;
  error?: string;
}

const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = (props) => {
  const { onSubmit, isSubmitting, error } = props;

  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      initialValues: { emailOrPhoneNumber: "" },
      onSubmit: (values) => {
        onSubmit({
          emailOrPhoneNumber: values.emailOrPhoneNumber,
        });
      },
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const content = (
    <Space className={formClasses.bodyInner} direction="vertical" size="large">
      <FormLogo />
      <Typography.Title level={4}>Forgot Password</Typography.Title>
      {error && <Alert message={error} type="error" />}
      <form onSubmit={handleSubmit}>
        <Form.Item
          required
          label="Email Address or Phone Number"
          help={
            touched.emailOrPhoneNumber &&
            errors.emailOrPhoneNumber && (
              <FunErrorMessages message={formik.errors.emailOrPhoneNumber}>
                <FormError error={formik.errors.emailOrPhoneNumber} />
              </FunErrorMessages>
            )
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            // autoComplete="email"
            name="emailOrPhoneNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.emailOrPhoneNumber}
            placeholder="Enter your email address or phone number"
            disabled={isSubmitting}
          />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            {isSubmitting ? "Sending Code" : "Reset"}
          </Button>
        </Form.Item>
        <Form.Item>
          <Space direction="vertical" size={2}>
            <Typography.Text>
              Change your password{" "}
              <Link to={appBasePaths.changePassword}>
                <Typography.Text
                  className={utilClassNames.typographyWithPrimaryColor}
                >
                  Here
                </Typography.Text>
              </Link>
            </Typography.Text>
            <Typography.Text>
              Don't have an account?{" "}
              <Link to={appBasePaths.signup}>
                <Typography.Text
                  className={utilClassNames.typographyWithPrimaryColor}
                >
                  Sign up
                </Typography.Text>
              </Link>
            </Typography.Text>
          </Space>
        </Form.Item>
      </form>
    </Space>
  );

  return (
    <div className={cx(formClasses.body, formClasses.centeredFormContainer)}>
      {content}
    </div>
  );
};

export default ForgotPasswordForm;
