import { CaretDownOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Dropdown, Menu, Select, Space, Tag, Typography } from "antd";
import { defaultTo } from "lodash";
import React from "react";
import { TicketStatus } from "../../definitions/utils";
import cast from "../../utils/cast";
import { ALL } from "../utils/constants";

export interface ISelectTicketStatusProps {
  useTags?: boolean;
  statusColor?: string;
  disabled?: boolean;
  loading?: boolean;
  bordered?: boolean;
  status?: TicketStatus;
  style?: React.CSSProperties;
  showAll?: boolean;
  onChange: (value?: TicketStatus) => void;
}

const PLACEHOLDER = "Select status";
const WIDTH = 160;

const SelectTicketStatus: React.FC<ISelectTicketStatusProps> = (props) => {
  const {
    status,
    useTags,
    statusColor,
    disabled,
    loading,
    bordered,
    showAll,
    style,
    onChange,
  } = props;

  if (useTags) {
    const menuNode = (
      <Menu
        selectedKeys={status ? [status] : []}
        onClick={(evt) => {
          onChange(evt.key === ALL ? undefined : cast<TicketStatus>(evt.key));
        }}
        style={{ width: WIDTH }}
      >
        {showAll && <Menu.Item key={ALL}>All</Menu.Item>}
        {showAll && <Menu.Divider />}
        <Menu.Item key={TicketStatus.New}>New</Menu.Item>
        <Menu.Item key={TicketStatus.InProgress}>In Progress</Menu.Item>
        <Menu.Item key={TicketStatus.Resolved}>Resolved</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menuNode} trigger={["click"]} disabled={disabled}>
        <Space size={4} className={css({ cursor: "pointer" })}>
          <Tag
            style={{
              color: statusColor,
            }}
          >
            {status || PLACEHOLDER}
          </Tag>
          <Typography.Text type="secondary">
            <CaretDownOutlined />
          </Typography.Text>
        </Space>
      </Dropdown>
    );
  }

  return (
    <Select
      allowClear
      bordered={bordered}
      disabled={disabled}
      loading={loading}
      value={status}
      placeholder={PLACEHOLDER}
      onChange={onChange}
      style={{ ...defaultTo(style, {}), width: WIDTH }}
    >
      <Select.Option value={TicketStatus.New}>New</Select.Option>
      <Select.Option value={TicketStatus.InProgress}>In Progress</Select.Option>
      <Select.Option value={TicketStatus.Resolved}>Resolved</Select.Option>
    </Select>
  );
};

export default SelectTicketStatus;
