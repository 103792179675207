import React from "react";
import { IAppDataPaginationProps } from "./usePaginatedData";

const DEFAULT_PAGE_SIZE = 10;

export interface IUsePaginationProps<T> {
  defaultPageSize?: number;
  data: T[];
}

export interface IUsePaginationResult<T> extends IAppDataPaginationProps {
  loadingPagesMap: Record<number, boolean>;
  pageErrorsMap: Record<number, string>;
  getPageItems: (page: number) => T[];
}

function usePagination<T>(
  props: IUsePaginationProps<T>
): IUsePaginationResult<T> {
  const { data, defaultPageSize } = props;
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(
    defaultPageSize || DEFAULT_PAGE_SIZE
  );

  const onNavigate = React.useCallback((pageNumber: number) => {
    setPage(pageNumber);
  }, []);

  const getPageItems = React.useCallback(
    (pageNumber: number) => {
      pageNumber = pageNumber > 0 ? pageNumber : 1;
      const startIndex = (pageNumber - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      return data.slice(startIndex, endIndex);
    },
    [data, pageSize]
  );

  const result: IUsePaginationResult<T> = {
    page,
    pageSize,
    onNavigate,
    getPageItems,
    setPageSize,
    pageErrorsMap: {},
    total: data.length,
    loadingPagesMap: {},
  };

  return result;
}

export default usePagination;
