import { ITalkToUsRequest } from "../../definitions/requests";
import { TicketStatus } from "../../definitions/utils";
import { IUsePageDataResult, PageDataMode } from "../hooks/usePageData";
import PageError from "../utils/PageError";
import PagePagination from "../utils/PagePaination";
import RenderPageViewModes, {
  PageViewMode,
  usePageView,
} from "../utils/PageViewMode";
import { appClasses } from "../utils/theme";
import PartnerWithUsRequestsPageControls from "./PartnerWithUsRequestsPageControls";
import TalkToUsRequestsTable from "./TalkToUsRequestsTable";

export interface ITalkToUsRequestsPageFetchProps {
  status?: TicketStatus;
}

export interface ITalkToUsRequestsPageSearchProps {
  query: string;
}

export interface ITalkToUsRequestsPageProps
  extends IUsePageDataResult<
    ITalkToUsRequest,
    ITalkToUsRequestsPageFetchProps,
    ITalkToUsRequestsPageSearchProps
  > {}

const TalkToUsRequestsPage: React.FC<ITalkToUsRequestsPageProps> = (props) => {
  const {
    pageFetchExtra,
    searchFetchExtra,
    mode,
    page,
    isInitialized,
    getPageError,
    getPageLoading,
    setMode,
    setPageFetchExtra,
    setSearchFetchExtra,
    reloadSearchData,
    getPageItems,
    reloadEverything,
    reloadPageItems,
  } = props;

  const { viewMode } = usePageView(PageViewMode.Table);
  const pageLoading = getPageLoading(page);
  const renderTicketTable = () => (
    <TalkToUsRequestsTable
      loading={pageLoading}
      data={getPageItems(page)}
      onReloadPage={reloadEverything}
    />
  );

  const pageError = getPageError(page);
  let centerNode: React.ReactNode = null;

  if (pageError) {
    centerNode = (
      <PageError
        messageText={pageError}
        actions={[{ text: "Reload Requests", onClick: reloadPageItems }]}
      />
    );
  } else {
    centerNode = (
      <RenderPageViewModes
        mode={viewMode}
        modeList={[{ mode: PageViewMode.Table, render: renderTicketTable }]}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <PartnerWithUsRequestsPageControls
          disabled={!isInitialized}
          status={pageFetchExtra.status}
          onSelectStatus={(status) =>
            setPageFetchExtra({ ...pageFetchExtra, status }, true)
          }
          onEnterSearchQuery={(query) => {
            setSearchFetchExtra({ ...searchFetchExtra, query });
            if (query) {
              reloadSearchData();
              if (mode !== PageDataMode.Search) {
                setMode(PageDataMode.Search);
              }
            } else {
              if (mode === PageDataMode.Search) {
                setMode(PageDataMode.Page);
              }
            }
          }}
          isSearchMode={mode === PageDataMode.Search}
        />
      </div>
      <div className={appClasses.pageContent}>{centerNode}</div>
      <div className={appClasses.pagePagination}>
        <PagePagination {...props} />
      </div>
    </div>
  );
};

export default TalkToUsRequestsPage;
