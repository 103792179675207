import { Select } from "antd";
import React from "react";
import { TicketPriority } from "../../definitions/ticket";

export interface ISelectTicketPriorityProps {
  priority?: TicketPriority;
  disabled?: boolean;
  style?: React.CSSProperties;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onChange: (value: TicketPriority) => void;
}

const SelectTicketPriority: React.FC<ISelectTicketPriorityProps> = (props) => {
  const { priority, disabled, style, onChange, onBlur } = props;

  return (
    <Select
      allowClear
      value={priority}
      onBlur={onBlur}
      onChange={onChange}
      placeholder="Select priority"
      disabled={disabled}
      style={style}
    >
      <Select.Option value={TicketPriority.Urgent}>Urgent</Select.Option>
      <Select.Option value={TicketPriority.High}>High</Select.Option>
      <Select.Option value={TicketPriority.Medium}>Medium</Select.Option>
      <Select.Option value={TicketPriority.Low}>Low</Select.Option>
    </Select>
  );
};

export default SelectTicketPriority;
