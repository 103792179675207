import RequestsAPI from "../../api/endpoints/requests";
import { appLoggedInPaths } from "../../definitions/paths";
import usePageData from "../hooks/usePageData";
import useSectionCurrentPage from "../hooks/useSectionCurrentPage";
import PageLoading from "../utils/PageLoading";
import TalkToUsRequestsPage from "./TalkToUsPage";

const TalkToUsRequestsRoot: React.FC<{}> = () => {
  const pageData = usePageData({
    pageDataProps: {
      fetch: RequestsAPI.getTalkToUsRequests,
      getItemId: (item) => item.Id,
    },
    searchDataProps: {
      manual: true,
      fetch: RequestsAPI.searchTalkToUsRequests,
    },
  });

  const { isInitialized } = pageData;
  useSectionCurrentPage({
    baseURL: appLoggedInPaths.talkToUs,
    hasCreateForm: false,
    hasSelectedItemPage: false,
    hasUpdateForm: false,
    isComponentReady: isInitialized,
  });

  if (isInitialized) {
    return <TalkToUsRequestsPage {...pageData} />;
  }

  return <PageLoading />;
};

export default TalkToUsRequestsRoot;
