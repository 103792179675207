import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import EmployeeAPI from "../../api/endpoints/employee";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { IEmployee, IEmployeeInput } from "../../definitions/employee";
import { messages } from "../../definitions/messages";
import { withWrapWithMessageError } from "../utils/utils";
import EmployeeForm, { IEmployeeFormValues } from "./EmployeeForm";

export interface IEmployeeFormContainerProps {
  employee?: IEmployee;
  onCompleteCreateEmployee: () => void;
  onCompleteUpdateEmployee: () => void;
}

function getEmployeeFormValues(employee: IEmployee): IEmployeeFormValues {
  return {
    firstName: employee.EmployeeRawData.FirstName,
    lastName: employee.EmployeeRawData.LastName,
    emailAddress: employee.EmployeeRawData.EmailAddress,
    phoneNumber: {
      code: {
        iso2: employee.EmployeeRawData.PhoneNumber.Code,
        dialCode: employee.EmployeeRawData.PhoneNumber.Number,
      },
      number: employee.EmployeeRawData.PhoneNumber.Number,
    },
    address: {
      street: employee.EmployeeRawData.Address?.Street,
      city: employee.EmployeeRawData.Address?.City,
      state: employee.EmployeeRawData.Address?.State,
      zipCode: employee.EmployeeRawData.Address?.ZipCode,
      country: employee.EmployeeRawData.Address?.Country,
    },
    roleType: employee.AssignedRole,
    assignedGroupPolicyId: employee.EmployeeRawData.AssignedGroupPolicyId,
  };
}

const EmployeeFormContainer: React.FC<IEmployeeFormContainerProps> = (
  props
) => {
  const { onCompleteCreateEmployee, onCompleteUpdateEmployee, employee } =
    props;

  const onSubmit = withWrapWithMessageError(
    async (data: IEmployeeFormValues) => {
      const input: IEmployeeInput = {
        ...data,
        phoneNumber: {
          number: data.phoneNumber.number,
          code: data.phoneNumber.code.iso2,
        },
      };

      if (employee) {
        const result = await EmployeeAPI.updateEmployee({
          data: input,
          employeeId: employee.EmployeeId,
        });

        message.success(
          getEndpointResultMessage(result) || messages.employeeUpdatedSuccess
        );
        onCompleteUpdateEmployee();
      } else {
        const result = await EmployeeAPI.createEmployee(input);
        message.success(
          getEndpointResultMessage(result) || messages.employeeCreatedSuccess
        );
        onCompleteCreateEmployee();
      }
    }
  );

  const {
    loading: isSubmitting,
    error: submitError,
    run: onSubmitForm,
  } = useRequest(onSubmit, { manual: true });

  return (
    <EmployeeForm
      data={employee ? getEmployeeFormValues(employee) : undefined}
      onSubmit={onSubmitForm}
      error={submitError?.message}
      isSubmitting={isSubmitting}
    />
  );
};

export default EmployeeFormContainer;
