import get from "lodash/get";
import mergeWith from "lodash/mergeWith";
import { isMoment, Moment } from "moment";

export function getDateString(initial?: Date | string | number | Moment) {
  if (initial) {
    return new Date(
      isMoment(initial) ? initial.valueOf() : initial
    ).toISOString();
  }

  return new Date().toISOString();
}

export const pluralize = (str: string, count: number = 2) => {
  return `${str}${count === 1 ? "" : "s"}`;
};

export interface IMergeDataMeta {
  arrayUpdateStrategy?: "merge" | "concat" | "replace";
}

export const mergeData = <ResourceType = any>(
  resource: ResourceType,
  data: Partial<ResourceType>,
  meta: IMergeDataMeta = { arrayUpdateStrategy: "concat" }
) => {
  return mergeWith(resource, data, (objValue, srcValue) => {
    if (Array.isArray(objValue) && srcValue) {
      if (meta.arrayUpdateStrategy === "concat") {
        return objValue.concat(srcValue);
      } else if (meta.arrayUpdateStrategy === "replace") {
        return srcValue;
      }

      // No need to handle the "merge" arrayUpdateStrategy, it happens by default
      // if nothing is returned
    }
  });
};

export function getDate(initial?: any) {
  if (initial) {
    const date = new Date(initial);
    return date;
  }

  return new Date();
}

function defaultIndexer(data: any, path: any) {
  if (path) {
    return get(data, path);
  }

  return data;
}

function defaultReducer(data: any) {
  return data;
}

export type ArrayItemIndex = string;
export type ArrayItemIndexer<T> = (
  current: T,
  path: (T extends object ? keyof T : never) | undefined,
  arr: T[],
  index: number
) => ArrayItemIndex | undefined;

export type ArrayItemReducer<T, R> = (current: T, arr: T[], index: number) => R;

export interface IIndexArrayOptions<T, R> {
  path?: T extends object ? keyof T : never;
  indexer?: ArrayItemIndexer<T>;
  reducer?: ArrayItemReducer<T, R>;
}

export function indexArray<T, R = T>(
  arr: T[] = [],
  opts: IIndexArrayOptions<T, R> = {}
): Record<string, R> {
  const indexer = opts.indexer || defaultIndexer;
  const path = opts.path;
  const reducer = opts.reducer || defaultReducer;

  const result = arr.reduce((accumulator, current, index) => {
    const key = indexer(current, path, arr, index);

    if (!key) {
      return accumulator;
    }

    accumulator[key] = reducer(current, arr, index);
    return accumulator;
  }, {} as Record<string, R>);

  return result;
}

export function filterObjectList<T extends object = object>(
  list: T[],
  field: keyof T,
  searchQuery?: string
) {
  if (!searchQuery) {
    return list;
  }

  const lowercased = searchQuery.toLowerCase();
  return list.filter((block) => {
    const fieldValue = block[field];

    if (fieldValue) {
      return (fieldValue as unknown as string)
        .toLowerCase()
        .includes(lowercased);
    }

    return false;
  });
}

export function booleanToText(
  value: boolean,
  trueText: string,
  falseText: string
) {
  return value ? trueText : falseText;
}
