export function setEndpointParam(
  params: URLSearchParams,
  name: string,
  data: any
) {
  if (data) {
    params.set(name, data);
  }
}

export function setEndpointPath(value: string, data?: string | number | null) {
  if (data) {
    return `${value}/${data}`;
  }

  return value;
}

export function selectEndpointParam(
  url: string,
  params: URLSearchParams,
  data: Record<string, any>
) {
  if (Object.keys(data).length > 0) {
    return `${url}?${params.toString()}`;
  } else {
    return url;
  }
}

export function addEndpointPath(url: string, path?: string | number | null) {
  return path ? `${url}/${path}` : url;
}
