import { messages } from "../../definitions/messages";
import { IAppState } from "../types";

function getUserToken(state: IAppState) {
  return state.session.token;
}

function getRefreshToken(state: IAppState) {
  return state.session.token;
}

function getUserProfile(state: IAppState) {
  return state.session.userProfile;
}

function getCompanyProfile(state: IAppState) {
  return state.session.companyProfile;
}

function isUserSignedIn(state: IAppState) {
  return !!state.session.token && !!state.session.userProfile;
}

function assertUserSignedIn(state: IAppState) {
  if (!isUserSignedIn(state)) {
    throw new Error(messages.notSignedIn);
  }
}

function assertGetUserProfile(state: IAppState) {
  assertUserSignedIn(state);
  const user = getUserProfile(state)!;
  return user;
}

function assertGetCompanyProfile(state: IAppState) {
  assertUserSignedIn(state);
  const company = getCompanyProfile(state)!;
  return company;
}

function assertGetToken(state: IAppState) {
  assertUserSignedIn(state);
  return getUserToken(state)!;
}

function getSessionType(state: IAppState) {
  return state.session.sessionType;
}

export default class SessionSelectors {
  public static getUserToken = getUserToken;
  public static getRefreshToken = getRefreshToken;
  public static getUserProfile = getUserProfile;
  public static getCompanyProfile = getCompanyProfile;
  public static isUserSignedIn = isUserSignedIn;
  public static assertGetUserProfile = assertGetUserProfile;
  public static assertGetCompanyProfile = assertGetCompanyProfile;
  public static getSessionType = getSessionType;
  public static assertGetToken = assertGetToken;
}
