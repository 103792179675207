import { useRequest } from "ahooks";
import { message, Modal } from "antd";
import React from "react";
import GroupPolicyAPI from "../../api/endpoints/group-policy";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { IGroupPolicy } from "../../definitions/group-policy";
import { messages } from "../../definitions/messages";
import { IDeleteActionDeleteFnProps } from "../utils/delete/DeleteAction";

export interface IPolicyItemContainerRenderFnProps {
  deleting?: boolean;
  onDelete: (props: IDeleteActionDeleteFnProps) => void;
  onDeleteWithModal: (props: IDeleteActionDeleteFnProps) => void;
}

export interface IPolicyItemContainerProps {
  policy: IGroupPolicy;
  onCompleteUpdate: () => void;
  render: (props: IPolicyItemContainerRenderFnProps) => React.ReactElement;
}

const PolicyItemContainer: React.FC<IPolicyItemContainerProps> = (props) => {
  const { onCompleteUpdate, render } = props;
  const onDelete = React.useCallback(
    async (props: IDeleteActionDeleteFnProps) => {
      try {
        const result = await GroupPolicyAPI.deletePolicy(props);
        onCompleteUpdate();
        message.success(
          getEndpointResultMessage(result) || messages.policyDeleted
        );
      } catch (error: any) {
        message.error(error?.message || messages.deletePolicyError);
      }
    },
    [onCompleteUpdate]
  );

  const onDeleteWithModal = React.useCallback(
    async (props: IDeleteActionDeleteFnProps) => {
      Modal.confirm({
        title: "Are you sure you want to delete this policy?",
        okText: "Yes",
        cancelText: "No",
        okType: "primary",
        okButtonProps: { danger: true },
        onOk: () => onDelete(props),
        onCancel() {
          // do nothing
        },
      });
    },
    [onDelete]
  );

  const { run: runDelete, loading: deleting } = useRequest(onDelete, {
    manual: true,
  });

  return render({
    deleting,
    onDeleteWithModal,
    onDelete: runDelete,
  });
};

export default PolicyItemContainer;
