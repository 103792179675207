import { Line } from "@ant-design/charts";
import moment from "moment";
import React from "react";
import { IAnalysisDriverStat } from "../../definitions/analysis";

export interface IAnalysisDriverStatsProps {
  stats: IAnalysisDriverStat[];
}

const AnalysisDriverStats: React.FC<IAnalysisDriverStatsProps> = (props) => {
  const { stats } = props;
  const processed = stats.map((item) => ({
    counts: item.counts,
    period: moment(item.period).format("YYYY-MM-DD"),
  }));
  const config: React.ComponentProps<typeof Line> = {
    data: processed,
    padding: "auto",
    xField: "period",
    yField: "counts",
    xAxis: {
      tickCount: 5,
    },
  };

  return <Line {...config} />;
};

export default AnalysisDriverStats;
