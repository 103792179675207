import { Alert, Button, Checkbox, Form, Input, Typography } from "antd";
import React from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { userConstants } from "../../definitions/user";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import useFormHelpers from "../hooks/useFormHelpers";
import { appBasePaths } from "../../definitions/paths";
import { css, cx } from "@emotion/css";
import { utilClassNames } from "../utils/util-class-names";
import FormLogo from "../forms/FormLogo";
import { formValidationSchemas } from "../forms/validation";

const validationSchema = yup.object().shape({
  emailOrPhoneNumber: formValidationSchemas.emailOrPhoneNumberRequired,
  password: formValidationSchemas.strRequired,
});

export interface ILoginFormValues {
  emailOrPhoneNumber: string;
  password: string;
  remember: boolean;
}

export interface ILoginProps {
  onSubmit: (values: ILoginFormValues) => void | Promise<void>;
  isSubmitting?: boolean;
  error?: string;
}

const Login: React.FC<ILoginProps> = (props) => {
  const { onSubmit, isSubmitting, error } = props;

  const { formik } = useFormHelpers({
    formikProps: {
      onSubmit,
      validationSchema,
      initialValues: {
        emailOrPhoneNumber: "",
        password: "",
        remember: true,
      },
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <div className={cx(formClasses.body, formClasses.centeredFormContainer)}>
      <div className={formClasses.bodyInner}>
        <Form.Item>
          <FormLogo />
        </Form.Item>
        <Form.Item>
          <Typography.Title level={4}>Login</Typography.Title>
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        <form onSubmit={handleSubmit}>
          <Form.Item
            required
            label="Email Address or Phone Number"
            help={
              touched.emailOrPhoneNumber &&
              errors.emailOrPhoneNumber && (
                <FunErrorMessages message={errors.emailOrPhoneNumber}>
                  <FormError error={errors.emailOrPhoneNumber} />
                </FunErrorMessages>
              )
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              autoComplete="on"
              name="emailOrPhoneNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.emailOrPhoneNumber}
              disabled={isSubmitting}
              placeholder="Enter your email address or phone number"
            />
          </Form.Item>
          <Form.Item
            required
            label="Password"
            help={
              touched.password &&
              errors.password && (
                <FunErrorMessages message={errors.password}>
                  <FormError error={errors.password} />
                </FunErrorMessages>
              )
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.Password
              visibilityToggle
              autoComplete="current-password"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              disabled={isSubmitting}
              placeholder="Enter your password"
              maxLength={userConstants.maxPasswordLength}
            />
          </Form.Item>
          <Form.Item>
            <div
              className={css({
                display: "flex",
              })}
            >
              <div
                className={css({
                  flex: 1,
                  marginRight: "16px",
                })}
              >
                <Link to={appBasePaths.forgotPassword}>
                  <Typography.Text
                    underline
                    className={utilClassNames.typographyWithPrimaryColor}
                  >
                    Forgot Password?
                  </Typography.Text>
                </Link>
              </div>
              <Checkbox
                name="remember"
                onChange={handleChange}
                defaultChecked={true}
                checked={values.remember}
                disabled={isSubmitting}
              >
                <Typography.Text
                  className={utilClassNames.typographyWithPrimaryColor}
                >
                  Remember Me
                </Typography.Text>
              </Checkbox>
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting ? "Logging In" : "Login"}
            </Button>
          </Form.Item>
        </form>
      </div>
    </div>
  );
};

export default React.memo(Login);
