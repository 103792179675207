import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import SessionStorageFuncs from "../../browser-storage/session";
import { IAdminCompany } from "../../definitions/company";
import { ISignedInUser } from "../../definitions/user";
import { SessionType } from "./types";

const loginUser = createAction<{
  token: string;
  userProfile: ISignedInUser;
  companyProfile: IAdminCompany;
  refreshToken?: string;
}>("session/loginUser");

const update = createAction<{
  token?: string;
  refreshToken?: string;
  userProfile?: ISignedInUser;
  companyProfile?: IAdminCompany;
  avatarLastUpdatedAt?: string;
  companyAvatarLastUpdatedAt?: string;
}>("session/update");

const setSessionType = createAction<SessionType>("session/setSessionType");

const logoutUser = createAsyncThunk("session/logoutUser", (arg, thunkApi) => {
  SessionStorageFuncs.deleteUserVariables();
});

export default class SessionActions {
  public static loginUser = loginUser;
  public static logoutUser = logoutUser;
  public static setSessionType = setSessionType;
  public static update = update;
}
