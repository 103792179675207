import { IEnum } from "./utils";

export enum AnalysisFrequencyTypes {
  Today = "Today",
  ThisWeek = "ThisWeek",
  TwoWeeks = "TwoWeeks",
  ThisMonth = "ThisMonth",
  _3Months = "_3Months",
  All = "All",
}

export const ANALYSIS_FREQ_TYPES_LABEL: Record<AnalysisFrequencyTypes, string> =
  {
    [AnalysisFrequencyTypes.Today]: "Today",
    [AnalysisFrequencyTypes.ThisWeek]: "This Week",
    [AnalysisFrequencyTypes.TwoWeeks]: "Two Weeks",
    [AnalysisFrequencyTypes.ThisMonth]: "This Month",
    [AnalysisFrequencyTypes._3Months]: "3 Months",
    [AnalysisFrequencyTypes.All]: "All",
  };

export interface IAnalysisOrganization {
  organizationName: string;
  organizationId: string;
  Status: IEnum;
}

export interface IAnalysisDriver {
  DriverId: string;
  FullName: string;
  DriverNo: string;
  Status: IEnum;
  DateCreated: string;
}

export interface IAnalysisDriverStat {
  counts: number;
  period: string;
}

export interface IAnalysisDriverFeedback {
  SubmittedBy: string;
  Rating: number;
  Comment: string;
  DateSubmitted: string;
  OrderNo: string;
}

export interface IAnalysisOrderItemStat {
  TotalOrders: number;
  NeedsAttensionCount: number;
  CompletedCount: number;
  InProgressCount: number;
  GraphInfo: Array<{
    counts: number;
    period: string;
  }>;
}

export interface IAnalysisBlockItemStat {
  PrivateBlockCount: number;
  PublicBlockCount: number;
  TransferBlockCount: number;
  DispatchBlockCount: number;
}

export interface IAnalysisPaymentMethodStat {
  CashCount: number;
  PosCount: number;
  TransferCount: number;
  OthersCount: number;
}

export interface IAnalysisOrderItemCategoryStat {
  Count: number;
  CategoryName: string;
}

export interface IAnalysisDeliveryCostStat {
  DeliveryCost: number;
  Tax: number;
  Insurance: number;
  Type: IEnum;
  Total_: string;
  DeliveryCost_: string;
  Tax_: string;
  Insurance_: string;
}
