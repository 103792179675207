import { createReducer } from "@reduxjs/toolkit";
import SessionActions from "./actions";
import { ISessionState, SessionType } from "./types";

const sessionReducer = createReducer<ISessionState>(
  { sessionType: SessionType.Uninitialized },
  (builder) => {
    builder.addCase(SessionActions.loginUser, (state, action) => {
      state.sessionType = SessionType.App;
      state.token = action.payload.token;
      state.userProfile = action.payload.userProfile;
      state.companyProfile = action.payload.companyProfile;
      state.refreshToken = action.payload.refreshToken;
    });

    builder.addCase(SessionActions.update, (state, action) => {
      return { ...state, ...action.payload };
    });

    builder.addCase(SessionActions.setSessionType, (state, action) => {
      switch (action.payload) {
        case SessionType.Initializing:
          return { sessionType: SessionType.Initializing };

        case SessionType.Uninitialized:
          return { sessionType: SessionType.Uninitialized };

        case SessionType.Web:
          return { sessionType: SessionType.Web };

        case SessionType.App:
          state.sessionType = action.payload;
      }
    });

    builder.addCase(SessionActions.logoutUser.fulfilled, (state) => {
      return { sessionType: SessionType.Web };
    });
  }
);

export default sessionReducer;
