import { Typography } from "antd";
import React from "react";
import { IGroupPolicy } from "../../definitions/group-policy";

export interface IPolicyUpdateInfoProps {
  policy: IGroupPolicy;
}

const PolicyUpdateInfo: React.FC<IPolicyUpdateInfoProps> = (props) => {
  const { policy } = props;

  return (
    <Typography.Text type="secondary">
      Last updated by {policy.UpdatedBy}, {policy.LastUpdated}
    </Typography.Text>
  );
};

export default PolicyUpdateInfo;
