import {
  IGroupPolicy,
  IRole,
  IRolePermission,
  IShortGroupPolicy,
} from "../../definitions/group-policy";
import {
  IIdWithReason,
  IPaginatedDataInput,
  IPaginatedDataResult,
} from "../../definitions/utils";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { IEndpointMessageResult } from "../types";
import { selectEndpointParam, setEndpointParam } from "../utils";

const groupPolicyURL = `${netAddrs.serverAddr}/GroupPolicy`;
const pageURL = `${groupPolicyURL}/Page`;
const getRolesURL = `${groupPolicyURL}/GetRoles`;
const getRolePermissionsURL = `${groupPolicyURL}/GetRolePermissions`;
const getAssignedPermissionsURL = `${groupPolicyURL}/GetAssignedPermissions`;
const getShortGroupPolicyURL = `${groupPolicyURL}/GetGroupPolicy`;
const createURL = `${groupPolicyURL}/Create`;
const updateURL = `${groupPolicyURL}/Update`;
const updateStatusURL = `${groupPolicyURL}/Status`;
const deleteURL = `${groupPolicyURL}/Delete`;

export interface IGetGroupPoliciesEndpointInput extends IPaginatedDataInput {
  roleType?: string;
}

export interface IGetGroupPoliciesEndpointResult
  extends IPaginatedDataResult<IGroupPolicy> {}

async function getGroupPolicies(
  props: IGetGroupPoliciesEndpointInput = {}
): Promise<IGetGroupPoliciesEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "roleType", props.roleType);

  const endpointURL = selectEndpointParam(pageURL, params, props);
  const result = await invokeEndpointWithAuth<IGetGroupPoliciesEndpointResult>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export type IGetRolesEndpointResult = Array<IRole>;

async function getRoles(): Promise<IGetRolesEndpointResult> {
  const result = await invokeEndpointWithAuth<IGetRolesEndpointResult>({
    path: getRolesURL,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export interface IGetRolePermissionsEndpointProps {
  roleType: string;
}

export type GetRolesPermissionsEndpointResult = Array<IRolePermission>;

async function getRolePermissions(
  props: IGetRolePermissionsEndpointProps
): Promise<GetRolesPermissionsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "roleType", props.roleType);

  const endpointURL = selectEndpointParam(getRolePermissionsURL, params, props);
  const result =
    await invokeEndpointWithAuth<GetRolesPermissionsEndpointResult>({
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export interface IGetAssignedPermissionsEndpointProps {
  groupPolicyId: string;
}

async function getAssignedPermissions(
  props: IGetAssignedPermissionsEndpointProps
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "groupPolicyId", props.groupPolicyId);

  const endpointURL = selectEndpointParam(
    getAssignedPermissionsURL,
    params,
    props
  );

  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: endpointURL,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export interface IGetShortGroupPoliciesEndpointProps {
  roleType?: string;
}

export type IGetShortGroupPoliciesEndpointResult = Array<IShortGroupPolicy>;

async function getShortGroupPolicies(
  props: IGetShortGroupPoliciesEndpointProps = {}
): Promise<IGetShortGroupPoliciesEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "roleType", props.roleType);

  const fullPath = selectEndpointParam(getShortGroupPolicyURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetShortGroupPoliciesEndpointResult>({
      path: fullPath,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export interface ICreatePolicyEndpointProps {
  roleType: string;
  groupName: string;
  description: string;
  assignedGroupPolicy: string[];
}

async function createPolicy(
  props: ICreatePolicyEndpointProps
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: createURL,
    method: "POST",
    data: props,
  });

  return result;
}

export interface IUpdatePolicyEndpointProps {
  groupPolicyId: string;
  data: ICreatePolicyEndpointProps;
}

async function updatePolicy(
  props: IUpdatePolicyEndpointProps
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "groupPolicyId", props.groupPolicyId);

  const fullPath = selectEndpointParam(updateURL, params, props);
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: fullPath,
    method: "PUT",
    data: props.data,
  });

  return result;
}

export interface IUpdatePolicyStatusEndpointProps {
  id: string;
  reason: string;
  value: boolean;
}

async function updatePolicyStatus(
  props: IUpdatePolicyStatusEndpointProps
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: updateStatusURL,
    method: "PUT",
    data: props,
  });

  return result;
}

export interface IDeletePolicyEndpointProps extends IIdWithReason {}

async function deletePolicy(
  props: IDeletePolicyEndpointProps
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "id", props.id);
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: deleteURL,
    method: "DELETE",
    data: props,
  });

  return result;
}

export default class GroupPolicyAPI {
  public static getAssignedPermissions = getAssignedPermissions;
  public static getGroupPolicies = getGroupPolicies;
  public static getRoles = getRoles;
  public static getShortGroupPolicies = getShortGroupPolicies;
  public static createPolicy = createPolicy;
  public static updatePolicy = updatePolicy;
  public static updatePolicyStatus = updatePolicyStatus;
  public static deletePolicy = deletePolicy;
  public static getRolePermissions = getRolePermissions;
}
