import { TableOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import React from "react";

export enum PageViewMode {
  Table = "Table",
  List = "List",
}

export function usePageView(defaultMode: PageViewMode) {
  const [viewMode, setPageViewMode] = React.useState(defaultMode);
  React.useEffect(() => {
    setPageViewMode(defaultMode);
  }, [defaultMode]);
  return { viewMode, setPageViewMode };
}

export interface IPageViewToggleButtonsProps {
  mode: PageViewMode;
  setMode: (mode: PageViewMode) => void;
}

export const PageViewToggleButtons: React.FC<IPageViewToggleButtonsProps> = (
  props
) => {
  const { mode, setMode } = props;
  return (
    <Radio.Group
      value={mode}
      onChange={(evt) => setMode(evt.target.value)}
      buttonStyle="solid"
    >
      <Radio.Button value={PageViewMode.Table}>
        <TableOutlined />
      </Radio.Button>
      <Radio.Button value={PageViewMode.List}>
        <UnorderedListOutlined />
      </Radio.Button>
    </Radio.Group>
  );
};

export interface IPageViewModeItem {
  mode: PageViewMode;
  render: () => React.ReactNode;
}

export interface IRenderPageViewModesProps {
  mode: PageViewMode;
  modeList: IPageViewModeItem[];
}

const RenderPageViewModes: React.FC<IRenderPageViewModesProps> = (props) => {
  const { mode, modeList } = props;
  const selectedMode = modeList.find((item) => item.mode === mode);
  return <React.Fragment>{selectedMode?.render() || null}</React.Fragment>;
};

export default RenderPageViewModes;
