import RequestsAPI from "../../api/endpoints/requests";
import { appLoggedInPaths } from "../../definitions/paths";
import usePageData from "../hooks/usePageData";
import useSectionCurrentPage from "../hooks/useSectionCurrentPage";
import PageLoading from "../utils/PageLoading";
import PartnerWithUsRequestsPage from "./PartnerWithUsPage";

const PartnerWithUsRequestsRoot: React.FC<{}> = () => {
  const pageData = usePageData({
    pageDataProps: {
      fetch: RequestsAPI.getPartnerWithUsRequests,
      getItemId: (item) => item.Id,
    },
    searchDataProps: {
      manual: true,
      fetch: RequestsAPI.searchPartnerWithUsRequests,
    },
  });

  const { isInitialized } = pageData;
  useSectionCurrentPage({
    baseURL: appLoggedInPaths.partnerWithUs,
    hasCreateForm: false,
    hasSelectedItemPage: false,
    hasUpdateForm: false,
    isComponentReady: isInitialized,
  });

  if (isInitialized) {
    return <PartnerWithUsRequestsPage {...pageData} />;
  }

  return <PageLoading />;
};

export default PartnerWithUsRequestsRoot;
