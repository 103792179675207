import { Space } from "antd";
import React from "react";
import ImageProvider, {
  IImageProviderProps,
  IImageProviderRefMethods,
} from "./ImageProvider";
import ImageUpload, { IImageUploadProps } from "./ImageUpload";

export interface IImageProviderAndUploadProps
  extends Omit<IImageUploadProps, "onCompleteUpload">,
    Omit<IImageProviderProps, "onCompleteDelete"> {}

const ImageProviderAndUpload: React.FC<IImageProviderAndUploadProps> = (
  props
) => {
  const providerRef = React.useRef<IImageProviderRefMethods>();
  const onReload = React.useCallback(() => {
    providerRef.current?.reload();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <ImageProvider
        {...props}
        ref={providerRef as React.Ref<IImageProviderRefMethods>}
        onCompleteDelete={onReload}
      />
      <ImageUpload {...props} onCompleteUpload={onReload} />
    </Space>
  );
};

export default ImageProviderAndUpload;
