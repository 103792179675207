import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import GroupPolicyAPI from "../../api/endpoints/group-policy";
import { getEndpointResultMessage } from "../../api/invokeREST";
import {
  GroupPolicyStatus,
  IGroupPolicy,
} from "../../definitions/group-policy";
import { messages } from "../../definitions/messages";
import { wrapWithMessageError } from "../utils/utils";
import PolicyToggleStatus from "./PolicyToggleStatus";

export interface IPolicyToggleStatusProps {
  disabled?: boolean;
  policy: IGroupPolicy;
  onCompleteUpdatePolicy: () => void;
}

const PolicyToggleStatusContainer: React.FC<IPolicyToggleStatusProps> = (
  props
) => {
  const { policy, disabled, onCompleteUpdatePolicy } = props;
  const onSubmit = React.useCallback(
    async (status: boolean, reason: string) => {
      wrapWithMessageError(async () => {
        const result = await GroupPolicyAPI.updatePolicyStatus({
          reason,
          id: policy.GroupPolicyId,
          value: status,
        });

        onCompleteUpdatePolicy();
        message.success(
          getEndpointResultMessage(result) || messages.statusUpdated
        );
      });
    },
    [policy, onCompleteUpdatePolicy]
  );

  const { loading, error, run } = useRequest(onSubmit, { manual: true });
  return (
    <PolicyToggleStatus
      status={policy.Status.Name === GroupPolicyStatus.Active}
      loading={loading}
      onChange={run}
      disabled={disabled}
      error={error?.message}
    />
  );
};

export default PolicyToggleStatusContainer;
