import { LoadingOutlined } from "@ant-design/icons";
import { Divider, List, Space, Spin, Typography } from "antd";
import React from "react";
import { IGroupPolicy } from "../../definitions/group-policy";
import { appConstants } from "../../definitions/utils";
import { IDeleteActionDeleteFnProps } from "../utils/delete/DeleteAction";
import MiddleDot from "../utils/MiddleDot";
import PolicySecondaryDetails from "./PolicySecondaryDetails";

export interface IPolicyProps {
  deleting?: boolean;
  policy: IGroupPolicy;
  onEdit: () => void;
  onDelete: (props: IDeleteActionDeleteFnProps) => void;
  onCompleteUpdatePolicy: () => void;
  onCompleteDeletePolicy: () => void;
}

const Policy: React.FC<IPolicyProps> = (props) => {
  const { policy, deleting } = props;

  // TODO: should we show created/updated date and  who?
  const isSystemManagedPolicy = policy.UpdatedBy === appConstants.systemName;

  return (
    <Spin
      spinning={deleting}
      tip="Deleting group policy..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Space
        direction="vertical"
        style={{ width: "100%", padding: "0px 16px" }}
        size="middle"
      >
        <Typography.Title level={5}>{policy.GroupName}</Typography.Title>
        {policy.Description && (
          <Typography.Text>
            {policy.Description}
            <Typography.Text>{policy.Description}</Typography.Text>
            {isSystemManagedPolicy && (
              <Typography.Text type="danger">
                <MiddleDot />
                This policy is system managed
              </Typography.Text>
            )}
          </Typography.Text>
        )}
        <PolicySecondaryDetails {...props} />
        <Divider orientation="left">Permissions</Divider>
        <List
          split
          header={null}
          footer={null}
          dataSource={policy.AssignedPolicy}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text strong>{item._PolicyType}</Typography.Text>
            </List.Item>
          )}
        />
      </Space>
    </Spin>
  );
};

export default Policy;
