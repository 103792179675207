import { css } from "@emotion/css";
import { appColors } from "./theme";

const textButtonClassName = css({
  border: "none !important",
  backgroundColor: "inherit !important",
  color: `${appColors.primaryColor} !important`,
  borderRadius: "0 !important",
  boxShadow: "none !important",
  padding: "0 !important",

  "&:hover": {
    border: "none !important",
    backgroundColor: "inherit !important",
    color: `${appColors.primaryColor} !important`,
  },

  "&:focus": {
    border: "none !important",
    backgroundColor: "inherit !important",
    color: `${appColors.primaryColor} !important`,
  },
});

const typographyWithPrimaryColor = css({
  color: `${appColors.primaryColor} !important`,
});

export const utilClassNames = {
  typographyWithPrimaryColor,
  textButton: textButtonClassName,
};
