import React from "react";
import { Avatar, Button, Space, Table, TableColumnType, Tooltip } from "antd";
import { IEmployee } from "../../definitions/employee";
import Status, { ISubmitStatusFnProps } from "../utils/status/Status";
import DeleteAction, {
  IDeleteActionDeleteFnProps,
} from "../utils/delete/DeleteAction";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import TableSkeleton from "../utils/TableSkeleton";
import { getLoadSingleImagePath } from "../../api/endpoints/image";

export interface IEmployeeTableProps {
  loading?: boolean;
  data: IEmployee[];
  onCompleteUpdateStatus: () => void;
  submitStatusFn: (props: ISubmitStatusFnProps) => void;
  deleteEmployeeFn: (props: IDeleteActionDeleteFnProps) => void;
  onCompleteDelete: () => void;
  onEdit: (id: string) => void;
}

const EmployeeTable: React.FC<IEmployeeTableProps> = (props) => {
  const {
    loading,
    data,
    onCompleteUpdateStatus,
    onCompleteDelete,
    submitStatusFn,
    deleteEmployeeFn,
    onEdit,
  } = props;

  const columns: TableColumnType<IEmployee>[] = [
    {
      title: "",
      dataIndex: "EmployeeId",
      key: "employee-image",
      render(id, record) {
        return (
          <Avatar
            icon={<UserOutlined />}
            src={getLoadSingleImagePath(id)}
            alt={`${record.FullName}'s avatar picture`}
            shape="circle"
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "FullName",
      key: "FullName",
    },
    {
      title: "Email",
      dataIndex: "EmailAddress",
      key: "EmailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "PhoneNumber",
      key: "PhoneNumber",
    },
    {
      title: "Role",
      dataIndex: "AssignedRole",
      key: "AssignedRole",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record) => {
        return (
          <Status
            showStatusReason
            showStatusReasonTitle="Show status reason to user"
            id={record.EmployeeId}
            name={record.FullName}
            status={record.Status.Name}
            statusColor={record.Status.Color}
            onCompleteUpdateStatus={onCompleteUpdateStatus}
            submitFn={submitStatusFn}
            modalTitle="Update Employee Status"
          />
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="edit">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => onEdit(record.EmployeeId)}
            />
          </Tooltip>
          <DeleteAction
            id={record.EmployeeId}
            name={record.FullName}
            deleteFn={deleteEmployeeFn}
            onCompleteDelete={onCompleteDelete}
            modalTitle="Remove Employee"
            promptMessage="Are you sure you want to remove this employee?"
          />
        </Space>
      ),
    },
  ];

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      rowKey={(row) => row.EmployeeId}
      pagination={false}
    />
  );
};

export default EmployeeTable;
