import { css, cx } from "@emotion/css";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import React from "react";

export interface IMiddleDotProps extends TextProps {}

const MiddleDot: React.FC<IMiddleDotProps> = (props) => {
  return (
    <Typography.Text
      {...props}
      className={cx(css({ padding: "0px 12px" }), props.className)}
    >
      &#xB7;
    </Typography.Text>
  );
};

export default MiddleDot;
