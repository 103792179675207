import { css, cx } from "@emotion/css";
import { useRequest } from "ahooks";
import { Select } from "antd";
import React from "react";
import AuditLogAPI from "../../api/endpoints/audit";
import { IAuditReferenceType } from "../../definitions/audit";
import InlineState from "../utils/InlineState";
import { appDimensions } from "../utils/theme";

export interface IReferenceTypeInputProps {
  value?: string;
  disabled?: boolean;
  className?: string;
  allowEmpty?: boolean;
  onChange: (val?: IAuditReferenceType) => void;
}

const kEmptyKey = "empty";

const ReferenceTypeInput: React.FC<IReferenceTypeInputProps> = (props) => {
  const { value, disabled, className, allowEmpty, onChange } = props;
  const { loading, error, data, refresh } = useRequest(
    AuditLogAPI.getReferenceTypes
  );

  const optionNodes: React.ReactNode[] = [];

  if (allowEmpty) {
    optionNodes.push(
      <Select.Option key={kEmptyKey} value={kEmptyKey}>
        Empty
      </Select.Option>
    );
  }

  const internalData = data || [];

  internalData.forEach((referencetype) =>
    optionNodes.push(
      <Select.Option key={referencetype.id} value={referencetype.id}>
        {referencetype.description}
      </Select.Option>
    )
  );

  return (
    <InlineState
      loading={loading}
      error={error?.message}
      onReload={refresh}
      loadingText="Loading reference types..."
    >
      <Select
        value={value}
        style={{ width: "100%" }}
        placeholder="Select reference type"
        onChange={(val) => {
          if (val === kEmptyKey) {
            onChange();
          } else {
            const item = internalData.find((item) => item.id === val);

            if (item) {
              onChange(item);
            }
          }
        }}
        disabled={disabled}
        className={cx(className, css({ minWidth: appDimensions.optionsWidth }))}
      >
        {optionNodes}
      </Select>
    </InlineState>
  );
};

export default ReferenceTypeInput;
