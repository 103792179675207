import React from "react";
import GroupPolicyAPI from "../../api/endpoints/group-policy";
import { appLoggedInPaths } from "../../definitions/paths";
import usePaginatedData from "../hooks/usePaginatedData";
import useSectionCurrentPage from "../hooks/useSectionCurrentPage";
import PageLoading from "../utils/PageLoading";
import PoliciesPage from "./PoliciesPage";
import Policy from "./Policy";
import PolicyFormContainer from "./PolicyFormContainer";
import PolicyItemContainer from "./PolicyItemContainer";

const PoliciesRoot: React.FC<{}> = (props) => {
  const pageData = usePaginatedData({
    fetch: GroupPolicyAPI.getGroupPolicies,
    getItemId: (item) => item.GroupPolicyId,
  });

  const { isInitialized, reloadEverything, getItemById } = pageData;

  const {
    isCreateForm,
    updateItemId,
    selectedItemId,
    gotoUpdateForm,
    gotoCreateForm,
    gotoBase,
  } = useSectionCurrentPage({
    baseURL: appLoggedInPaths.groupPolicy,
    hasCreateForm: true,
    hasSelectedItemPage: true,
    hasUpdateForm: true,
    isComponentReady: isInitialized,
  });

  const onCompleteForm = React.useCallback(() => {
    gotoBase();
    reloadEverything();
  }, [gotoBase, reloadEverything]);

  if (isInitialized) {
    if (isCreateForm) {
      return <PolicyFormContainer onCompleteUpdatePolicy={onCompleteForm} />;
    } else if (updateItemId) {
      return (
        <PolicyFormContainer
          policy={getItemById(updateItemId)!}
          onCompleteUpdatePolicy={onCompleteForm}
        />
      );
    } else if (selectedItemId) {
      return (
        <PolicyItemContainer
          policy={getItemById(selectedItemId)!}
          onCompleteUpdate={reloadEverything}
          render={(itemProps) => (
            <Policy
              {...itemProps}
              policy={getItemById(selectedItemId)!}
              onEdit={() => gotoUpdateForm(selectedItemId)}
              onCompleteUpdatePolicy={reloadEverything}
              onCompleteDeletePolicy={reloadEverything}
            />
          )}
        />
      );
    } else {
      return (
        <PoliciesPage
          {...pageData}
          onCreateGroupPolicy={gotoCreateForm}
          gotoUpdateForm={gotoUpdateForm}
        />
      );
    }
  }

  return <PageLoading />;
};

export default PoliciesRoot;
