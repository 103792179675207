import { Button, Typography } from "antd";
import React from "react";
import { appClasses } from "../utils/theme";

export interface ICompaniesControlsProps {
  onCreateCompany: () => void;
}

const CompaniesControls: React.FC<ICompaniesControlsProps> = (props) => {
  const { onCreateCompany } = props;
  return (
    <div className={appClasses.controls.root}>
      <div className={appClasses.controls.leftControls}>
        <Typography.Title level={3}>Companies</Typography.Title>
      </div>
      <div>
        <Button type="primary" onClick={() => onCreateCompany()}>
          Create Company
        </Button>
      </div>
    </div>
  );
};

export default CompaniesControls;
