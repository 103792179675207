import { ReloadOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import React from "react";

export interface IAlertWithReloadProps {
  message: string;
  style?: React.CSSProperties;
  className?: string;
  reloadFn: () => void;
}

const AlertWithReload: React.FC<IAlertWithReloadProps> = (props) => {
  const { message, style, className, reloadFn } = props;
  return (
    <Alert
      style={style}
      className={className}
      type="error"
      message={message}
      action={
        <Button type="primary" icon={<ReloadOutlined />} onClick={reloadFn}>
          Reload
        </Button>
      }
    />
  );
};

export default AlertWithReload;
