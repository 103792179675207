import { List, Space, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { appLoggedInPaths } from "../../definitions/paths";
import { ITicket } from "../../definitions/ticket";
import { appColors } from "../utils/theme";
import TicketSecondaryDetails from "./TicketSecondaryDetails";

export interface ITicketListItemProps {
  className?: string;
  ticket: ITicket;
  onCompleteUpdateStatus: () => void;
}

const TicketListItem: React.FC<ITicketListItemProps> = (props) => {
  const { ticket, className, onCompleteUpdateStatus } = props;
  return (
    <List.Item className={className}>
      <List.Item.Meta
        title={
          <Link to={`${appLoggedInPaths.ticket}/${ticket.TicketNumber}`}>
            <Typography.Title
              level={5}
              style={{ color: appColors.primaryColor, fontSize: "14px" }}
            >
              {ticket.Subject}
            </Typography.Title>
          </Link>
        }
        description={
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {ticket.Message && (
              <Typography.Text>{ticket.Message}</Typography.Text>
            )}
            <TicketSecondaryDetails
              ticket={ticket}
              onCompleteUpdateStatus={onCompleteUpdateStatus}
            />
          </Space>
        }
      />
    </List.Item>
  );
};

export default TicketListItem;
