import useRequest from "@ahooksjs/use-request";
import { Space, Table, TableColumnType, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import DashboardAPI from "../../api/endpoints/dashboard";
import { IDashboardRecentOpenTicket } from "../../definitions/dashboard";
import { appLoggedInPaths } from "../../definitions/paths";
import PageError from "../utils/PageError";
import TableSkeleton from "../utils/TableSkeleton";

const columns: TableColumnType<IDashboardRecentOpenTicket>[] = [
  {
    title: "Ticket Number",
    dataIndex: "TicketNumber",
    key: "TicketNumber",
    render: (text, record) => {
      return (
        <Link to={`${appLoggedInPaths.ticket}/${record.TicketNumber}`}>
          {text}
        </Link>
      );
    },
    width: 200,
  },
  {
    title: "Subject",
    dataIndex: "Subject",
    key: "Subject",
    width: 200,
  },
  {
    title: "Priority",
    dataIndex: "Priority",
    key: "Priority",
    width: 200,
  },
  {
    title: "Org Acronym",
    dataIndex: "OrganizationArcoynm",
    key: "OrganizationArcoynm",
    width: 200,
  },
  {
    title: "Timestamp",
    dataIndex: "Timestamp",
    key: "Timestamp",
    width: 200,
  },
];

const DashboardRecentOpenTickets: React.FC<{}> = () => {
  const { error, data, loading, run } = useRequest(
    DashboardAPI.getRecentOpenTickets
  );
  let contentNode: React.ReactNode = null;

  if (error) {
    contentNode = (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload", onClick: run }]}
      />
    );
  } else if (loading || !data) {
    contentNode = <TableSkeleton columns={columns} />;
  } else {
    contentNode = (
      <Table
        bordered
        size="small"
        dataSource={data}
        columns={columns}
        rowKey={(row) => row.TicketId}
        pagination={false}
      />
    );
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Title level={5}>Recent Open Tickets</Typography.Title>
      {contentNode}
    </Space>
  );
};

export default DashboardRecentOpenTickets;
