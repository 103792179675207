import { cx } from "@emotion/css";
import { Alert, Button, Form, Input, Typography } from "antd";
import { CountryData } from "intl-tel-input";
import { isString } from "lodash";
import React from "react";
import * as yup from "yup";
import { IEmployeeInput } from "../../definitions/employee";
import { IRole } from "../../definitions/group-policy";
import AddressInput from "../forms/AddressInput";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import PhoneInput from "../forms/PhoneInput";
import { formValidationSchemas } from "../forms/validation";
import PolicyInput from "../group-policy/PolicyInput";
import RolesInput from "../group-policy/RolesInput";
import useFormHelpers from "../hooks/useFormHelpers";

const validationSchema = yup.object().shape({
  firstName: formValidationSchemas.strRequired,
  lastName: formValidationSchemas.strRequired,
  emailAddress: formValidationSchemas.emailRequired,
  // phoneNumber: formValidationSchemas.requiredField,
  address: formValidationSchemas.addressRequired,
  assignedGroupPolicyId: formValidationSchemas.requiredField,
});

export type IEmployeeFormValues = Omit<IEmployeeInput, "phoneNumber"> & {
  roleType?: string;
  phoneNumber: {
    number: string;
    code: {
      iso2: string;
      dialCode: string;
    };
  };
};

interface IEmployeeFormInternalValues extends IEmployeeFormValues {
  role?: IRole;
}

export interface IEmployeeFormProps {
  error?: string;
  isSubmitting?: boolean;
  data?: IEmployeeFormValues;
  onSubmit: (values: IEmployeeFormValues) => void | Promise<void>;
}

const initialValues: IEmployeeFormInternalValues = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: {
    code: {
      iso2: "",
      dialCode: "",
    },
    number: "",
  },
  address: {
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  },
  assignedGroupPolicyId: "",
};

const EmployeeForm: React.FC<IEmployeeFormProps> = (props) => {
  const { isSubmitting, data, error, onSubmit } = props;
  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      initialValues: {
        ...initialValues,
        ...data,
      },
      onSubmit: (data) => {
        onSubmit(data);
      },
    },
  });

  const firstNameNode = (
    <Form.Item
      required
      label="First Name"
      help={
        formik.touched?.firstName &&
        formik.errors?.firstName && (
          <FunErrorMessages message={formik.errors?.firstName}>
            <FormError error={formik.errors?.firstName} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="firstName"
        value={formik.values?.firstName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter first name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const lastNameNode = (
    <Form.Item
      required
      label="Last Name"
      help={
        formik.touched?.lastName &&
        formik.errors?.lastName && (
          <FunErrorMessages message={formik.errors?.lastName}>
            <FormError error={formik.errors?.lastName} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="lastName"
        value={formik.values?.lastName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter last name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const emailNode = (
    <Form.Item
      required
      label="Email Address"
      help={
        formik.touched?.emailAddress &&
        formik.errors?.emailAddress && (
          <FunErrorMessages message={formik.errors.emailAddress}>
            <FormError error={formik.errors.emailAddress} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="emailAddress"
        value={formik.values.emailAddress}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        disabled={isSubmitting}
        placeholder="Enter email address"
      />
    </Form.Item>
  );

  const phoneNodeOnChange = React.useCallback(
    (val: string, code?: CountryData, phoneNumber?: string) =>
      formik.setFieldValue(
        "phoneNumber",
        { code, number: phoneNumber },
        val ? false : true
      ),
    [formik]
  );

  const phoneCountryCodeNodeOnChange = React.useCallback(
    (code?: CountryData) => {
      formik.setFieldValue(
        "phoneNumber",
        { ...formik.values.phoneNumber, code },
        false
      );
    },
    [formik]
  );

  const phoneNodeOnError = React.useCallback(
    (errorMsg) => formik.setFieldError("phoneNumber", errorMsg),
    [formik]
  );

  const phoneNumberError = isString(formik.errors.phoneNumber)
    ? formik.errors.phoneNumber
    : "";

  const phoneNode = (
    <Form.Item
      required
      label="Phone Number"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.phoneNumber &&
        formik.errors?.phoneNumber && (
          <FunErrorMessages message={phoneNumberError}>
            <FormError error={phoneNumberError} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <PhoneInput
        value={{
          code: formik.values.phoneNumber?.code.iso2,
          number: formik.values.phoneNumber?.number,
        }}
        onChangePhoneNumber={phoneNodeOnChange}
        onChangeCountryCode={phoneCountryCodeNodeOnChange}
        onError={phoneNodeOnError}
        placeholder="Enter phone number"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const addressNode = (
    <AddressInput
      errors={formik.errors?.address || {}}
      setFieldValue={formik.setFieldValue}
      touched={formik.touched?.address || {}}
      values={formik.values.address}
      fieldName="address"
      disabled={isSubmitting}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
    />
  );

  const roleNode = (
    <Form.Item
      label="Role"
      help={
        formik.touched?.roleType &&
        formik.errors?.roleType && (
          <FunErrorMessages message={formik.errors.roleType}>
            <FormError error={formik.errors.roleType} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <RolesInput
        useRoleType
        allowEmpty
        value={formik.values.roleType}
        disabled={isSubmitting}
        onChange={(val) => {
          formik.setFieldValue("assignedGroupPolicyId", "");
          formik.setFieldValue("assignedRoleId", val?.RoleId);
          formik.setFieldValue("role", val);
          formik.setFieldValue("roleType", val?._RoleType);
        }}
        onInit={(val) => {
          formik.setFieldValue("role", val);
        }}
      />
    </Form.Item>
  );

  // TODO: Group policy is currently setup in the server as a single select
  // Ask Kingsley if this is supposed to be so.
  const policyNode = (
    <Form.Item
      required
      label="Group Policy"
      help={
        formik.touched?.assignedGroupPolicyId &&
        formik.errors?.assignedGroupPolicyId && (
          <FunErrorMessages message={formik.errors.assignedGroupPolicyId}>
            <FormError error={formik.errors.assignedGroupPolicyId} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <PolicyInput
        allowEmpty={false}
        roleType={formik.values.role?._RoleType}
        value={formik.values.assignedGroupPolicyId}
        disabled={isSubmitting}
        onChange={(val) => {
          formik.setFieldValue("assignedGroupPolicyId", val?.GroupPolicyId);
        }}
      />
    </Form.Item>
  );

  // TODO: in the submit button, have specific labels like "create employee", etc.
  // do the same for other forms
  return (
    <div className={cx(formClasses.body)}>
      <div className={formClasses.bodyInner}>
        <Form.Item>
          <Typography.Title level={4}>Employee Form</Typography.Title>
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        <form onSubmit={formik.handleSubmit}>
          {firstNameNode}
          {lastNameNode}
          {emailNode}
          {phoneNode}
          {addressNode}
          {roleNode}
          {policyNode}
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </Button>
          </Form.Item>
        </form>
      </div>
    </div>
  );
};

export default React.memo(EmployeeForm);
