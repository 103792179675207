import { Table, TableColumnType } from "antd";
import React from "react";
import { IAnalysisDriver } from "../../definitions/analysis";
import TableSkeleton from "../utils/TableSkeleton";

export interface IAnalysisDriversTableProps {
  loading?: boolean;
  data: IAnalysisDriver[];
}

const enCollator = new Intl.Collator("en");
const AnalysisDriversTable: React.FC<IAnalysisDriversTableProps> = (props) => {
  const { data, loading } = props;
  const columns: TableColumnType<IAnalysisDriver>[] = [
    // {
    //   title: "Driver ID",
    //   dataIndex: "DriverId",
    //   key: "DriverId",
    //   sorter: (a, b) => enCollator.compare(a.DriverId, b.DriverId),
    //   width: 300,
    // },
    {
      title: <div style={{ minWidth: "80px" }}>Driver No.</div>,
      dataIndex: "DriverNo",
      key: "DriverNo",
      sorter: (a, b) => enCollator.compare(a.DriverNo, b.DriverNo),
      width: 120,
    },
    {
      title: <div style={{ minWidth: "160px" }}>Full Name</div>,
      dataIndex: "FullName",
      key: "FullName",
      sorter: (a, b) => enCollator.compare(a.FullName, b.FullName),
      width: 260,
    },
    {
      title: <div style={{ minWidth: "80px" }}>Status</div>,
      dataIndex: "Status",
      key: "Status",
      sorter: (a, b) => a.Status.Index - b.Status.Index,
      render: (status, row) => row.Status.Name,
      width: 100,
    },
    {
      title: <div style={{ minWidth: "310px" }}>Date Created</div>,
      dataIndex: "DateCreated",
      key: "DateCreated",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.DateCreated).valueOf() - new Date(b.DateCreated).valueOf(),
    },
  ];

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey={(item) => {
        return item.DriverId;
      }}
    />
  );
};

export default AnalysisDriversTable;
