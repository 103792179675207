import { message } from "antd";
import { isString } from "lodash";
import { messages } from "../../definitions/messages";
import { appConstants } from "../../definitions/utils";
import SessionActions from "../../redux/session/actions";
import { AppDispatch } from "../../redux/types";
import { UnPromisify } from "../../utils/types";

export async function logoutOnError<T extends () => any>(
  dispatch: AppDispatch,
  func: T
): Promise<ReturnType<T> | undefined> {
  try {
    return await func();
  } catch (error: any) {
    let errorMessage = "";

    if (isString(error)) {
      errorMessage = error;
    } else if (error?.message) {
      errorMessage = error.message;
    }

    if (errorMessage) {
      message.error(errorMessage);
    }

    dispatch(SessionActions.logoutUser());
  }
}

export async function wrapWithMessageError<F extends (...args: any[]) => any>(
  fn: F
): Promise<UnPromisify<ReturnType<F>>> {
  try {
    return await fn();
  } catch (error: any) {
    message.error(
      error.message || messages.errorOccurred,
      appConstants.messageDuration
    );
    throw error;
  }
}

export function withWrapWithMessageError<F extends (...args: any[]) => any>(
  fn: F
) {
  return async (
    ...args: Parameters<F>
  ): Promise<UnPromisify<ReturnType<F>>> => {
    try {
      return await fn(...args);
    } catch (error: any) {
      message.error(
        error.message || messages.errorOccurred,
        appConstants.messageDuration
      );
      throw error;
    }
  };
}
