import { Space, Typography } from "antd";
import React from "react";
import { ITrackingInformation } from "../../definitions/tracker";
import LabeledNode from "../utils/LabeledNode";
import TrackerEventList from "./TrackerEventList";

export interface ITrackerProps {
  trackingNumber: string;
  tracker: ITrackingInformation;
}

const Tracker: React.FC<ITrackerProps> = (props) => {
  const { trackingNumber, tracker } = props;

  return (
    <Space direction="vertical" size="large">
      <LabeledNode
        nodeIsText
        label="Tracking Number"
        direction="horizontal"
        node={trackingNumber}
      />
      <LabeledNode
        nodeIsText
        label="Package Status"
        direction="horizontal"
        node={tracker.CurrentPackageStatus}
      />
      <Typography.Title level={5}>Events</Typography.Title>
      <TrackerEventList events={tracker.Events} />
    </Space>
  );
};

export default Tracker;
