import { css } from "@emotion/css";
import { Button, Space, Typography } from "antd";
import { noop } from "lodash";
import React from "react";
import { appClasses } from "../utils/theme";
import RolesInput from "./RolesInput";

export interface IPoliciesPageControlsOptions {
  roleType?: string;
}

export interface IPoliciesPageControlsProps {
  options: IPoliciesPageControlsOptions;
  onChange: (options: IPoliciesPageControlsOptions) => void;
  gotoCreateForm: () => void;
}

const PoliciesPageControls: React.FC<IPoliciesPageControlsProps> = (props) => {
  const { options, onChange, gotoCreateForm } = props;
  return (
    <div className={appClasses.controls.root}>
      <div className={appClasses.controls.leftControls}>
        <Typography.Title level={3}>Group Policy</Typography.Title>
      </div>
      <Space size={"middle"}>
        <Button type="primary" onClick={() => gotoCreateForm()}>
          Create New Policy
        </Button>
        <RolesInput
          allowEmpty
          value={options.roleType}
          onChange={(role) => onChange({ roleType: role?._RoleType })}
          onInit={noop}
          className={css({ width: "200px !important" })}
        />
      </Space>
    </div>
  );
};

export default PoliciesPageControls;
