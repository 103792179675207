import { Tabs } from "antd";
import React from "react";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { appLoggedInPaths } from "../../definitions/paths";
import { appClasses } from "../utils/theme";
import AnalysisFeedbackStats from "./AnalysisFeedbackStats";
import AnalysisMainStats from "./AnalysisMainStats";

const AnalysisPage: React.FC<{}> = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch<{
    currentRoute?: string;
  }>(`${appLoggedInPaths.analysis}/:currentRoute`);

  const currentRoute = routeMatch?.params.currentRoute;
  const selectedKey = `${appLoggedInPaths.requests}/${currentRoute}`;

  if (!currentRoute) {
    return <Redirect to={appLoggedInPaths.analysisMain} />;
  }

  return (
    <Tabs
      defaultActiveKey={selectedKey}
      onChange={(key) => {
        history.push(key);
      }}
      className={appClasses.tabs}
      tabBarExtraContent={{
        left: <div style={{ marginLeft: "16px" }} />,
        right: <div style={{ marginRight: "16px" }} />,
      }}
    >
      <Tabs.TabPane
        tab={<span>Stats</span>}
        key={appLoggedInPaths.analysisMain}
      >
        <AnalysisMainStats />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={<span>Feedback</span>}
        key={appLoggedInPaths.analysisFeedback}
      >
        <AnalysisFeedbackStats />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default AnalysisPage;
