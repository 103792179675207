import {
  AimOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BankOutlined,
  BookOutlined,
  CommentOutlined,
  CreditCardOutlined,
  LogoutOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  SlidersOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/css";
import { Menu, Modal, Space, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useRouteMatch } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import { messages } from "../../definitions/messages";
import { appBasePaths, appLoggedInPaths } from "../../definitions/paths";
import SessionActions from "../../redux/session/actions";
import SessionSelectors from "../../redux/session/selectors";
import { ISessionState } from "../../redux/session/types";
import { IAppState } from "../../redux/types";
import ImageWithFallback from "../utils/ImageWithFallback";
import Logo from "../utils/Logo";

interface INavBarMatchedRoute {
  currentRoute?: string;
}

const LOGOUT_KEY = "logout";
const NAV_WIDTH = 240;
const LOGO_WIDTH = NAV_WIDTH - 16 * 2;
const LOGO_HEIGHT = 48;

const MENU_ITEMS: Array<
  | {
      label: string;
      pathname: string;
      icon: React.ReactNode;
      visible?: boolean;
    }
  | { type: "divider"; visible?: boolean }
  | { label: string; key: string; icon: React.ReactNode; visible?: boolean }
> = [
  {
    label: "Dashboard",
    pathname: appLoggedInPaths.dashboard,
    icon: <AppstoreOutlined />,
    visible: true,
  },
  {
    label: "Analysis",
    pathname: appLoggedInPaths.analysis,
    icon: <SlidersOutlined />,
    visible: true,
  },
  {
    label: "Marketplace",
    pathname: appLoggedInPaths.marketplace,
    icon: <ShopOutlined />,
    visible: false,
  },
  {
    label: "Tracking",
    pathname: appLoggedInPaths.tracking,
    icon: <AimOutlined />,
    visible: false,
  },
  {
    label: "Group Policies",
    pathname: appLoggedInPaths.groupPolicy,
    icon: <SolutionOutlined />,
    visible: true,
  },
  {
    label: "Employees",
    pathname: appLoggedInPaths.employees,
    icon: <UsergroupAddOutlined />,
    visible: true,
  },
  {
    label: "Companies",
    pathname: appLoggedInPaths.company,
    icon: <BankOutlined />,
    visible: true,
  },
  {
    label: "Support",
    pathname: appLoggedInPaths.support,
    icon: <CommentOutlined />,
    visible: false,
  },
  {
    label: "Audit Logs",
    pathname: appLoggedInPaths.audit,
    icon: <AuditOutlined />,
    visible: true,
  },
  {
    label: "Tickets",
    pathname: appLoggedInPaths.ticket,
    icon: <BookOutlined />,
    visible: true,
  },
  {
    label: "Requests",
    pathname: appLoggedInPaths.requests,
    icon: <ReconciliationOutlined />,
    visible: true,
  },
  {
    label: "Payment",
    pathname: appLoggedInPaths.payment,
    icon: <WalletOutlined />,
    visible: false,
  },
  {
    label: "Subscription",
    pathname: appLoggedInPaths.subscription,
    icon: <CreditCardOutlined />,
    visible: false,
  },
  { type: "divider" },
  {
    label: "Profile",
    pathname: appLoggedInPaths.profile,
    icon: <UserOutlined />,
  },
  {
    label: "Logout",
    icon: <LogoutOutlined />,
    key: LOGOUT_KEY,
  },
];

const classes = {
  menuItem: css({
    "&.ant-menu-item-selected *": {
      color: "#FF7F50 !important",
    },
    "& *": {
      color: "#FFFFFF !important",
    },
    "&:active *": {
      color: "#FF7F50 !important",
    },
  }),
  menu: css({
    backgroundColor: "rgba(15, 113, 115, 1) !important",
  }),
  logo: css({ height: "auto !important" }),
};

const AdminNavBar: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector(SessionSelectors.assertGetCompanyProfile);
  const sessionData = useSelector<IAppState, ISessionState>(
    (state) => state.session
  );

  const routeMatch = useRouteMatch<INavBarMatchedRoute>(
    `${appBasePaths.app}/:currentRoute`
  );

  const currentRoute = routeMatch?.params.currentRoute;
  const selectedKeys: string[] = [];

  const handleClick = React.useCallback(
    (item: { key: string }) => {
      if (item.key === LOGOUT_KEY) {
        Modal.confirm({
          title: "Do you want to logout?",
          okText: "Yes",
          cancelText: "No",
          okType: "primary",
          okButtonProps: { danger: true },
          onOk: () => {
            dispatch(SessionActions.logoutUser());
          },
          onCancel() {
            // do nothing
          },
        });

        return;
      }

      history.push(item.key);
    },
    [history, dispatch]
  );

  const menuItemNodes = React.useMemo(() => {
    return MENU_ITEMS.filter((item) => item.visible !== false).map((item) =>
      isDivider(item) ? (
        <Menu.Divider />
      ) : isMenuItem01(item) ? (
        <Menu.Item key={item.pathname} className={classes.menuItem}>
          <Link to={item.pathname}>
            <Space size="large">
              {item.icon}
              <Typography.Text style={{ color: "inherit" }}>
                {item.label}
              </Typography.Text>
            </Space>
          </Link>
        </Menu.Item>
      ) : isMenuItem02(item) ? (
        <Menu.Item key={item.key} className={classes.menuItem}>
          <Space size="large">
            {item.icon}
            <Typography.Text>{item.label}</Typography.Text>
          </Space>
        </Menu.Item>
      ) : null
    );
  }, []);

  if (currentRoute) {
    selectedKeys.push(`${appBasePaths.app}/${currentRoute}`);
  } else {
    return <Redirect to={appLoggedInPaths.dashboard} />;
  }

  return (
    <Menu
      onClick={handleClick}
      style={{ width: NAV_WIDTH }}
      mode="vertical"
      selectedKeys={selectedKeys}
      className={classes.menu}
    >
      <Menu.Item disabled key="logo" className={classes.logo}>
        <Link to={appLoggedInPaths.dashboard}>
          <ImageWithFallback
            key={sessionData.companyAvatarLastUpdatedAt}
            preview={false}
            fallbackNode={<Logo />}
            alt={messages.movebotLogo}
            src={getLoadSingleImagePath(company.CompanyId)}
            width={LOGO_WIDTH}
            height={LOGO_HEIGHT}
          />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      {menuItemNodes}
    </Menu>
  );
};

function isDivider(item: typeof MENU_ITEMS[0]): item is { type: "divider" } {
  return (item as { type?: "divider" })?.type === "divider";
}

function isMenuItem01(item: typeof MENU_ITEMS[0]): item is {
  label: string;
  pathname: string;
  icon: React.ReactNode;
  visible?: boolean;
} {
  return !!(item as { pathname?: string })?.pathname;
}

function isMenuItem02(item: typeof MENU_ITEMS[0]): item is {
  label: string;
  key: string;
  icon: React.ReactNode;
  visible?: boolean;
} {
  return !!(item as { key?: string })?.key;
}

export default AdminNavBar;
