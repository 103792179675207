import React from "react";
import { IAnalysisOrderItemStat } from "../../definitions/analysis";
import CountList from "../utils/CountList";
import LabeledCount from "../utils/LabeledCount";

export interface IAnalysisOrderItemCountStatsProps {
  stats: IAnalysisOrderItemStat;
}

const AnalysisOrderItemCountStats: React.FC<
  IAnalysisOrderItemCountStatsProps
> = (props) => {
  const { stats } = props;
  return (
    <CountList>
      <LabeledCount label="Total Orders Count" count={stats.TotalOrders} />
      <LabeledCount label="Completed Count" count={stats.CompletedCount} />
      <LabeledCount label="In Progress Count" count={stats.InProgressCount} />
      <LabeledCount
        label="Needs Attention Count"
        count={stats.NeedsAttensionCount}
      />
    </CountList>
  );
};

export default AnalysisOrderItemCountStats;
