import { Card, Space, Typography } from "antd";
import React from "react";

export interface ILabeledCountProps {
  label: string;
  count: string | number;
}

const LabeledCount: React.FC<ILabeledCountProps> = (props) => {
  const { label, count } = props;
  return (
    <Card>
      <Space direction="vertical">
        <Typography.Text strong style={{ fontSize: "32px" }}>
          {count || 0}
        </Typography.Text>
        <Typography.Text type="secondary">{label}</Typography.Text>
      </Space>
    </Card>
  );
};

export default LabeledCount;
