import React from "react";
import { Select } from "antd";
import InlineLoading from "../utils/InlineLoading";
import FormError from "./FormError";
import ResourcesAPI from "../../api/endpoints/resources";
import { messages } from "../../definitions/messages";

export interface IStateCitiesInputProps {
  countryCode: string;
  state: string;
  value?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const StateCitiesInput: React.FC<IStateCitiesInputProps> = (props) => {
  const { value, countryCode, state, disabled, onChange } = props;
  const [fetching, setFetching] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [cities, setCities] = React.useState<Array<string> | null>(null);

  const getCities = React.useCallback(async () => {
    try {
      setError(null);
      setFetching(true);
      const result = await ResourcesAPI.getCountryStatesAndCities({
        countryCode,
        state,
      });

      setCities(result);
    } catch (error: any) {
      setError(error?.message || messages.initComponentError);
    }

    setFetching(false);
  }, [countryCode, state]);

  React.useEffect(() => {
    getCities();
  }, [getCities]);

  const optionsNode = React.useMemo(() => {
    return cities?.map((city) => (
      <Select.Option key={city} value={city} title={city}>
        {city}
      </Select.Option>
    ));
  }, [cities]);

  if (fetching) {
    return <InlineLoading />;
  } else if (error) {
    return <FormError>{error}</FormError>;
  }

  return (
    <Select
      showSearch
      disabled={disabled}
      value={value}
      onChange={(val) => {
        onChange(val);
      }}
      placeholder="Select city"
      optionFilterProp="title"
      filterOption={(input, option) =>
        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.title.toLowerCase().localeCompare(optionB.title.toLowerCase())
      }
    >
      {optionsNode}
    </Select>
  );
};

export default StateCitiesInput;
