import React from "react";
import useRequest from "@ahooksjs/use-request";
import { Select } from "antd";
import AlertWithReload from "../utils/AlertWithReload";
import ResourcesAPI from "../../api/endpoints/resources";
import { DashboardTimeFilterValue } from "../../definitions/dashboard";

export interface IDashboardStatisticFilterInputProps {
  value: DashboardTimeFilterValue;
  onChange: (value: DashboardTimeFilterValue) => void;
}

const DashboardStatisticFilterInput: React.FC<
  IDashboardStatisticFilterInputProps
> = (props) => {
  const { value, onChange } = props;
  const { error, data, loading, run } = useRequest(
    ResourcesAPI.getDashboardStatisticFilter
  );

  if (error) {
    return <AlertWithReload message={error.message} reloadFn={run} />;
  }

  const options = data || [];
  return (
    <Select
      showSearch
      loading={loading}
      disabled={loading}
      value={value}
      onChange={onChange}
      placeholder="Select Filter"
      optionFilterProp="title"
      filterOption={(input, option) =>
        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.title.toLowerCase().localeCompare(optionB.title.toLowerCase())
      }
      style={{ width: "160px" }}
    >
      {options.map((item) => (
        <Select.Option key={item.id} value={item.id} title={item.name}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DashboardStatisticFilterInput;
