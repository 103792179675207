import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import sessionReducer from "./session/reducer";

const reducer = combineReducers({
  session: sessionReducer,
});

const store = configureStore({
  reducer,
});

export default store;
