import React from "react";
import CompanyAPI from "../../api/endpoints/company";
import { appLoggedInPaths } from "../../definitions/paths";
import usePaginatedData from "../hooks/usePaginatedData";
import useSectionCurrentPage from "../hooks/useSectionCurrentPage";
import PageLoading from "../utils/PageLoading";
import CompaniesPage from "./CompaniesPage";
import CompanyFormContainer from "./CompanyFormContainer";

const CompaniesRoot: React.FC<{}> = () => {
  const pageData = usePaginatedData({
    fetch: CompanyAPI.getCompanies,
    getItemId: (item) => item.CompanyId,
  });

  const { isInitialized, reloadEverything, getItemById } = pageData;
  const {
    isCreateForm,
    updateItemId,
    gotoUpdateForm,
    gotoCreateForm,
    gotoBase,
  } = useSectionCurrentPage({
    baseURL: appLoggedInPaths.company,
    hasCreateForm: true,
    hasSelectedItemPage: false,
    hasUpdateForm: true,
    isComponentReady: isInitialized,
  });

  const onCompleteForm = React.useCallback(() => {
    gotoBase();
    reloadEverything();
  }, [gotoBase, reloadEverything]);

  if (isInitialized) {
    if (isCreateForm) {
      return (
        <CompanyFormContainer
          onCompleteCreateCompany={onCompleteForm}
          onCompleteUpdateCompany={onCompleteForm}
        />
      );
    } else if (updateItemId) {
      return (
        <CompanyFormContainer
          company={getItemById(updateItemId)!}
          onCompleteCreateCompany={onCompleteForm}
          onCompleteUpdateCompany={onCompleteForm}
        />
      );
    } else {
      return (
        <CompaniesPage
          {...pageData}
          onCreateCompany={gotoCreateForm}
          gotoUpdateForm={gotoUpdateForm}
        />
      );
    }
  }

  return <PageLoading />;
};

export default CompaniesRoot;
