import { DatePicker, Space, Typography } from "antd";
import moment from "moment";
import React from "react";
import { appDimensions } from "../utils/theme";
import AuditLogCompanyInput from "./AuditLogCompanyInput";
import ReferenceTypeInput from "./ReferenceTypeInput";

export interface IAuditLogsControlsOptions {
  type?: string;
  date?: string;
  logisticCompanyId?: string;
}

export interface IAuditLogsControlsProps {
  disabled?: boolean;
  options: IAuditLogsControlsOptions;
  onChange: (value: IAuditLogsControlsOptions) => void;
}

function disabledDate(current: moment.Moment) {
  // Can not select days before today and today
  return current && current > moment().endOf("day");
}

const AuditLogsControls: React.FC<IAuditLogsControlsProps> = (props) => {
  const { disabled, options, onChange } = props;

  return (
    <Space direction="vertical" size="middle">
      <Typography.Title level={3}>Audit Logs</Typography.Title>
      <Space size="middle">
        {/* @ts-ignore */}
        <DatePicker
          disabled={disabled}
          value={options.date ? moment(options.date) : undefined}
          onChange={(date) => {
            onChange({
              ...options,
              date: date?.toISOString(),
            });
          }}
          style={{ minWidth: appDimensions.optionsWidth }}
          disabledDate={disabledDate}
        />
        <AuditLogCompanyInput
          disabled={disabled}
          value={options.logisticCompanyId}
          onChange={(val) => {
            onChange({
              ...options,
              logisticCompanyId: val?.Id,
            });
          }}
        />
        <ReferenceTypeInput
          disabled={disabled}
          value={options.type}
          onChange={(val) => {
            onChange({ ...options, type: val?.id });
          }}
        />
      </Space>
    </Space>
  );
};

export default AuditLogsControls;
