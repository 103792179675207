import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import { useHistory } from "react-router";
import UserAPI from "../../api/endpoints/user";
import { ICreateAdminCompanyInput } from "../../definitions/company";
import { messages } from "../../definitions/messages";
import { appBasePaths } from "../../definitions/paths";
import SignupForm from "./SignupForm";

const SignupContainer: React.FC<{}> = () => {
  const history = useHistory();

  const onSubmit = React.useCallback(
    async (data: ICreateAdminCompanyInput) => {
      await UserAPI.signup(data);
      message.success(messages.signupSuccessful);
      history.push(appBasePaths.login);
    },
    [history]
  );

  const { loading, error, run } = useRequest(onSubmit, { manual: true });

  return (
    <SignupForm isSubmitting={loading} error={error?.message} onSubmit={run} />
  );
};

export default React.memo(SignupContainer);
