import { Alert, Button, Form, Input } from "antd";
import React from "react";
import * as yup from "yup";
import { userConstants } from "../../definitions/user";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import useFormHelpers from "../hooks/useFormHelpers";
import { css } from "@emotion/css";
import { messages } from "../../definitions/messages";
import { formValidationSchemas } from "../forms/validation";

const validationSchema = yup.object().shape({
  currentPassword: formValidationSchemas.passwordRequired,
  newPassword: formValidationSchemas.passwordRequired,
});

export interface IUpdateUserPasswordFormValues {
  currentPassword: string;
  newPassword: string;
}

export interface IUpdateUserPasswordFormProps {
  onSubmit: (values: IUpdateUserPasswordFormValues) => void | Promise<void>;
  isSubmitting?: boolean;
  error?: string;
}

const UpdateUserPasswordForm: React.FC<IUpdateUserPasswordFormProps> = (
  props
) => {
  const { onSubmit, isSubmitting, error } = props;

  const { formik } = useFormHelpers({
    formikProps: {
      onSubmit,
      validationSchema,
      initialValues: {
        currentPassword: "",
        newPassword: "",
      },
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <div className={formClasses.bodyInner}>
      {error && (
        <Form.Item>
          <Alert message={error} type="error" />
        </Form.Item>
      )}
      <form onSubmit={handleSubmit}>
        <Form.Item
          required
          label="Current Password"
          help={
            touched.currentPassword &&
            errors.currentPassword && (
              <FunErrorMessages message={errors.currentPassword}>
                <FormError error={errors.currentPassword} />
              </FunErrorMessages>
            )
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password
            visibilityToggle
            autoComplete="current-password"
            name="currentPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.currentPassword}
            disabled={isSubmitting}
            placeholder="Enter your current password"
            maxLength={userConstants.maxPasswordLength}
          />
        </Form.Item>
        <Form.Item
          required
          label="New Password"
          help={
            formik.touched?.newPassword && formik.errors?.newPassword ? (
              <FunErrorMessages message={formik.errors?.newPassword}>
                <FormError error={formik.errors?.newPassword} />
              </FunErrorMessages>
            ) : (
              messages.passwordExtraInfo
            )
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password
            visibilityToggle
            autoComplete="new-password"
            name="newPassword"
            value={formik.values.newPassword}
            onBlur={formik.handleBlur}
            onChange={handleChange}
            disabled={isSubmitting}
            placeholder="Enter your new password"
            maxLength={userConstants.maxPasswordLength}
          />
        </Form.Item>
        <Form.Item>
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
            })}
          >
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Save
            </Button>
          </div>
        </Form.Item>
      </form>
    </div>
  );
};

export default React.memo(UpdateUserPasswordForm);
