import { UserOutlined } from "@ant-design/icons";
import { Avatar, List, Space, Typography } from "antd";
import React from "react";
import DOMPurify from "dompurify";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import { ITicketActivity } from "../../definitions/ticket";
import ImageProvider from "../utils/ImageProvider";
import { appClasses } from "../utils/theme";

export interface ITicketActivityProps {
  className?: string;
  activity: ITicketActivity;
}

const ATTACHMENT_WIDTH = 80;

const TicketActivity: React.FC<ITicketActivityProps> = (props) => {
  const { activity, className } = props;
  return (
    <List.Item className={className}>
      <Space direction="vertical" size="middle">
        <Space align="start" size="middle">
          <Avatar
            icon={<UserOutlined />}
            src={
              activity.RespondantId
                ? getLoadSingleImagePath(activity.RespondantId)
                : undefined
            }
            alt="Respondant's profile picture"
            shape="square"
          />
          <Space direction="vertical" size={2}>
            <Typography.Text strong>{activity.Header}</Typography.Text>
            <Typography.Text type="secondary">
              {activity.Timestamp_}
            </Typography.Text>
            <Typography.Text className={appClasses.paragraph}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(activity.Message),
                }}
              />
            </Typography.Text>
            {activity.AttachmentImageCount > 0 && (
              <ImageProvider
                id={activity.AttachmentId}
                imageAltBase="Ticket activity attachment"
                count={activity.AttachmentImageCount}
                width={ATTACHMENT_WIDTH}
                height={ATTACHMENT_WIDTH}
              />
            )}
          </Space>
        </Space>
      </Space>
    </List.Item>
  );
};

export default TicketActivity;
