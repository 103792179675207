import React from "react";
import { IUsePaginatedDataResult } from "../hooks/usePaginatedData";
import CompanyTable from "./CompanyTable";
import { ICompany } from "../../definitions/company";
import CompaniesControls from "./CompanyControls";
import PagePagination from "../utils/PagePaination";
import PageError from "../utils/PageError";
import { appClasses } from "../utils/theme";

export interface ICompaniesPageProps
  extends IUsePaginatedDataResult<ICompany, {}> {
  onCreateCompany: () => void;
  gotoUpdateForm: (id: string) => void;
}

const CompaniesPage: React.FC<ICompaniesPageProps> = (props) => {
  const {
    page,
    isInitialized,
    getPageItems,
    reloadEverything,
    getPageError,
    getPageLoading,
    onCreateCompany,
    gotoUpdateForm,
  } = props;

  let contentNode: React.ReactNode = null;

  if (getPageError(page)) {
    contentNode = (
      <PageError
        messageText={getPageError(page)}
        actions={[{ text: "Reload Companies", onClick: reloadEverything }]}
      />
    );
  } else if (isInitialized) {
    const pageData = getPageItems(page);
    contentNode = (
      <CompanyTable
        loading={getPageLoading(page)}
        data={pageData}
        onCompleteDelete={() => reloadEverything(true)}
        onCompleteUpdateStatus={() => reloadEverything(true)}
        onEdit={gotoUpdateForm}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <CompaniesControls onCreateCompany={onCreateCompany} />
      </div>
      <div className={appClasses.pageContent}>{contentNode}</div>
      <div className={appClasses.pagePagination}>
        <PagePagination {...props} />
      </div>
    </div>
  );
};

export default CompaniesPage;
