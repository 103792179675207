import React from "react";
import TicketAPI from "../../api/endpoints/ticket";
import { appLoggedInPaths } from "../../definitions/paths";
import usePageData from "../hooks/usePageData";
import useSectionCurrentPage from "../hooks/useSectionCurrentPage";
import PageLoading from "../utils/PageLoading";
import Ticket from "./Ticket";
import TicketContainer from "./TicketContainer";
import TicketFormContainer from "./TicketFormContainer";
import TicketsPage from "./TicketsPage";

const TicketsRoot: React.FC<{}> = (props) => {
  const pageData = usePageData({
    pageDataProps: {
      fetch: TicketAPI.getTickets,
      getItemId: (item) => item.TicketId,
    },
    searchDataProps: {
      manual: true,
      fetch: TicketAPI.searchTickets,
    },
  });

  const { isInitialized, reloadEverything, getItemById, reloadPageItems } =
    pageData;

  const { isCreateForm, selectedItemId, gotoCreateForm, gotoBase } =
    useSectionCurrentPage({
      baseURL: appLoggedInPaths.ticket,
      hasCreateForm: true,
      hasSelectedItemPage: true,
      hasUpdateForm: false,
      isComponentReady: true,
    });

  const onCompleteCreateTicket = React.useCallback(() => {
    gotoBase();
    reloadEverything();
  }, [gotoBase, reloadEverything]);

  if (selectedItemId) {
    return <TicketContainer onCompleteUpdate={reloadPageItems} />;
  }

  if (isInitialized) {
    const selectedItem = selectedItemId && getItemById(selectedItemId);

    if (isCreateForm) {
      return (
        <TicketFormContainer onCompleteCreateTicket={onCompleteCreateTicket} />
      );
    } else if (selectedItem) {
      return (
        <Ticket
          ticket={selectedItem}
          onCompleteUpdateStatus={reloadPageItems}
        />
      );
    } else {
      return <TicketsPage {...pageData} onCreateTicket={gotoCreateForm} />;
    }
  }

  return <PageLoading />;
};

export default TicketsRoot;
