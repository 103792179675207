import {
  DashboardTimeFilterValue,
  IDashboardOrderCreationStatics,
  IDashboardRecentOpenTicket,
  IDashboardRecentOrderUpdate,
  IDashboardStaticCounts,
} from "../../definitions/dashboard";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { selectEndpointParam, setEndpointParam } from "../utils";

const dashboardURL = `${netAddrs.serverAddr}/Dashboard`;
const getStaticCountsURL = `${dashboardURL}/GetStaticCounts`;
const getRecentOrderUpdateURL = `${dashboardURL}/GetRecentOrderUpdate`;
const getOrderCreationStaticsURL = `${dashboardURL}/GetOrderCreationStatics`;
const getRecentOpenTicketsURL = `${dashboardURL}/GetRecentOpenTickets`;

export interface IGetStaticCountsEndpointParams {
  time: DashboardTimeFilterValue;
}

async function getStaticCounts(
  props: IGetStaticCountsEndpointParams
): Promise<IDashboardStaticCounts> {
  const params = new URLSearchParams();
  setEndpointParam(params, "time", props.time);

  const endpointURL = selectEndpointParam(getStaticCountsURL, params, props);
  const result = await invokeEndpointWithAuth<IDashboardStaticCounts>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

async function getRecentOrderUpdate(): Promise<
  Array<IDashboardRecentOrderUpdate>
> {
  const result = await invokeEndpointWithAuth<
    Array<IDashboardRecentOrderUpdate>
  >({
    path: getRecentOrderUpdateURL,
    method: "GET",
  });

  return result;
}

export interface IGetOrderCreationStaticsEndpointParams {
  time: DashboardTimeFilterValue;
}

async function getOrderCreationStatics(
  props: IGetOrderCreationStaticsEndpointParams
): Promise<Array<IDashboardOrderCreationStatics>> {
  const params = new URLSearchParams();
  setEndpointParam(params, "time", props.time);

  const endpointURL = selectEndpointParam(
    getOrderCreationStaticsURL,
    params,
    props
  );

  const result = await invokeEndpointWithAuth<
    Array<IDashboardOrderCreationStatics>
  >({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

async function getRecentOpenTickets(): Promise<
  Array<IDashboardRecentOpenTicket>
> {
  const result = await invokeEndpointWithAuth<
    Array<IDashboardRecentOpenTicket>
  >({
    path: getRecentOpenTicketsURL,
    method: "GET",
  });

  return result;
}

export default class DashboardAPI {
  public static getRecentOpenTickets = getRecentOpenTickets;
  public static getOrderCreationStatics = getOrderCreationStatics;
  public static getRecentOrderUpdate = getRecentOrderUpdate;
  public static getStaticCounts = getStaticCounts;
}
