import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import GroupPolicyAPI, {
  ICreatePolicyEndpointProps,
} from "../../api/endpoints/group-policy";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { IGroupPolicy } from "../../definitions/group-policy";
import { messages } from "../../definitions/messages";
import { wrapWithMessageError } from "../utils/utils";
import PolicyForm, { IPolicyFormValues } from "./PolicyForm";

export interface IPolicyFormContainerProps {
  policy?: IGroupPolicy;
  onCompleteUpdatePolicy: () => void;
}

const PolicyFormContainer: React.FC<IPolicyFormContainerProps> = (props) => {
  const { policy, onCompleteUpdatePolicy } = props;
  const onSubmit = React.useCallback(
    async (data: IPolicyFormValues) => {
      wrapWithMessageError(async () => {
        const apiData: ICreatePolicyEndpointProps = {
          roleType: data.role._RoleType,
          groupName: data.groupName,
          description: data.description,
          assignedGroupPolicy: data.assignedGroupPolicy,
        };

        if (policy) {
          const result = await GroupPolicyAPI.updatePolicy({
            data: apiData,
            groupPolicyId: policy.GroupPolicyId,
          });

          onCompleteUpdatePolicy();
          message.success(
            getEndpointResultMessage(result) ||
              messages.policyUpdatedWithReloadPrompt
          );
        } else {
          const result = await GroupPolicyAPI.createPolicy(apiData);
          message.success(
            getEndpointResultMessage(result) ||
              messages.policyCreatedWithReloadPrompt
          );
        }
      });
    },
    [policy, onCompleteUpdatePolicy]
  );

  const {
    loading: isSubmitting,
    error: submitError,
    run: onSubmitForm,
  } = useRequest(onSubmit, { manual: true });

  return (
    <PolicyForm
      onSubmit={onSubmitForm}
      data={policy}
      error={submitError?.message}
      isSubmitting={isSubmitting}
    />
  );
};

export default PolicyFormContainer;
