import React from "react";
import PolicyList from "./PolicyList";
import { IUsePaginatedDataResult } from "../hooks/usePaginatedData";
import { IGroupPolicy } from "../../definitions/group-policy";
import PoliciesPageControls, {
  IPoliciesPageControlsOptions,
} from "./PoliciesPageControls";
import PageError from "../utils/PageError";
import PagePagination from "../utils/PagePaination";
import { appClasses } from "../utils/theme";
import { cx } from "@emotion/css";

export interface IPoliciesPageProps
  extends IUsePaginatedDataResult<IGroupPolicy, IPoliciesPageControlsOptions> {
  onCreateGroupPolicy: () => void;
  gotoUpdateForm: (id: string) => void;
}

const PoliciesPage: React.FC<IPoliciesPageProps> = (props) => {
  const {
    page,
    fetchExtra,
    getPageLoading,
    getPageError,
    getPageItems,
    gotoUpdateForm,
    reloadEverything,
    setFetchExtra,
    onCreateGroupPolicy,
  } = props;

  let contentNode: React.ReactNode = null;

  if (getPageError(page)) {
    contentNode = (
      <PageError
        messageText={getPageError(page)}
        actions={[{ text: "Reload Group Policies", onClick: reloadEverything }]}
      />
    );
  } else {
    contentNode = (
      <PolicyList
        policies={getPageItems(page)}
        loading={getPageLoading(page)}
        onEdit={gotoUpdateForm}
        onReloadPage={() => reloadEverything(true)}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <PoliciesPageControls
          options={fetchExtra}
          gotoCreateForm={onCreateGroupPolicy}
          onChange={(value) => setFetchExtra(value, true)}
        />
      </div>
      <div className={cx(appClasses.pageContent, appClasses.pageList)}>
        {contentNode}
      </div>
      <div className={appClasses.pagePagination}>
        <PagePagination {...props} />
      </div>
    </div>
  );
};

export default PoliciesPage;
