import { message } from "antd";
import React from "react";
import EmployeeAPI from "../../api/endpoints/employee";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { IEmployee } from "../../definitions/employee";
import { messages } from "../../definitions/messages";
import { IUsePaginatedDataResult } from "../hooks/usePaginatedData";
import { IDeleteActionDeleteFnProps } from "../utils/delete/DeleteAction";
import PageError from "../utils/PageError";
import PagePagination from "../utils/PagePaination";
import { ISubmitStatusFnProps } from "../utils/status/Status";
import { appClasses } from "../utils/theme";
import { wrapWithMessageError } from "../utils/utils";
import EmployeesPageControls from "./EmployeesPageControls";
import EmployeeTable from "./EmployeeTable";

export interface IEmployeesPageProps
  extends IUsePaginatedDataResult<IEmployee, {}> {
  onCreateEmployee: () => void;
  gotoUpdateForm: (id: string) => void;
}

const EmployeesPage: React.FC<IEmployeesPageProps> = (props) => {
  const {
    page,
    getPageItems,
    reloadEverything,
    onCreateEmployee,
    gotoUpdateForm,
    getPageError,
    getPageLoading,
  } = props;

  let contentNode: React.ReactNode = null;
  const deleteEmployeeFn = React.useCallback(
    async (deleteProps: IDeleteActionDeleteFnProps) => {
      wrapWithMessageError(async () => {
        const result = await EmployeeAPI.deleteEmployee(deleteProps);
        message.success(
          getEndpointResultMessage(result) || messages.requestSuccessful
        );
      });
    },
    []
  );

  const submitStatusFn = React.useCallback(
    async (statusProps: ISubmitStatusFnProps) => {
      wrapWithMessageError(async () => {
        const result = await EmployeeAPI.updateEmployeeStatus({
          employeeId: statusProps.id,
          reason: statusProps.reason,
          statusType: statusProps.status,
          showStatusReasonToUser: !!statusProps.showStatusReason,
        });

        message.success(
          getEndpointResultMessage(result) || messages.requestSuccessful
        );
      });
    },
    []
  );

  if (getPageError(page)) {
    contentNode = (
      <PageError
        messageText={getPageError(page)}
        actions={[{ text: "Reload Employees", onClick: reloadEverything }]}
      />
    );
  } else {
    const pageData = getPageItems(page);
    contentNode = (
      <EmployeeTable
        loading={getPageLoading(page)}
        data={pageData}
        onCompleteDelete={() => reloadEverything(true)}
        onCompleteUpdateStatus={() => reloadEverything(true)}
        deleteEmployeeFn={deleteEmployeeFn}
        onEdit={gotoUpdateForm}
        submitStatusFn={submitStatusFn}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <EmployeesPageControls onCreateEmployee={onCreateEmployee} />
      </div>
      <div className={appClasses.pageContent}>{contentNode}</div>
      <div className={appClasses.pagePagination}>
        <PagePagination {...props} />
      </div>
    </div>
  );
};

export default EmployeesPage;
