import { Button, Typography } from "antd";
import React from "react";
import { appClasses } from "../utils/theme";

export interface IEmployeesPageControlsProps {
  onCreateEmployee: () => void;
}

const EmployeesPageControls: React.FC<IEmployeesPageControlsProps> = (
  props
) => {
  const { onCreateEmployee } = props;
  return (
    <div className={appClasses.controls.root}>
      <div className={appClasses.controls.leftControls}>
        <Typography.Title level={3}>Employees</Typography.Title>
      </div>
      <div>
        <Button type="primary" onClick={() => onCreateEmployee()}>
          Add Employee
        </Button>
      </div>
    </div>
  );
};

export default EmployeesPageControls;
