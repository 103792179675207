import { css } from "@emotion/css";
import React from "react";
import Logo from "../utils/Logo";

const FormLogo: React.FC<{}> = (props) => {
  return (
    <div
      className={css({
        display: "flex",
        width: "100%",
        marginBottom: "32px",
        marginTop: "48px",
      })}
    >
      <Logo />
    </div>
  );
};

export default FormLogo;
