import useRequest from "@ahooksjs/use-request";
import React from "react";
import { Redirect } from "react-router";
import TicketAPI from "../../api/endpoints/ticket";
import { appLoggedInPaths } from "../../definitions/paths";
import useSectionCurrentPage from "../hooks/useSectionCurrentPage";
import PageError from "../utils/PageError";
import PageLoading from "../utils/PageLoading";
import Ticket from "./Ticket";

export interface ITicketContainerProps {
  onCompleteUpdate?: () => void;
}

class TicketNumberNotProvided extends Error {
  message = "Ticket number not provided";
}

const TicketContainer: React.FC<ITicketContainerProps> = (props) => {
  const { onCompleteUpdate } = props;
  const { selectedItemId } = useSectionCurrentPage({
    baseURL: appLoggedInPaths.ticket,
    hasCreateForm: false,
    hasSelectedItemPage: true,
    hasUpdateForm: false,
    isComponentReady: true,
  });

  const getTicket = React.useCallback(() => {
    if (!selectedItemId) {
      throw new TicketNumberNotProvided();
    }

    return TicketAPI.getTicket({ ticketNumber: selectedItemId });
  }, [selectedItemId]);

  const { loading, error, data, run } = useRequest(getTicket);
  const internalOnCompleteUpdate = React.useCallback(() => {
    run();

    if (onCompleteUpdate) {
      onCompleteUpdate();
    }
  }, [run, onCompleteUpdate]);

  if (error) {
    if (error instanceof TicketNumberNotProvided) {
      return <Redirect to={appLoggedInPaths.ticket} />;
    }

    return (
      <PageError
        messageText={error?.message}
        actions={[{ text: "Reload Tickets", onClick: run }]}
      />
    );
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  return (
    <Ticket ticket={data} onCompleteUpdateStatus={internalOnCompleteUpdate} />
  );
};

export default TicketContainer;
