import { css, cx } from "@emotion/css";
import { Alert, Button, Form, Input, Typography } from "antd";
import React from "react";
import * as yup from "yup";
import {
  IGroupPolicy,
  IRole,
  IRolePermission,
} from "../../definitions/group-policy";
import { messages } from "../../definitions/messages";
import { indexArray } from "../../utils/utils";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import { formValidationSchemas } from "../forms/validation";
import useFormHelpers from "../hooks/useFormHelpers";
import PermissionsInput from "./PermissionsInput";
import RolesInput from "./RolesInput";

const validationSchema = yup.object().shape({
  groupName: formValidationSchemas.strRequired,
  description: formValidationSchemas.strRequired,
  role: yup.object().required(messages.requiredField),
});

export interface IPolicyFormValues {
  role: IRole;
  groupName: string;
  description: string;
  assignedGroupPolicy: string[];
  permissions: IRolePermission[];
}

export interface IPolicyFormInternalValues {
  role?: IRole;
  groupName: string;
  description: string;
  assignedGroupPolicy: Record<string, IRolePermission>;
  permissions: IRolePermission[];
}

export interface IPolicyFormProps {
  error?: string;
  isSubmitting?: boolean;
  data?: IGroupPolicy;
  onSubmit: (values: IPolicyFormValues) => void | Promise<void>;
}

const PolicyForm: React.FC<IPolicyFormProps> = (props) => {
  const { isSubmitting, data, error, onSubmit } = props;

  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      initialValues: {
        groupName: data?.GroupName || "",
        role: data?.RoleInfo,
        description: data?.Description || "",
        assignedGroupPolicy: data?.AssignedPolicy
          ? indexArray(data.AssignedPolicy, {
              path: "PolicyId",
            })
          : {},
        permissions: [] as IRolePermission[],
      } as IPolicyFormInternalValues,
      onSubmit: (data) => {
        if (!data.role) {
          return;
        }

        onSubmit({
          groupName: data.groupName,
          description: data.description,
          role: data.role,
          assignedGroupPolicy: Object.keys(data.assignedGroupPolicy),
          permissions: data.permissions,
        });
      },
    },
  });

  const nameNode = (
    <Form.Item
      required
      label="Group Policy Name"
      help={
        formik.touched?.groupName &&
        formik.errors?.groupName && (
          <FunErrorMessages message={formik.errors.groupName}>
            <FormError error={formik.errors.groupName} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="groupName"
        value={formik.values.groupName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter group policy name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const descriptionNode = (
    <Form.Item
      label="Group Policy Description"
      help={
        formik.touched?.description &&
        formik.errors?.description && (
          <FunErrorMessages message={formik.errors.description}>
            <FormError error={formik.errors.description} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        name="description"
        value={formik.values.description}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter group policy description"
        disabled={isSubmitting}
        autoSize={{ minRows: 2 }}
      />
    </Form.Item>
  );

  const roleNode = (
    <Form.Item
      required
      label="Role"
      help={
        formik.touched?.role &&
        formik.errors?.role && (
          <FunErrorMessages message={formik.errors.role}>
            <FormError error={formik.errors.role} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <RolesInput
        allowEmpty={false}
        value={formik.values.role?.RoleId}
        disabled={isSubmitting}
        onChange={(val) => {
          formik.setFieldValue("role", val);
        }}
        onInit={(val) => {
          formik.setFieldValue("role", val);
        }}
      />
    </Form.Item>
  );

  const permissionsNode = (
    <Form.Item
      required
      label="Permissions"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      {!formik.values.role ? (
        <Alert type="warning" message="Select a role first" />
      ) : (
        <PermissionsInput
          disabled={isSubmitting}
          value={formik.values.assignedGroupPolicy}
          roleType={formik.values.role._RoleType}
          onChange={(assignedGroupPolicy, permissions) => {
            formik.setValues({
              ...formik.values,
              assignedGroupPolicy,
              permissions,
            });
          }}
        />
      )}
    </Form.Item>
  );

  return (
    <div className={cx(formClasses.body, css({ padding: 0 }))}>
      <div className={formClasses.bodyInner}>
        <Form.Item>
          <Typography.Title level={4}>Group Policy Form</Typography.Title>
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        <form onSubmit={formik.handleSubmit}>
          {nameNode}
          {descriptionNode}
          {roleNode}
          {permissionsNode}
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </Button>
          </Form.Item>
        </form>
      </div>
    </div>
  );
};

export default React.memo(PolicyForm);
