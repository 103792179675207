import { Divider, List } from "antd";
import React from "react";
import { ITrackerEvent } from "../../definitions/tracker";
import ListSkeleton from "../utils/ListSkeleton";
import PageNothingFound from "../utils/PageNothingFound";
import TrackerEvent from "./TrackerEvent";

export interface ITrackerEventListProps {
  loading?: boolean;
  events: Array<ITrackerEvent>;
}

const TrackerEventList: React.FC<ITrackerEventListProps> = (props) => {
  const { loading, events } = props;

  if (loading) {
    return <ListSkeleton />;
  }

  if (events.length === 0) {
    return <PageNothingFound />;
  }

  return (
    <List
      split
      bordered
      loading={loading}
      itemLayout="horizontal"
      dataSource={events}
      renderItem={(item, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Divider />}
            <TrackerEvent event={item} />
          </React.Fragment>
        );
      }}
    />
  );
};

export default TrackerEventList;
