import { Table, TableColumnType } from "antd";
import React from "react";
import { IAuditLogItem } from "../../definitions/audit";
import TableSkeleton from "../utils/TableSkeleton";

export interface IAuditLogTableProps {
  loading?: boolean;
  data: IAuditLogItem[];
}

const AuditLogTable: React.FC<IAuditLogTableProps> = (props) => {
  const { data, loading } = props;
  const columns: TableColumnType<IAuditLogItem>[] = [
    {
      title: <div style={{ width: "200px" }}>Date</div>,
      dataIndex: "TimeStamp_",
      key: "TimeStamp_",
    },
    {
      title: "Company",
      dataIndex: "CompanyName",
      key: "CompanyName",
      width: 200,
    },
    {
      title: "User",
      dataIndex: "Name",
      key: "Name",
      width: 160,
    },
    {
      title: "Event",
      dataIndex: "Event",
      key: "Event",
      width: 120,
    },
    {
      title: "Target",
      dataIndex: "Target",
      key: "Target",
      width: 160,
    },
    {
      title: "Reference",
      dataIndex: "Reference",
      key: "Reference",
      width: 160,
    },
    {
      title: "Message",
      dataIndex: "Message",
      key: "Message",
    },
  ];

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey={(item) => {
        return item.itemId;
      }}
    />
  );
};

export default AuditLogTable;
