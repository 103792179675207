import {
  ITicket,
  ITicketActivity,
  ITicketActivityInput,
  ITicketInput,
  ITicketOrganization,
  TicketPriority,
} from "../../definitions/ticket";
import {
  IPaginatedDataInput,
  IPaginatedDataResult,
  TicketStatus,
} from "../../definitions/utils";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { IEndpointMessageResult } from "../types";
import { selectEndpointParam, setEndpointParam } from "../utils";

const ticketURL = `${netAddrs.serverAddr}/Ticket`;
const pageURL = `${ticketURL}/Page`;
const searchTicketsURL = `${ticketURL}/Search`;
const getTicketActivitiesURL = `${ticketURL}/ActivitiesInfo`;
const getTicketURL = `${ticketURL}/GetTicketInfo`;
const submitTicketURL = `${ticketURL}/Submit`;
const submitTicketActivityURL = `${ticketURL}/SubmitActivity`;
const updateTicketStatusURL = `${ticketURL}/UpdateTicketStatus`;
const getTicketOrganizationsURL = `${ticketURL}/GetTicketOrganizations`;

export interface IGetTicketsEndpointResult
  extends IPaginatedDataResult<ITicket> {}

export interface IGetTicketsEndpointParams extends IPaginatedDataInput {
  status?: TicketStatus;
  priority?: TicketPriority;
  orgId?: string;
}

async function getTickets(
  props: IGetTicketsEndpointParams = {}
): Promise<IGetTicketsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "status", props.status);
  setEndpointParam(params, "priority", props.priority);
  setEndpointParam(params, "orgId", props.orgId);

  const endpointURL = selectEndpointParam(pageURL, params, props);
  const result = await invokeEndpointWithAuth<IGetTicketsEndpointResult>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export interface ISearchTicketsEndpointParams {
  query: string;
  orgId?: string;
}

async function searchTickets(
  props: ISearchTicketsEndpointParams
): Promise<ITicket[]> {
  const params = new URLSearchParams();
  setEndpointParam(params, "query", props.query);
  setEndpointParam(params, "orgId", props.orgId);

  const endpointURL = selectEndpointParam(searchTicketsURL, params, props);
  const result = await invokeEndpointWithAuth<ITicket[]>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export interface IGetTicketActivitiesEndpointParams {
  ticketId: string;
}

async function getTicketActivities(
  props: IGetTicketActivitiesEndpointParams
): Promise<ITicketActivity[]> {
  const params = new URLSearchParams();
  setEndpointParam(params, "ticketId", props.ticketId);

  const endpointURL = selectEndpointParam(
    getTicketActivitiesURL,
    params,
    props
  );

  const result = await invokeEndpointWithAuth<ITicketActivity[]>({
    path: endpointURL,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export interface IGetTicketEndpointParams {
  ticketNumber: string;
}

async function getTicket(props: IGetTicketEndpointParams): Promise<ITicket> {
  const params = new URLSearchParams();
  setEndpointParam(params, "ticketNumber", props.ticketNumber);

  const endpointURL = selectEndpointParam(getTicketURL, params, props);
  const result = await invokeEndpointWithAuth<ITicket>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export interface ICreateTicketEndpointParams {
  orgId: string;
  data: ITicketInput;
}

async function createTicket(
  props: ICreateTicketEndpointParams
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);

  const endpointURL = selectEndpointParam(submitTicketURL, params, props);
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: endpointURL,
    method: "POST",
    data: props.data,
  });

  return result;
}

export interface ISubmitTicketActivityEndpointParams {
  orgId: string;
  data: ITicketActivityInput;
}

async function submitTicketActivity(
  props: ISubmitTicketActivityEndpointParams
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);

  const endpointURL = selectEndpointParam(
    submitTicketActivityURL,
    params,
    props
  );

  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: endpointURL,
    method: "POST",
    data: props.data,
  });

  return result;
}

export interface IUpdateTicketStatusEndpointParams {
  ticketId: string;
  status: TicketStatus;
}

async function updateTicketStatus(
  props: IUpdateTicketStatusEndpointParams
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "ticketId", props.ticketId);
  setEndpointParam(params, "status", props.status);

  const endpointURL = selectEndpointParam(updateTicketStatusURL, params, props);
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

async function getTicketOrganizations(): Promise<ITicketOrganization[]> {
  const result = await invokeEndpointWithAuth<ITicketOrganization[]>({
    path: getTicketOrganizationsURL,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export default class TicketAPI {
  public static getTickets = getTickets;
  public static searchTickets = searchTickets;
  public static getTicketActivities = getTicketActivities;
  public static createTicket = createTicket;
  public static submitTicketActivity = submitTicketActivity;
  public static updateTicketStatus = updateTicketStatus;
  public static getTicketOrganizations = getTicketOrganizations;
  public static getTicket = getTicket;
}
