import { Modal } from "antd";
import React from "react";
import CompanyStatusFormContainer from "./CompanyStatusFormContainer";
import { ICompany } from "../../definitions/company";
import StatusInput from "../forms/StatusInput";

export interface ICompanyStatusProps {
  company: ICompany;
  onCompleteUpdateStatus: () => void;
}

const CompanyStatus: React.FC<ICompanyStatusProps> = (props) => {
  const { company, onCompleteUpdateStatus } = props;
  const [showReasonInput, setReasonInputVisibility] = React.useState(false);
  const [cachedStatus, setCachedStatus] = React.useState<string | null>(null);

  const onCancelUpdate = React.useCallback(() => {
    setReasonInputVisibility(false);
    setCachedStatus(null);
  }, []);

  const onInitialSelectStatus = React.useCallback((value: string) => {
    setCachedStatus(value);
    setReasonInputVisibility(true);
  }, []);

  const internalOnCompleteUpdateStatus = React.useCallback(() => {
    setCachedStatus(null);
    setReasonInputVisibility(false);
    onCompleteUpdateStatus();
  }, [onCompleteUpdateStatus]);

  return (
    <React.Fragment>
      {showReasonInput ? (
        <Modal
          destroyOnClose
          visible={showReasonInput}
          title="Update Company Status"
          footer={null}
          onCancel={onCancelUpdate}
        >
          <CompanyStatusFormContainer
            onCancel={onCancelUpdate}
            initialStatus={cachedStatus}
            company={company}
            onCompleteUpdateStatus={internalOnCompleteUpdateStatus}
          />
        </Modal>
      ) : (
        <StatusInput
          useDropdown
          onChange={onInitialSelectStatus}
          value={company.Status.Name}
          statusColor={company.Status.Color}
        />
      )}
    </React.Fragment>
  );
};

export default CompanyStatus;
