import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin, Typography } from "antd";
import { css } from "@emotion/css";
import React from "react";
import { messages } from "../../definitions/messages";

export interface IInlineLoadingProps {
  text?: string;
}

const InlineLoading: React.FC<IInlineLoadingProps> = (props) => {
  const { text } = props;

  return (
    <Space>
      <Spin indicator={<LoadingOutlined spin style={{ fontSize: 16 }} />} />
      <Typography.Text className={css({ textTransform: "capitalize" })}>
        {text || messages.loading}
      </Typography.Text>
    </Space>
  );
};

export default InlineLoading;
