import React from "react";
import { Select } from "antd";
import InlineLoading from "../utils/InlineLoading";
import FormError from "./FormError";
import ResourcesAPI from "../../api/endpoints/resources";
import { messages } from "../../definitions/messages";

export interface ICountryStatesInputProps {
  countryCode: string;
  value?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const CountryStatesInput: React.FC<ICountryStatesInputProps> = (props) => {
  const { value, countryCode, disabled, onChange } = props;
  const [fetching, setFetching] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [states, setStates] = React.useState<Array<string> | null>(null);

  const getStates = React.useCallback(async () => {
    try {
      setError(null);
      setFetching(true);
      const states = await ResourcesAPI.getCountryStatesAndCities({
        countryCode,
      });

      setStates(states);
    } catch (error: any) {
      setError(error?.message || messages.initComponentError);
    }

    setFetching(false);
  }, [countryCode]);

  React.useEffect(() => {
    getStates();
  }, [getStates]);

  const optionsNode = React.useMemo(() => {
    return states?.map((state) => (
      <Select.Option key={state} value={state} title={state}>
        {state}
      </Select.Option>
    ));
  }, [states]);

  if (fetching) {
    return <InlineLoading />;
  } else if (error) {
    return <FormError>{error}</FormError>;
  }

  return (
    <Select
      showSearch
      disabled={disabled}
      value={value}
      onChange={(val) => {
        onChange(val);
      }}
      placeholder="Select state"
      optionFilterProp="title"
      filterOption={(input, option) =>
        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.title.toLowerCase().localeCompare(optionB.title.toLowerCase())
      }
    >
      {optionsNode}
    </Select>
  );
};

export default CountryStatesInput;
