import React from "react";
import { Dropdown, Menu, Select, Space, Tag } from "antd";
import InlineLoading from "../utils/InlineLoading";
import FormError from "./FormError";
import { useRequest } from "ahooks";
import ResourcesAPI from "../../api/endpoints/resources";
import { css } from "@emotion/css";
import { CaretDownOutlined } from "@ant-design/icons";

export interface IStatusInputProps {
  useDropdown?: boolean;
  value?: string;
  statusColor?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const StatusInput: React.FC<IStatusInputProps> = (props) => {
  const { value, disabled, statusColor, useDropdown, onChange } = props;
  const { data, error, loading } = useRequest(ResourcesAPI.getStatusTypes);

  const optionsNode = React.useMemo(() => {
    if (useDropdown) {
      const nodes: React.ReactNode[] = [];
      data?.forEach((status, i) => {
        if (i !== 0) {
          nodes.push(<Menu.Divider key={`divider-${i}`} />);
        }

        nodes.push(<Menu.Item key={status}>{status}</Menu.Item>);
      });

      return nodes;
    }

    return data?.map((status) => (
      <Select.Option key={status} value={status} title={status}>
        {status}
      </Select.Option>
    ));
  }, [data, useDropdown]);

  if (loading) {
    return <InlineLoading />;
  } else if (error) {
    return <FormError>{error}</FormError>;
  }

  if (useDropdown) {
    const selectedKeys: string[] = [];

    if (value) {
      selectedKeys.push(value);
    }

    const menuNode = (
      <Menu
        selectedKeys={selectedKeys}
        onClick={(evt) => {
          onChange(evt.key);
        }}
      >
        {optionsNode}
      </Menu>
    );

    return (
      <Dropdown overlay={menuNode} trigger={["click"]} disabled={disabled}>
        <Space size={4} className={css({ cursor: "pointer" })}>
          <Tag
            style={{
              color: statusColor,
            }}
          >
            {value}
          </Tag>
          <CaretDownOutlined />
        </Space>
      </Dropdown>
    );
  }

  return (
    <Select
      showSearch
      disabled={disabled}
      value={value}
      onChange={(val) => {
        onChange(val);
      }}
      placeholder="Select status"
      optionFilterProp="title"
      filterOption={(input, option) =>
        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.title.toLowerCase().localeCompare(optionB.title.toLowerCase())
      }
    >
      {optionsNode}
    </Select>
  );
};

export default StatusInput;
