import { Button, Modal, Tooltip } from "antd";
import React from "react";
import DeleteCompanyReasonFormContainer from "./DeleteReasonFormContainer";
import { DeleteOutlined } from "@ant-design/icons";
import { IDeleteReasonFormValues } from "./DeleteReasonForm";

export interface IDeleteActionDeleteFnProps extends IDeleteReasonFormValues {
  id: string;
}

export interface IDeleteActionProps {
  id: string;
  name: string;
  modalTitle?: string;
  promptMessage?: string;
  deleteFn: (props: IDeleteActionDeleteFnProps) => void;
  onCompleteDelete: () => void;
}

const DeleteAction: React.FC<IDeleteActionProps> = (props) => {
  const { id, name, modalTitle, promptMessage, deleteFn, onCompleteDelete } =
    props;

  const [showReasonInput, setReasonInputVisibility] = React.useState(false);
  const onCancelUpdate = React.useCallback(() => {
    setReasonInputVisibility(false);
  }, []);

  const internalDeleteFn = React.useCallback(
    async (deleteProps: IDeleteReasonFormValues) => {
      await deleteFn({ ...deleteProps, id });
      setReasonInputVisibility(false);
      onCompleteDelete();
    },
    [id, deleteFn, onCompleteDelete]
  );

  const internalOnDelete = React.useCallback(async () => {
    Modal.confirm({
      title: promptMessage || "Are you sure you want to make this change?",
      okText: "Yes",
      cancelText: "No",
      okType: "primary",
      okButtonProps: { danger: true },
      onOk: () => {
        setReasonInputVisibility(true);
      },
      onCancel() {
        // do nothing
      },
    });
  }, [promptMessage]);

  return (
    <React.Fragment>
      {showReasonInput ? (
        <Modal
          destroyOnClose
          visible={showReasonInput}
          title={modalTitle || "Delete Action"}
          footer={null}
          onCancel={onCancelUpdate}
        >
          <DeleteCompanyReasonFormContainer
            onCancel={onCancelUpdate}
            name={name}
            deleteFn={internalDeleteFn}
          />
        </Modal>
      ) : (
        <Tooltip title="delete">
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={internalOnDelete}
          />
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default DeleteAction;
