import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import UserAPI from "../../api/endpoints/user";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { messages } from "../../definitions/messages";
import {
  ICreateAdminUserInput,
  ISignedInUser,
  IUpdateAdminUserInput,
} from "../../definitions/user";
import cast from "../../utils/cast";
import { withWrapWithMessageError } from "../utils/utils";
import SignupUserForm from "./SignupUserForm";

export interface IUserProfileFormContainerProps {
  profile: ISignedInUser;
  onCompleteUpdateProfile: () => void | Promise<void>;
}

function getProfileFormValues(profile: ISignedInUser): ICreateAdminUserInput {
  const data: IUpdateAdminUserInput = {
    firstName: profile.ProfileRawData.FirstName,
    lastName: profile.ProfileRawData.LastName,
    emailAddress: profile.ProfileRawData.EmailAddress,
    phoneNumber: profile.ProfileRawData.PhoneNumber,
    countryCode: profile.ProfileRawData.CountryCode,
    address: {
      street: profile.ProfileRawData.Address.Street,
      city: profile.ProfileRawData.Address.City,
      state: profile.ProfileRawData.Address.State,
      zipCode: profile.ProfileRawData.Address.ZipCode,
      country: profile.ProfileRawData.Address.Country,
    },
  };

  return cast<ICreateAdminUserInput>(data);
}

const UserProfileFormContainer: React.FC<IUserProfileFormContainerProps> = (
  props
) => {
  const { profile, onCompleteUpdateProfile } = props;
  const onSubmit = withWrapWithMessageError(
    async (data: IUpdateAdminUserInput) => {
      const result = await UserAPI.updateProfile(data);
      message.success(
        getEndpointResultMessage(result) || messages.profileUpdateSuccess
      );
      await onCompleteUpdateProfile();
    }
  );

  const {
    loading: isSubmitting,
    error: submitError,
    run: onSubmitForm,
  } = useRequest(onSubmit, { manual: true });

  return (
    <SignupUserForm
      omitPassword
      btnText="Save"
      data={getProfileFormValues(profile)}
      onSubmit={onSubmitForm}
      error={submitError?.message}
      isSubmitting={isSubmitting}
    />
  );
};

export default UserProfileFormContainer;
