import { message } from "antd";
import React from "react";
import TicketAPI from "../../api/endpoints/ticket";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { messages } from "../../definitions/messages";
import { TicketStatus } from "../../definitions/utils";
import SelectTicketStatus, {
  ISelectTicketStatusProps,
} from "./SelectTicketStatus";

export interface ISelectTicketStatusContainerProps
  extends Omit<ISelectTicketStatusProps, "onChange"> {
  ticketId: string;
  onCompleteUpdate: () => void;
}

const SelectTicketStatusContainer: React.FC<
  ISelectTicketStatusContainerProps
> = (props) => {
  const { ticketId, onCompleteUpdate, status: defaultStatus } = props;
  const [status, setStatus] = React.useState(defaultStatus);
  const [savingStatus, setSavingStatus] = React.useState(false);

  const onChangeStatus = async (value?: TicketStatus) => {
    if (!value) {
      return;
    }

    const prevValue = status;

    try {
      setStatus(value);
      setSavingStatus(true);
      const result = await TicketAPI.updateTicketStatus({
        ticketId,
        status: value,
      });

      message.success(
        getEndpointResultMessage(result) || messages.statusUpdated
      );
      setSavingStatus(false);
      onCompleteUpdate();
    } catch (error: any) {
      message.error(error?.message || messages.ticketStatusUpdateError);
      setStatus(prevValue);
      setSavingStatus(false);
    }
  };

  // TODO: Change tags to round tags
  return (
    <SelectTicketStatus
      {...props}
      status={status}
      loading={savingStatus}
      onChange={onChangeStatus}
    />
  );
};

export default SelectTicketStatusContainer;
