import React from "react";
import { IAnalysisBlockItemStat } from "../../definitions/analysis";
import CountList from "../utils/CountList";
import LabeledCount from "../utils/LabeledCount";

export interface IAnalysisBlockItemCountStatsProps {
  stats: IAnalysisBlockItemStat;
}

const AnalysisBlockItemCountStats: React.FC<
  IAnalysisBlockItemCountStatsProps
> = (props) => {
  const { stats } = props;
  return (
    <CountList>
      <LabeledCount
        label="Private Block Count"
        count={stats.PrivateBlockCount}
      />
      <LabeledCount label="Public Block Count" count={stats.PublicBlockCount} />
      <LabeledCount
        label="Transfer Block Count"
        count={stats.TransferBlockCount}
      />
      <LabeledCount
        label="Dispatch Block Count"
        count={stats.DispatchBlockCount}
      />
    </CountList>
  );
};

export default AnalysisBlockItemCountStats;
