import { useRequest } from "ahooks";
import React from "react";
import DeleteReasonForm, { IDeleteReasonFormValues } from "./DeleteReasonForm";

export interface IDeleteReasonFormContainerProps {
  name: string;
  deleteFn: (props: IDeleteReasonFormValues) => void;
  onCancel: () => void;
}

const DeleteFormContainer: React.FC<IDeleteReasonFormContainerProps> = (
  props
) => {
  const { name, onCancel, deleteFn } = props;
  const { loading, error, run } = useRequest(deleteFn, { manual: true });

  return (
    <DeleteReasonForm
      isSubmitting={loading}
      error={error?.message}
      onSave={run}
      name={name}
      onCancel={onCancel}
    />
  );
};

export default React.memo(DeleteFormContainer);
