import { List, Space, Switch, Typography } from "antd";
import React from "react";
import { IRolePermission } from "../../definitions/group-policy";
import InlineState from "../utils/InlineState";
import useRolePermissions from "./useRolePermissions";

export interface IPermissionsInputProps {
  value?: Record<string, IRolePermission>;
  disabled?: boolean;
  roleType: string;
  onChange: (
    val: Record<string, IRolePermission>,
    permissions: IRolePermission[]
  ) => void;
}

const PermissionsInput: React.FC<IPermissionsInputProps> = (props) => {
  const { value, disabled, roleType, onChange } = props;
  const {
    permissions,
    loadingPermissions,
    loadPermissionsError,
    loadPermissions,
  } = useRolePermissions(roleType);

  const values = value || {};

  return (
    <InlineState
      loading={loadingPermissions}
      error={loadPermissionsError?.message}
      onReload={() => loadPermissions(roleType)}
      loadingText="Loading role permissions..."
    >
      <List
        split
        header={null}
        footer={null}
        dataSource={permissions}
        renderItem={(item) => (
          <List.Item key={item.PolicyId}>
            <Space size="middle">
              <Switch
                disabled={disabled}
                checked={!!values[item.PolicyId]}
                onChange={() => {
                  const newValues = {
                    ...values,
                  };

                  if (!!newValues[item.PolicyId]) {
                    delete newValues[item.PolicyId];
                  } else {
                    newValues[item.PolicyId] = item;
                  }

                  onChange(newValues, Object.values(newValues));
                }}
              />
              <Typography.Text strong>{item._PolicyType}</Typography.Text>
            </Space>
          </List.Item>
        )}
      />
    </InlineState>
  );
};

export default PermissionsInput;
