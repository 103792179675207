import * as yup from "yup";
import { messages } from "../../definitions/messages";
import { userConstants } from "../../definitions/user";

const passwordRegex = /[A-Za-z0-9!()?_`~#$^&*+=]/;
export const zipcodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

const str = yup.string().trim();
const forgotPasswordToken = str.max(userConstants.maxForgotPasswordTokenLength);
const email = str.email(messages.invalidEmailAddress);
const confirmEmail = str.oneOf(
  [yup.ref("email")],
  messages.emailAddressDoesNotMatch
);

const password = str
  .min(userConstants.minPasswordLength)
  .matches(passwordRegex, messages.funPassword);

const confirmPassword = str.oneOf(
  [yup.ref("password")],
  messages.passwordDoesNotMatch
);

const emailOrPhoneNumber = str.test(
  "emailOrPhoneNumber",
  messages.invalidEmailOrPhoneNumber,
  (value) => {
    if (email.isValidSync(value) || str.isValidSync(value)) {
      return true;
    }

    return false;
  }
);

const strRequired = str.required(messages.requiredField);
const emailOrPhoneNumberRequired = emailOrPhoneNumber.required(
  messages.requiredField
);

const emailRequired = email.required(messages.requiredField);
const passwordRequired = password.required(messages.requiredField);
const confirmPasswordRequired = confirmPassword.required(
  messages.requiredField
);

const confirmEmailRequired = confirmEmail.required(messages.requiredField);
const requiredField = yup.mixed().required(messages.requiredField);

const forgotPasswordTokenRequired = forgotPasswordToken.required(
  messages.requiredField
);

const zipcode = str.matches(zipcodeRegex, {
  message: messages.zipcodeInvalid,
});

const address = yup.object().shape({
  zipcode,
  street: str.required(messages.requiredField),
  city: str.required(messages.requiredField),
  state: str.required(messages.requiredField),
  country: str.required(messages.requiredField),
});

const addressRequired = address.required(messages.requiredField);

export const formValidationSchemas = {
  email,
  emailOrPhoneNumber,
  confirmEmail,
  password,
  forgotPasswordToken,
  confirmPassword,
  emailRequired,
  passwordRequired,
  confirmEmailRequired,
  confirmPasswordRequired,
  requiredField,
  forgotPasswordTokenRequired,
  emailOrPhoneNumberRequired,
  address,
  zipcode,
  URL,
  addressRequired,
  strRequired,
  str,
};
