import { Upload, message, Typography, Button } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import React from "react";
import { messages } from "../../definitions/messages";
import { useSelector } from "react-redux";
import SessionSelectors from "../../redux/session/selectors";
import { getUploadImagePath } from "../../api/endpoints/image";
import { defaultTo } from "lodash";
import { appColors, appDimensions } from "./theme";
import { css } from "@emotion/css";

export type IImageUploadMessages = Partial<{
  uploading: string;
  successful: string;
  failed: string;
}>;

export interface IUploadAvatarProps {
  messages?: IImageUploadMessages;
  id: string;
  onCompleteUpload: () => void;
}

const IMAGE_JPEG = "image/jpeg";
const IMAGE_PNG = "image/png";
const DEFAULT_MESSAGES: IImageUploadMessages = {
  uploading: messages.uploadingImage,
  successful: messages.imageUploadSuccessful,
  failed: messages.imageUploadFailed,
};

function beforeUpload(file: RcFile) {
  const isJpgOrPng = file.type === IMAGE_JPEG || file.type === IMAGE_PNG;

  if (!isJpgOrPng) {
    message.error(messages.invalidImageType);
  }

  return isJpgOrPng;
}

const UploadAvatar: React.FC<IUploadAvatarProps> = (props) => {
  const { id, onCompleteUpload } = props;
  const customMessages = {
    ...DEFAULT_MESSAGES,
    ...defaultTo(props.messages, {}),
  };

  const [loading, setLoading] = React.useState(false);
  const [messageKey] = React.useState(() => Math.random().toString());
  const token = useSelector(SessionSelectors.assertGetToken);

  const onChange = (info: UploadChangeParam) => {
    if (info.file.status === "uploading") {
      message.loading({ content: customMessages.uploading, key: messageKey });
      setLoading(true);
    } else if (info.file.status === "done") {
      message.success({
        content: (
          <Typography.Text>
            {customMessages.successful}.{" "}
            <Typography.Text strong>
              Please reload the page if your change doesn't take effect
              immediately.
            </Typography.Text>
          </Typography.Text>
        ),
        key: messageKey,
        // duration: SUCCESS_MESSAGE_DURATION,
      });

      setLoading(false);
      setTimeout(() => {
        onCompleteUpload();
      }, 3000);
    } else if (info.file.status === "error") {
      message.error({
        content: customMessages.failed,
        key: messageKey,
      });

      setLoading(false);
    }
  };

  const uploadButton = (
    <Button
      icon={<CloudUploadOutlined />}
      style={{
        color: appColors.primaryColor,
      }}
      loading={loading}
    >
      Upload image
    </Button>
  );

  return (
    // <ImgCrop rotate>
    <Upload
      showUploadList={false}
      name="image"
      action={getUploadImagePath(id)}
      headers={{ authorization: `Bearer ${token}` }}
      beforeUpload={beforeUpload}
      onChange={onChange}
      disabled={loading}
      className={css({
        width: appDimensions.imageWidth,
        height: appDimensions.imageWidth,
      })}
    >
      {uploadButton}
    </Upload>
    // </ImgCrop>
  );
};

export default UploadAvatar;
