import { Tag } from "antd";
import React from "react";
import { ITicket } from "../../definitions/ticket";
import LabeledNodeList, {
  ILabeledNodeListProps,
} from "../utils/LabeledNodeList";
import SelectTicketStatusContainer from "./SelectTicketStatusContainer";

export interface ITicketSecondaryDetailsProps {
  ticket: ITicket;
  onCompleteUpdateStatus: () => void;
}

const TicketSecondaryDetails: React.FC<ITicketSecondaryDetailsProps> = (
  props
) => {
  const { ticket, onCompleteUpdateStatus } = props;

  const nodes: ILabeledNodeListProps["list"] = [
    { nodeIsText: true, label: "Ticket Number", node: ticket.TicketNumber },
    {
      nodeIsText: true,
      label: "Organization Name",
      node: ticket.OrganizationName,
    },
    {
      nodeIsText: true,
      label: "Status",
      node: (
        <SelectTicketStatusContainer
          useTags
          bordered={false}
          onCompleteUpdate={onCompleteUpdateStatus}
          status={ticket.Status.Name}
          statusColor={ticket.Status.Color}
          ticketId={ticket.TicketId}
        />
      ),
    },
    {
      nodeIsText: true,
      label: "Priority",
      node: (
        <Tag style={{ color: ticket.Priority.Color }}>
          {ticket.Priority.Name}
        </Tag>
      ),
    },
    { nodeIsText: true, label: "Date Created", node: ticket.DateCreated_ },
  ];

  return <LabeledNodeList direction="vertical" list={nodes} />;
};

export default TicketSecondaryDetails;
