import React from "react";
import { ITicket, TicketPriority } from "../../definitions/ticket";
import { TicketStatus } from "../../definitions/utils";
import { IUsePageDataResult, PageDataMode } from "../hooks/usePageData";
import PageError from "../utils/PageError";
import PagePagination from "../utils/PagePaination";
import RenderPageViewModes, {
  PageViewMode,
  usePageView,
} from "../utils/PageViewMode";
import { appClasses } from "../utils/theme";
import TicketList from "./TicketList";
import TicketsPageControls from "./TicketsPageControls";
import TicketTable from "./TicketTable";

export interface ITicketsPageFetchProps {
  status?: TicketStatus;
  priority?: TicketPriority;
  orgId?: string;
}

export interface ITicketsPageSearchProps {
  query: string;
  orgId?: string;
}

export interface ITicketsPageProps
  extends IUsePageDataResult<
    ITicket,
    ITicketsPageFetchProps,
    ITicketsPageSearchProps
  > {
  onCreateTicket: () => void;
}

const TicketsPage: React.FC<ITicketsPageProps> = (props) => {
  const {
    pageFetchExtra,
    searchFetchExtra,
    mode,
    page,
    isInitialized,
    getPageError,
    getPageLoading,
    setMode,
    onCreateTicket,
    setPageFetchExtra,
    setSearchFetchExtra,
    reloadSearchData,
    getPageItems,
    reloadEverything,
    reloadPageItems,
  } = props;

  const { viewMode, setPageViewMode } = usePageView(PageViewMode.Table);
  const pageLoading = getPageLoading(page);
  const renderTicketTable = () => (
    <TicketTable
      loading={pageLoading}
      data={getPageItems(page)}
      onCompleteUpdateStatus={reloadEverything}
    />
  );

  const renderTicketList = () => (
    <div>
      <TicketList
        loading={pageLoading}
        tickets={getPageItems(page)}
        onCompleteUpdateStatus={reloadPageItems}
      />
    </div>
  );

  const pageError = getPageError(page);
  let centerNode: React.ReactNode = null;

  if (pageError) {
    centerNode = (
      <PageError
        messageText={pageError}
        actions={[{ text: "Reload Tickets", onClick: reloadPageItems }]}
      />
    );
  } else {
    centerNode = (
      <RenderPageViewModes
        mode={viewMode}
        modeList={[
          { mode: PageViewMode.Table, render: renderTicketTable },
          { mode: PageViewMode.List, render: renderTicketList },
        ]}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <TicketsPageControls
          disabled={!isInitialized}
          orgId={pageFetchExtra.orgId}
          status={pageFetchExtra.status}
          priority={pageFetchExtra.priority}
          onCreateTicket={onCreateTicket}
          onSelectStatus={(status) =>
            setPageFetchExtra({ ...pageFetchExtra, status }, true)
          }
          onSelectPriority={(priority) =>
            setPageFetchExtra({ ...pageFetchExtra, priority }, true)
          }
          onSelectOrganization={(orgId) => {
            setPageFetchExtra(
              { ...pageFetchExtra, orgId },
              mode === PageDataMode.Page
            );
            setSearchFetchExtra(
              { ...searchFetchExtra, orgId },
              mode === PageDataMode.Search
            );
          }}
          onEnterSearchQuery={(query) => {
            setSearchFetchExtra({ ...searchFetchExtra, query });
            if (query) {
              reloadSearchData();
              if (mode !== PageDataMode.Search) {
                setMode(PageDataMode.Search);
              }
            } else {
              if (mode === PageDataMode.Search) {
                setMode(PageDataMode.Page);
              }
            }
          }}
          mode={viewMode}
          setMode={setPageViewMode}
          isSearchMode={mode === PageDataMode.Search}
        />
      </div>
      <div className={appClasses.pageContent}>{centerNode}</div>
      <div className={appClasses.pagePagination}>
        <PagePagination {...props} />
      </div>
    </div>
  );
};

export default TicketsPage;
