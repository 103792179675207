import { useRequest } from "ahooks";
import React from "react";
import { useDispatch } from "react-redux";
import UserAPI from "../../api/endpoints/user";
import SessionStorageFuncs from "../../browser-storage/session";
import SessionActions from "../../redux/session/actions";
import Login, { ILoginFormValues } from "./Login";

// const k2FARequiredStatusCode = 451;

const LoginContainer: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    async (data: ILoginFormValues) => {
      try {
        const result = await UserAPI.login({
          emailOrPhoneNumber: data.emailOrPhoneNumber,
          password: data.password,
        });

        dispatch(
          SessionActions.update({
            token: result.Token,
            refreshToken: result.RefreshToken,
          })
        );

        if (data.remember) {
          SessionStorageFuncs.saveUserToken(result.Token);
          SessionStorageFuncs.saveRefreshToken(result.RefreshToken);
        }

        const userProfile = await UserAPI.getUserProfile();
        const companyProfile = await UserAPI.getCompanyProfile();
        dispatch(
          SessionActions.loginUser({
            userProfile,
            companyProfile,
            token: result.Token,
            refreshToken: result.RefreshToken,
          })
        );
      } catch (error) {
        // TODO: what API are we using for 2FA
        // TODO: get APIs for resend code for login and forgot password

        // if (
        //   error instanceof EndpointError &&
        //   error.statusCode === k2FARequiredStatusCode
        // ) {
        //   notification.info({
        //     message: "Two factor authentication required",
        //     description: (
        //       <Typography.Paragraph>
        //         We sent a verification code to your phone number. Click the link
        //         below to route to the page where you enter the code.
        //         <br />
        //         <Link to={appBasePaths.changePassword}>
        //           <Typography.Text
        //             className={utilClassNames.typographyWithPrimaryColor}
        //           >
        //             <Space>
        //               Change password
        //               <RightOutlined />
        //             </Space>
        //           </Typography.Text>
        //         </Link>
        //       </Typography.Paragraph>
        //     ),
        //     icon: <SmileOutlined style={{ color: appColors.primaryColor }} />,
        //     duration: 0,
        //   });
        // }

        throw error;
      }
    },
    [dispatch]
  );

  const { loading, error, run } = useRequest(onSubmit, { manual: true });

  return <Login isSubmitting={loading} error={error?.message} onSubmit={run} />;
};

export default React.memo(LoginContainer);
