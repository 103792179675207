import { useRequest } from "ahooks";
import { message } from "antd";
import uuid from "uuid";
import React from "react";
import TicketAPI from "../../api/endpoints/ticket";
import { messages } from "../../definitions/messages";
import TicketActivityForm, {
  ITicketActivityFormValues,
} from "./TicketActivityForm";
import { removeUnusedImages } from "../utils/images";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { wrapWithMessageError } from "../utils/utils";

export interface ITicketActivityFormContainerProps {
  orgId: string;
  ticketId: string;
  onCompleteSubmitActivity: () => void;
}

const TicketActivityFormContainer: React.FC<
  ITicketActivityFormContainerProps
> = (props) => {
  const { ticketId, orgId, onCompleteSubmitActivity } = props;
  const [attachmentId, setAttachmentId] = React.useState(uuid.v4);
  const attachmentIdSubmittedImageCountRef = React.useRef(0);

  // TODO: Run delete only when there are images uploaded and we leave the form without save
  React.useEffect(() => {
    return () => {
      if (attachmentIdSubmittedImageCountRef.current === 0) {
        removeUnusedImages({ id: attachmentId, isGroupImage: true });
      }

      attachmentIdSubmittedImageCountRef.current = 0;
    };
  }, [attachmentId]);

  const onSubmit = React.useCallback(
    async (data: ITicketActivityFormValues) => {
      wrapWithMessageError(async () => {
        const result = await TicketAPI.submitTicketActivity({
          orgId,
          data: {
            ticketId,
            attachmentId: data.imageCount ? attachmentId : undefined,
            message: data.message,
          },
        });

        message.success(
          getEndpointResultMessage(result) || messages.ticketActivitySubmitted
        );
        attachmentIdSubmittedImageCountRef.current = data.imageCount || 0;
        setAttachmentId(uuid.v4());
        onCompleteSubmitActivity();
      });
    },
    [ticketId, attachmentId, orgId, onCompleteSubmitActivity]
  );

  const {
    loading: isSubmitting,
    error: submitError,
    run: onSubmitForm,
  } = useRequest(onSubmit, { manual: true });

  return (
    <TicketActivityForm
      key={attachmentId}
      attachmentId={attachmentId}
      onSubmit={onSubmitForm}
      error={submitError?.message}
      isSubmitting={isSubmitting}
    />
  );
};

export default TicketActivityFormContainer;
