import { cx } from "@emotion/css";
import { Alert, Button, Form, Input, Select, Typography } from "antd";
import React from "react";
import * as yup from "yup";
import { messages } from "../../definitions/messages";
import {
  ITicketInput,
  TicketPriority,
  TICKET_RELATED_SERVICES_OTHERS_OPTION,
} from "../../definitions/ticket";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import useFormHelpers from "../hooks/useFormHelpers";
import ImageProviderAndUpload from "../utils/ImageProviderAndUpload";
import SelectTicketRelatedService from "./SelectTicketRelatedService";
import TicketsSelectOrganization from "./TicketsSelectOrganization";

const validationSchema = yup.object().shape({
  orgId: yup.string().required(messages.requiredField),
  subject: yup.string().required(messages.requiredField),
  message: yup.string().required(messages.requiredField),
  priority: yup.string().required(messages.requiredField),
  relatedService: yup.string().required(messages.requiredField),
  customService: yup.string().when("relatedService", (service, schema) => {
    return service?.toLowerCase() === TICKET_RELATED_SERVICES_OTHERS_OPTION
      ? yup.string().required(messages.requiredField)
      : schema;
  }),
  attachmentId: yup.string(),
});

export type ITicketFormValues = Omit<ITicketInput, "attachmentId"> & {
  orgId: string;
  imageCount: number;
};

export interface ITicketFormProps {
  error?: string;
  isSubmitting?: boolean;
  attachmentId: string;
  onSubmit: (values: ITicketFormValues) => void | Promise<void>;
}

const initialValues: ITicketFormValues = {
  orgId: "",
  subject: "",
  priority: TicketPriority.Medium,
  customService: "",
  relatedService: "",
  message: "",
  imageCount: 0,
};

const ATTACHMENT_WIDTH = 80;

const TicketForm: React.FC<ITicketFormProps> = (props) => {
  const { attachmentId, isSubmitting, error, onSubmit } = props;

  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      onSubmit,
      initialValues: { ...initialValues },
    },
  });

  const organizationNode = (
    <Form.Item
      required
      label="Organization"
      help={
        formik.touched?.orgId &&
        formik.errors?.orgId && (
          <FunErrorMessages message={formik.errors?.orgId}>
            <FormError error={formik.errors?.orgId} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <TicketsSelectOrganization
        orgId={formik.values.orgId}
        onChange={(value) => formik.setFieldValue("orgId", value)}
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const subjectNode = (
    <Form.Item
      required
      label="Subject"
      help={
        formik.touched?.subject &&
        formik.errors?.subject && (
          <FunErrorMessages message={formik.errors?.subject}>
            <FormError error={formik.errors?.subject} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        name="subject"
        value={formik.values.subject}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter ticket subject"
        disabled={isSubmitting}
        autoSize={{ minRows: 2 }}
      />
    </Form.Item>
  );

  const messageNode = (
    <Form.Item
      required
      label="Message"
      help={
        formik.touched?.message &&
        formik.errors?.message && (
          <FunErrorMessages message={formik.errors?.message}>
            <FormError error={formik.errors?.message} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        name="message"
        value={formik.values.message}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter ticket message"
        disabled={isSubmitting}
        autoSize={{ minRows: 5 }}
      />
    </Form.Item>
  );

  const priorityNode = (
    <Form.Item
      required
      label="Priority"
      help={
        formik.touched?.priority &&
        formik.errors?.priority && (
          <FunErrorMessages message={formik.errors.priority}>
            <FormError error={formik.errors.priority} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Select
        value={formik.values.priority}
        onBlur={formik.handleBlur}
        onChange={(value) => formik.setFieldValue("priority", value)}
        placeholder="Select ticket priority"
        disabled={isSubmitting}
      >
        <Select.Option value={TicketPriority.Urgent}>Urgent</Select.Option>
        <Select.Option value={TicketPriority.High}>High</Select.Option>
        <Select.Option value={TicketPriority.Medium}>Medium</Select.Option>
        <Select.Option value={TicketPriority.Low}>Low</Select.Option>
      </Select>
    </Form.Item>
  );

  const relatedServiceNode = (
    <Form.Item
      required
      label="Related Service"
      help={
        formik.touched?.relatedService &&
        formik.errors?.relatedService && (
          <FunErrorMessages message={formik.errors?.relatedService}>
            <FormError error={formik.errors?.relatedService} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <SelectTicketRelatedService
        value={formik.values.relatedService}
        onChange={(value) => formik.setFieldValue("relatedService", value)}
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const showCustomService =
    formik.values.relatedService?.toLowerCase() ===
    TICKET_RELATED_SERVICES_OTHERS_OPTION;

  const customServiceNode = showCustomService && (
    <Form.Item
      required={showCustomService}
      label="Custom Service"
      help={
        formik.touched?.customService &&
        formik.errors?.customService && (
          <FunErrorMessages message={formik.errors?.customService}>
            <FormError error={formik.errors?.customService} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="customService"
        value={formik.values?.customService}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter custom service"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const setFieldValue = formik.setFieldValue;
  const setImageCount = React.useCallback(
    (count) => setFieldValue("imageCount", count),
    [setFieldValue]
  );

  const attachmentNode = (
    <Form.Item
      label="Attachments"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <ImageProviderAndUpload
        allowDelete
        id={attachmentId}
        imageAltBase="Ticket attachment"
        count={0}
        width={ATTACHMENT_WIDTH}
        height={ATTACHMENT_WIDTH}
        onCountChange={setImageCount}
      />
    </Form.Item>
  );

  return (
    <div className={cx(formClasses.body)}>
      <div className={formClasses.bodyInner}>
        <Form.Item>
          <Typography.Title level={4}>Ticket Form</Typography.Title>
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        <form onSubmit={formik.handleSubmit}>
          {subjectNode}
          {messageNode}
          {organizationNode}
          {priorityNode}
          {relatedServiceNode}
          {customServiceNode}
          {attachmentNode}
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting ? "Submitting Ticket" : "Submit Ticket"}
            </Button>
          </Form.Item>
        </form>
      </div>
    </div>
  );
};

export default React.memo(TicketForm);
