import { Tabs } from "antd";
import { Redirect, useHistory, useRouteMatch } from "react-router";
import { appBasePaths, appLoggedInPaths } from "../../definitions/paths";
import { appClasses } from "../utils/theme";
import PartnerWithUsRequestsRoot from "./PartnerWithUsRequestsRoot";
import TalkToUsRequestsRoot from "./TalkToUsRequestsRoot";

const RequestsRoot: React.FC<{}> = (props) => {
  const history = useHistory();
  const routeMatch = useRouteMatch<{
    currentRoute?: string;
  }>(`${appBasePaths.app}/requests/:currentRoute`);

  const currentRoute = routeMatch?.params.currentRoute;
  const selectedKey = `${appLoggedInPaths.requests}/${currentRoute}`;

  if (!currentRoute) {
    return <Redirect to={appLoggedInPaths.partnerWithUs} />;
  }

  return (
    <Tabs
      defaultActiveKey={selectedKey}
      onChange={(key) => {
        history.push(key);
      }}
      className={appClasses.tabs}
      tabBarExtraContent={{
        left: <div style={{ marginLeft: "16px" }} />,
        right: <div style={{ marginRight: "16px" }} />,
      }}
    >
      <Tabs.TabPane
        tab={<span>Partner With Us Requests</span>}
        key={appLoggedInPaths.partnerWithUs}
      >
        <PartnerWithUsRequestsRoot />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={<span>Talk To Us Requests</span>}
        key={appLoggedInPaths.talkToUs}
      >
        <TalkToUsRequestsRoot />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default RequestsRoot;
