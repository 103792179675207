import {
  CaretDownOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/css";
import { Avatar, Dropdown, Menu, Space, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import { appLoggedInPaths } from "../../definitions/paths";
import SessionActions from "../../redux/session/actions";
import SessionSelectors from "../../redux/session/selectors";
import { ISessionState } from "../../redux/session/types";
import { IAppState } from "../../redux/types";
import AppNotifications from "./AppNotifications";

export interface IAppBarProps {}

const kLogoutKey = "logout";
const classes = {
  root: css({
    display: "flex",
    justifyContent: "flex-end",
    padding: "16px",
  }),
  avatarRoot: css({
    cursor: "pointer",
  }),
  menu: css({
    minWidth: "140px",
  }),
};

const AppBar: React.FC<IAppBarProps> = (props) => {
  const user = useSelector(SessionSelectors.assertGetUserProfile);
  const dispatch = useDispatch();
  const sessionData = useSelector<IAppState, ISessionState>(
    (state) => state.session
  );

  const handleClickMenu = React.useCallback(
    (item: { key: string }) => {
      if (item.key === kLogoutKey) {
        dispatch(SessionActions.logoutUser());
        return;
      }
    },
    [dispatch]
  );

  const dropdownMenuNode = (
    <Menu onClick={handleClickMenu} className={classes.menu}>
      <Menu.Item key={appLoggedInPaths.profile} icon={<UserOutlined />}>
        <Link to={appLoggedInPaths.profile}>Profile</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={kLogoutKey} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const avatarSrc = getLoadSingleImagePath(
    user.EmployeeId,
    null,
    null,
    sessionData.userAvatarLastUpdatedAt
  );

  return (
    <div className={classes.root}>
      <Space size="large">
        <AppNotifications />
        <span>
          <Typography.Text type="secondary">Hello, </Typography.Text>
          <Typography.Text>{user.FullName}</Typography.Text>
        </span>
        <Dropdown trigger={["click"]} overlay={dropdownMenuNode}>
          <Space className={classes.avatarRoot}>
            <Avatar
              key={avatarSrc}
              icon={<UserOutlined />}
              src={avatarSrc}
              alt={`${user.FullName}'s profile picture`}
              shape="square"
            />
            <CaretDownOutlined />
          </Space>
        </Dropdown>
      </Space>
    </div>
  );
};

export default AppBar;
