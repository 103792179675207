import React from "react";
import { indexArray } from "../../utils/utils";
import useFetchData, { IUseFetchDataProps } from "./useFetchData";
import usePaginatedData, {
  IAppDataPaginationProps,
  IUsePaginatedDataProps,
} from "./usePaginatedData";
import usePagination from "./usePagination";

export enum PageDataMode {
  Page = "page",
  Search = "search",
}

export interface IUsePageDataProps<
  T,
  PageFetchExtra extends Record<string, any>,
  SearchFetchExtra extends Record<string, any>
> {
  defaultMode?: PageDataMode;
  pageDataProps: IUsePaginatedDataProps<T, PageFetchExtra>;
  searchDataProps: IUseFetchDataProps<T, SearchFetchExtra>;
}

export interface IUsePageDataResult<
  T,
  PageFetchExtra extends Record<string, any>,
  SearchFetchExtra extends Record<string, any>
> extends IAppDataPaginationProps {
  isInitialized: boolean;
  pageFetchExtra: PageFetchExtra;
  searchFetchExtra: SearchFetchExtra;
  mode: PageDataMode;
  getPageLoading: (page: number) => boolean;
  getPageError: (page: number) => string | undefined;
  setMode: (mode: PageDataMode) => void;
  getPageItems: (page: number) => T[];
  getItemById: (itemId: string) => T | null;
  getItemPageById: (itemId: string) => number | null;
  setPageFetchExtra: (fetchExtra: PageFetchExtra, reload?: boolean) => void;
  setSearchFetchExtra: (fetchExtra: SearchFetchExtra, reload?: boolean) => void;

  // Reloads the page side of the data, not the search side of the data
  reloadSearchData: () => void;
  reloadEverything: () => void;

  // Reloads the data for a single page
  reloadPageItems: (page?: number) => void;
}

/**
 * Combines usePaginatedData and useFetchData
 */
export default function usePageData<
  T,
  PageFetchExtra extends Record<string, any>,
  SearchFetchExtra extends Record<string, any>
>(
  props: IUsePageDataProps<T, PageFetchExtra, SearchFetchExtra>
): IUsePageDataResult<T, PageFetchExtra, SearchFetchExtra> {
  const pageData = usePaginatedData(props.pageDataProps);
  const searchData = useFetchData(props.searchDataProps);
  const paginatedSearchData = usePagination({ data: searchData.data || [] });
  const searchDataMap = React.useMemo(
    () =>
      indexArray(searchData.data || [], {
        indexer: props.pageDataProps.getItemId,
      }),
    [props.pageDataProps.getItemId, searchData.data]
  );

  const [mode, setMode] = React.useState(
    props.defaultMode || PageDataMode.Page
  );

  const getSearchItemById = React.useCallback(
    (itemId: string) => {
      return searchDataMap[itemId] || null;
    },
    [searchDataMap]
  );

  const getSearchItemPageById = React.useCallback((itemId: string) => {
    // TODO: This is currently 0 cause search data is not server-paginated
    return 0;
  }, []);

  const reloadEverything = React.useCallback(() => {
    if (mode === PageDataMode.Page) {
      pageData.reloadEverything();
    } else {
      searchData.reloadEverything();
    }
  }, [pageData, searchData, mode]);

  const getSearchLoading = React.useCallback(() => {
    return !!searchData.loading;
  }, [searchData.loading]);

  const getSearchError = React.useCallback(() => {
    return searchData.error?.message;
  }, [searchData.error]);

  if (mode === PageDataMode.Page) {
    const result: IUsePageDataResult<T, PageFetchExtra, SearchFetchExtra> = {
      mode,
      setMode,
      reloadEverything,
      page: pageData.page,
      pageSize: pageData.pageSize,
      total: pageData.total,
      isInitialized: pageData.isInitialized,
      pageFetchExtra: pageData.fetchExtra,
      searchFetchExtra: searchData.fetchExtra,
      getPageItems: pageData.getPageItems,
      getItemById: pageData.getItemById,
      getItemPageById: pageData.getItemPageById,
      setPageFetchExtra: pageData.setFetchExtra,
      setSearchFetchExtra: searchData.setFetchExtra,
      reloadSearchData: searchData.reloadEverything,
      setPageSize: pageData.setPageSize,
      onNavigate: pageData.onNavigate,
      getPageError: pageData.getPageError,
      getPageLoading: pageData.getPageLoading,
      reloadPageItems: pageData.reloadPageItems,
    };

    return result;
  } else {
    const result: IUsePageDataResult<T, PageFetchExtra, SearchFetchExtra> = {
      mode,
      setMode,
      reloadEverything,
      getItemPageById: getSearchItemPageById,
      page: paginatedSearchData.page,
      pageSize: paginatedSearchData.pageSize,
      total: paginatedSearchData.total,
      isInitialized: pageData.isInitialized,
      pageFetchExtra: pageData.fetchExtra,
      searchFetchExtra: searchData.fetchExtra,
      getPageItems: paginatedSearchData.getPageItems,
      getItemById: getSearchItemById,
      setPageFetchExtra: pageData.setFetchExtra,
      setSearchFetchExtra: searchData.setFetchExtra,
      reloadSearchData: searchData.reloadEverything,
      setPageSize: paginatedSearchData.setPageSize,
      onNavigate: paginatedSearchData.onNavigate,
      getPageError: getSearchError,
      getPageLoading: getSearchLoading,
      reloadPageItems: searchData.reloadEverything,
    };

    return result;
  }
}
