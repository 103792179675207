import { useRequest } from "ahooks";
import React from "react";
import AnalysisAPI, {
  IAnalysisGetBlockItemStatsEndpointParams,
  IAnalysisGetDeliveryCostStatsEndpointParams,
  IAnalysisGetDriverFeedbackEndpointParams,
  IAnalysisGetDriverListEndpointParams,
  IAnalysisGetDriverStatsEndpointParams,
  IAnalysisGetOrderItemCategoryStatsEndpointParams,
  IAnalysisGetOrderItemStatsEndpointParams,
  IAnalysisGetPaymentMethodStatsEndpointParams,
} from "../../api/endpoints/analysis";
import { AnalysisFrequencyTypes } from "../../definitions/analysis";
import { IFetchHooksOptions } from "../hooks/types";
import { useReturnNode } from "../hooks/useReturnNode";

export function useFetchAnalysisOrganizationList() {
  const getData = async () => {
    return await AnalysisAPI.getOrganizationList();
  };
  const data = useRequest(getData);
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useFetchAnalysisDriverList(
  props?: IAnalysisGetDriverListEndpointParams & IFetchHooksOptions
) {
  const getData = async () => {
    return await AnalysisAPI.getDriverList({
      orgId: props?.orgId,
      freqTypes: props?.freqTypes,
    });
  };
  const data = useRequest(getData, {
    refreshDeps: [props?.orgId, props?.freqTypes],
    ready: props?.ready,
  });
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useFetchAnalysisDriverStats(
  props?: IAnalysisGetDriverStatsEndpointParams
) {
  const getData = async () => {
    return await AnalysisAPI.getDriverStats({
      orgId: props?.orgId,
      freqTypes: props?.freqTypes,
    });
  };
  const data = useRequest(getData, {
    refreshDeps: [props?.orgId, props?.freqTypes],
  });
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useFetchAnalysisOrderItemStats(
  props?: IAnalysisGetOrderItemStatsEndpointParams
) {
  const getData = async () => {
    return await AnalysisAPI.getOrderItemStats({
      orgId: props?.orgId,
      freqTypes: props?.freqTypes,
    });
  };
  const data = useRequest(getData, {
    refreshDeps: [props?.orgId, props?.freqTypes],
  });
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useFetchAnalysisBlockItemStats(
  props?: IAnalysisGetBlockItemStatsEndpointParams
) {
  const getData = async () => {
    return await AnalysisAPI.getBlockItemStats({
      orgId: props?.orgId,
    });
  };
  const data = useRequest(getData, {
    refreshDeps: [props?.orgId],
  });
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useFetchAnalysisPaymentMethodStats(
  props?: IAnalysisGetPaymentMethodStatsEndpointParams
) {
  const getData = async () => {
    return await AnalysisAPI.getPaymentMethodStats({
      orgId: props?.orgId,
    });
  };
  const data = useRequest(getData, {
    refreshDeps: [props?.orgId],
  });
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useFetchAnalysisOrderItemCategoryStats(
  props?: IAnalysisGetOrderItemCategoryStatsEndpointParams
) {
  const getData = async () => {
    return await AnalysisAPI.getOrderItemCategoryStats({
      orgId: props?.orgId,
      freqTypes: props?.freqTypes,
    });
  };
  const data = useRequest(getData, {
    refreshDeps: [props?.orgId, props?.freqTypes],
  });
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useFetchAnalysisDeliveryCostStats(
  props?: IAnalysisGetDeliveryCostStatsEndpointParams
) {
  const getData = async () => {
    return await AnalysisAPI.getDeliveryCostStats({
      orgId: props?.orgId,
      freqTypes: props?.freqTypes,
    });
  };
  const data = useRequest(getData, {
    refreshDeps: [props?.orgId, props?.freqTypes],
  });
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useFetchFeedback(
  props?: IAnalysisGetDriverFeedbackEndpointParams
) {
  const getData = async () => {
    return await AnalysisAPI.getDriverFeedback({
      orgId: props?.orgId,
      driverId: props?.driverId,
    });
  };
  const data = useRequest(getData, {
    refreshDeps: [props?.orgId, props?.driverId],
  });
  const { returnNode, assertData } = useReturnNode(data);
  return { ...data, returnNode, assertData };
}

export function useAnalysisControls(props: { enableDrivers?: boolean } = {}) {
  type State = {
    orgId?: string;
    freqTypes?: AnalysisFrequencyTypes;
    driverId?: string;
  };

  const [state, setState] = React.useState<State>({
    freqTypes: AnalysisFrequencyTypes.All,
  });
  const orgs = useFetchAnalysisOrganizationList();
  const drivers = useFetchAnalysisDriverList({
    orgId: state.orgId,
    freqTypes: state.freqTypes,
    ready: !!(state.orgId && state.freqTypes && props.enableDrivers),
  });

  return {
    orgs,
    drivers,
    state,
    setState: (partialState: Partial<State>) =>
      setState({ ...state, ...partialState }),
  };
}
