import React from "react";
import usePaginatedData from "../hooks/usePaginatedData";
import AuditLogTable from "./AuditLogTable";
import AuditLogAPI from "../../api/endpoints/audit";
import cast from "../../utils/cast";
import AuditLogsControls from "./AuditLogsControls";
import PageError from "../utils/PageError";
import PagePagination from "../utils/PagePaination";
import { appClasses } from "../utils/theme";

const AuditLogs: React.FC<{}> = (props) => {
  const pageData = usePaginatedData({
    fetch: AuditLogAPI.getAuditLogs,
    defaultFetchExtra: cast<{
      type?: string;
      date?: string;
      logisticCompanyId?: string;
    }>({}),
    getItemId: (item) => item.itemId,
  });

  const {
    page,
    isInitialized,
    fetchExtra,
    getPageItems,
    getPageLoading,
    reloadEverything,
    setFetchExtra,
    getPageError,
  } = pageData;

  let contentNode: React.ReactNode = null;

  if (getPageError(page)) {
    contentNode = (
      <PageError
        messageText={getPageError(page)}
        actions={[{ text: "Reload Audit Logs", onClick: reloadEverything }]}
      />
    );
  } else {
    const items = getPageItems(page);
    contentNode = <AuditLogTable data={items} loading={getPageLoading(page)} />;
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <AuditLogsControls
          options={fetchExtra}
          onChange={(value) => setFetchExtra(value, true)}
          disabled={!isInitialized}
        />
      </div>
      <div className={appClasses.pageContent}>{contentNode}</div>
      <div className={appClasses.pagePagination}>
        <PagePagination {...pageData} />
      </div>
    </div>
  );
};

export default AuditLogs;
