import useRequest from "@ahooksjs/use-request";
import { Select, Form } from "antd";
import { defaultTo } from "lodash";
import React from "react";
import ResourcesAPI from "../../api/endpoints/resources";
import { CACHE_STALE_TIME, DataCacheKeys } from "../utils/cache-keys";

export interface ISelectTicketRelatedServiceProps {
  disabled?: boolean;
  value?: string;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
}

const SelectTicketRelatedService: React.FC<ISelectTicketRelatedServiceProps> = (
  props
) => {
  const { disabled, value, style, onChange } = props;
  const { data, error, loading } = useRequest(
    ResourcesAPI.getTicketRelatedServices,
    {
      cacheKey: DataCacheKeys.Ticket_SelectRelatedServices,
      staleTime: CACHE_STALE_TIME,
    }
  );

  const services = data || [];

  return (
    <Form.Item
      validateStatus={error && "error"}
      help={error}
      style={{ ...defaultTo(style, {}), margin: 0 }}
    >
      <Select
        allowClear
        showSearch
        placeholder="Select service"
        optionFilterProp="title"
        loading={loading}
        disabled={disabled || !!error}
        value={value}
        onChange={onChange}
        filterOption={(input, option) =>
          option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {services.map((service) => (
          <Select.Option key={service} value={service} title={service}>
            {service}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectTicketRelatedService;
