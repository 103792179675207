import { ITrackingInformation } from "../../definitions/tracker";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { selectEndpointParam, setEndpointParam } from "../utils";

const requestsURL = `${netAddrs.serverAddr}/LandingPage`;
const trackerURL = `${requestsURL}/Tracker`;

export interface IGetTrackingInformationEndpointParams {
  trackingId: string;
}

async function getTrackingInformation(
  props: IGetTrackingInformationEndpointParams
): Promise<ITrackingInformation[]> {
  const params = new URLSearchParams();
  setEndpointParam(params, "trackingId", props.trackingId);
  const endpointURL = selectEndpointParam(trackerURL, params, props);
  const result = await invokeEndpointWithAuth<ITrackingInformation[]>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export default abstract class TrackerAPI {
  static getTrackingInformation = getTrackingInformation;
}
