export enum TicketStatus {
  New = "New",
  InProgress = "InProgress",
  Resolved = "Resolved",
}

export interface IStatus {
  Color: string;
  Description: string;
  Id: number;
  Index: number;
  Name: TicketStatus;
}

export interface IColor {
  PrimaryColor: string;
  SecondaryColor: string;
  TertiaryColor: string;
}

export interface ILowercasedColor {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

export const BYTES = 1024;
export const appConstants = {
  appShortName: "movebot-admin",
  systemName: "System",
  maxImageSize: 10 * BYTES ** 2, // 10MB,
  messageDuration: 7,
};

export interface IPaginatedDataResult<T> {
  CurrentPageSize: number;
  TotalPages: number;
  CurrentPage: number;
  TotalSize: number;
  Data: T[];
}

export interface IPaginatedDataInput {
  pageNumber?: number;
  pageSize?: number;
}

export interface ILowercasedEnum {
  id: string;
  name: string;
  description: string;
  index: number;
  color: string;
  color_: ILowercasedColor;
}

export interface IEnum {
  Id: string;
  Name: string;
  Description: string;
  Index: number;
  Color: string;
  Color_: IColor;
}

export interface IIdWithReason {
  id: string;
  reason: string;
}
