import { Pagination } from "antd";
import React from "react";
import { IAppDataPaginationProps } from "../hooks/usePaginatedData";

export interface IPagePaginationProps extends IAppDataPaginationProps {}

const PagePagination: React.FC<IPagePaginationProps> = (props) => {
  const { page, pageSize, total, setPageSize, onNavigate } = props;

  return (
    <Pagination
      showSizeChanger
      pageSize={pageSize}
      current={page}
      total={total}
      onShowSizeChange={(current, next) => {
        setPageSize(next);
      }}
      onChange={onNavigate}
      showTotal={(total) => `Total ${total} items`}
    />
  );
};

export default PagePagination;
