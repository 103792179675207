import { Select, Space, Tag, Typography } from "antd";
import React from "react";
import {
  AnalysisFrequencyTypes,
  ANALYSIS_FREQ_TYPES_LABEL,
} from "../../definitions/analysis";
import InlineState from "../utils/InlineState";
import { appClasses } from "../utils/theme";
import { useAnalysisControls } from "./hooks";

const AnalysisControls: React.FC<{
  enableDrivers?: boolean;
  controls: ReturnType<typeof useAnalysisControls>;
  title?: string;
}> = (props) => {
  const { controls, title } = props;
  return (
    <Space direction="vertical" size="middle">
      <Typography.Title level={3}>{title || "Analysis"}</Typography.Title>
      <Space size="middle">
        <InlineState
          loading={controls.orgs.loading}
          error={controls.orgs.error?.message}
          onReload={controls.orgs.refresh}
          loadingText="Loading organizations..."
        >
          <Select
            value={controls.state.orgId}
            style={{ width: "100%" }}
            placeholder="Select organization"
            onChange={(val) => controls.setState({ orgId: val })}
            className={appClasses.controls.select}
          >
            {controls.orgs.data?.map((org) => (
              <Select.Option
                key={org.organizationId}
                value={org.organizationId}
              >
                <Space direction="vertical" size={4}>
                  <Typography.Text>{org.organizationName}</Typography.Text>
                  <Tag style={{ color: org.Status.Color }}>
                    {org.Status.Name}
                  </Tag>
                </Space>
              </Select.Option>
            ))}
          </Select>
        </InlineState>
        <Select
          value={controls.state.freqTypes}
          style={{ width: "100%" }}
          placeholder="Select time period"
          onChange={(val) => controls.setState({ freqTypes: val })}
          className={appClasses.controls.select}
        >
          {Object.keys(AnalysisFrequencyTypes).map((item) => (
            <Select.Option key={item}>
              <Typography.Text>
                {ANALYSIS_FREQ_TYPES_LABEL[item as AnalysisFrequencyTypes]}
              </Typography.Text>
            </Select.Option>
          ))}
        </Select>
        {props.enableDrivers && (
          <InlineState
            loading={controls.drivers.loading}
            error={controls.drivers.error?.message}
            onReload={controls.drivers.refresh}
            loadingText="Loading drivers..."
          >
            <Select
              value={controls.state.driverId}
              style={{ width: "100%" }}
              placeholder="Select driver"
              onChange={(val) => controls.setState({ driverId: val })}
              className={appClasses.controls.select}
            >
              {controls.drivers.data?.map((item) => (
                <Select.Option key={item.DriverId} value={item.DriverId}>
                  <Space direction="vertical" size={4}>
                    <Typography.Text>{item.FullName}</Typography.Text>
                    <Tag style={{ color: item.Status.Color }}>
                      {item.Status.Name}
                    </Tag>
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </InlineState>
        )}
      </Space>
    </Space>
  );
};

export default AnalysisControls;
