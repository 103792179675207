import { EditOutlined } from "@ant-design/icons";
import { Button, Space, Tag, Tooltip } from "antd";
import React from "react";
import { IGroupPolicy } from "../../definitions/group-policy";
import { appConstants } from "../../definitions/utils";
import DeleteAction, {
  IDeleteActionDeleteFnProps,
} from "../utils/delete/DeleteAction";
import LabeledNodeList, {
  ILabeledNodeListProps,
} from "../utils/LabeledNodeList";
import PolicyToggleStatusContainer from "./PolicyToggleStatusContainer";

export interface IPolicySecondaryDetailsProps {
  policy: IGroupPolicy;
  onEdit: () => void;
  onDelete: (props: IDeleteActionDeleteFnProps) => void;
  onCompleteUpdatePolicy: () => void;
  onCompleteDeletePolicy: () => void;
}

const PolicySecondaryDetails: React.FC<IPolicySecondaryDetailsProps> = (
  props
) => {
  const {
    policy,
    onEdit,
    onDelete,
    onCompleteUpdatePolicy,
    onCompleteDeletePolicy,
  } = props;

  // TODO: should we show created/updated date and  who?
  const isSystemManagedPolicy = policy.UpdatedBy === appConstants.systemName;
  const nodes: ILabeledNodeListProps["list"] = [
    { nodeIsText: true, label: "Role", node: policy.RoleInfo._RoleType },
    {
      nodeIsText: true,
      label: "Status",
      node: (
        <Space>
          <Tooltip title="update policy status">
            <PolicyToggleStatusContainer
              policy={policy}
              onCompleteUpdatePolicy={onCompleteUpdatePolicy}
              disabled={isSystemManagedPolicy}
            />
          </Tooltip>
          <Tag style={{ color: policy.Status.Color }}>{policy.Status.Name}</Tag>
        </Space>
      ),
    },
    { nodeIsText: true, label: "Date Created", node: policy.DateCreated_ },
    { nodeIsText: true, label: "Updated By", node: policy.UpdatedBy },
    { nodeIsText: true, label: "Updated At", node: policy.LastUpdated },
    {
      nodeIsText: true,
      label: "Actions",
      node: (
        <Space>
          <Tooltip title="edit policy" key="edit">
            <Button
              shape="circle"
              icon={<EditOutlined style={{ fontSize: "14px" }} />}
              onClick={onEdit}
              disabled={isSystemManagedPolicy}
            />
          </Tooltip>
          <DeleteAction
            id={policy.GroupPolicyId}
            name={policy.GroupName}
            deleteFn={onDelete}
            onCompleteDelete={onCompleteDeletePolicy}
            modalTitle="Delete Group Policy"
            promptMessage="Are you sure you want to delete this group policy?"
          />
        </Space>
      ),
    },
  ];

  return <LabeledNodeList direction="vertical" list={nodes} />;
};

export default PolicySecondaryDetails;
