import { IAdminCompany } from "../../definitions/company";
import { ISignedInUser } from "../../definitions/user";

export enum SessionType {
  Initializing = "initializing",
  Web = "web",
  App = "app",
  Uninitialized = "uninitialized",
}

export interface ISessionState {
  sessionType: SessionType;
  token?: string;
  refreshToken?: string;
  userProfile?: ISignedInUser;
  companyProfile?: IAdminCompany;
  userAvatarLastUpdatedAt?: string;
  companyAvatarLastUpdatedAt?: string;
}
