import { Col, Row } from "antd";
import React from "react";
import { DashboardTimeFilterValue } from "../../definitions/dashboard";
import { appClasses } from "../utils/theme";
import DashboardControls from "./DashboardControls";
import DashboardOrderCreationStatics from "./DashboardOrderCreationStatics";
import DashboardRecentOpenTickets from "./DashboardRecentOpenTickets";
import DashboardRecentOrdersUpdate from "./DashboardRecentOrdersUpdate";
import DashboardStaticCounts from "./DashboardStaticCounts";

const Dashboard: React.FC<{}> = () => {
  const [timeFilter, setTimeFilter] = React.useState(
    DashboardTimeFilterValue.All
  );

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <DashboardControls value={timeFilter} onChange={setTimeFilter} />
      </div>
      <div className={appClasses.pageContent} style={{ paddingBottom: "16px" }}>
        <Row wrap gutter={[16, 32]}>
          <Col span={12}>
            <DashboardStaticCounts time={timeFilter} />
          </Col>
          <Col span={12}>
            <DashboardOrderCreationStatics time={timeFilter} />
          </Col>
          <Col span={12}>
            <DashboardRecentOpenTickets />
          </Col>
          <Col span={12}>
            <DashboardRecentOrdersUpdate />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
