import { css } from "@emotion/css";
import React from "react";
import Banner from "./Banner";

const BannerWithContent: React.FC<{}> = (props) => {
  const { children } = props;

  return (
    <div
      className={css({
        display: "flex",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      })}
    >
      <div
        className={css({
          flex: 1,
          height: "100%",
        })}
      >
        <Banner
          className={css({
            width: "100%",
            height: "100%",
          })}
        />
      </div>
      <div
        className={css({
          flex: 1,
          overflow: "auto",
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default BannerWithContent;
