import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import { messages } from "../../definitions/messages";
import UserAPI from "../../api/endpoints/user";
import UpdateUserPasswordForm, {
  IUpdateUserPasswordFormValues,
} from "./UpdateUserPasswordForm";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { wrapWithMessageError } from "../utils/utils";

export interface IUpdateUserPasswordFormContainerProps {
  onCompleteUpdatePassword: () => void | Promise<void>;
}

const UpdateUserPasswordFormContainer: React.FC<
  IUpdateUserPasswordFormContainerProps
> = (props) => {
  const { onCompleteUpdatePassword } = props;
  const onSubmit = React.useCallback(
    async (data: IUpdateUserPasswordFormValues) => {
      wrapWithMessageError(async () => {
        const result = await UserAPI.updatePassword({
          currentPwd: data.currentPassword,
          newPwd: data.newPassword,
        });

        message.success(
          getEndpointResultMessage(result) || messages.passwordUpdatedSuccess
        );
        await onCompleteUpdatePassword();
      });
    },
    [onCompleteUpdatePassword]
  );

  const {
    loading: isSubmitting,
    error: submitError,
    run: onSubmitForm,
  } = useRequest(onSubmit, { manual: true });

  return (
    <UpdateUserPasswordForm
      onSubmit={onSubmitForm}
      error={submitError?.message}
      isSubmitting={isSubmitting}
    />
  );
};

export default UpdateUserPasswordFormContainer;
