import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import CompanyAPI from "../../api/endpoints/company";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { ICompany } from "../../definitions/company";
import { messages } from "../../definitions/messages";
import { wrapWithMessageError } from "../utils/utils";
import CompanyStatusForm, {
  ICompanyStatusFormValues,
} from "./CompanyStatusForm";

export interface ICompanyStatusFormContainerProps {
  company: ICompany;
  initialStatus?: string | null;
  onCompleteUpdateStatus: () => void;
  onCancel: () => void;
}

const CompanyStatusFormContainer: React.FC<ICompanyStatusFormContainerProps> = (
  props
) => {
  const { company, initialStatus, onCancel, onCompleteUpdateStatus } = props;
  const onSubmit = React.useCallback(
    async (data: ICompanyStatusFormValues) => {
      wrapWithMessageError(async () => {
        const result = await CompanyAPI.updateCompanyStatus({
          companyId: company.CompanyId,
          reason: data.reason,
          status: data.status,
        });

        message.success(
          getEndpointResultMessage(result) ||
            messages.companyUpdateStatusSuccess
        );
        onCompleteUpdateStatus();
      });
    },
    [company.CompanyId, onCompleteUpdateStatus]
  );

  const { loading, error, run } = useRequest(onSubmit, { manual: true });

  return (
    <CompanyStatusForm
      isSubmitting={loading}
      error={error?.message}
      onSave={run}
      company={company}
      onCancel={onCancel}
      initialStatus={initialStatus}
    />
  );
};

export default React.memo(CompanyStatusFormContainer);
