import { Space, Typography, Input } from "antd";
import React from "react";

export interface ITrackerControlsProps {
  disabled?: boolean;
  onEnterSearchQuery: (query: string) => void;
}

const TrackerControls: React.FC<ITrackerControlsProps> = (props) => {
  const { disabled, onEnterSearchQuery } = props;

  return (
    <Space direction="vertical" size="middle">
      <Typography.Title level={3}>Tracker</Typography.Title>
      <div>
        <Input.Search
          allowClear
          disabled={disabled}
          placeholder="Enter tracking number"
          onSearch={onEnterSearchQuery}
          style={{ width: 200 }}
        />
      </div>
    </Space>
  );
};

export default TrackerControls;
