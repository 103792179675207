import {
  ICompany,
  ICompanyInput,
  ICompanyStatusHistory,
} from "../../definitions/company";
import {
  IPaginatedDataInput,
  IPaginatedDataResult,
} from "../../definitions/utils";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { IEndpointMessageResult } from "../types";
import { selectEndpointParam, setEndpointParam } from "../utils";

const companyURL = `${netAddrs.serverAddr}/LogisticCompany`;
const pageURL = `${companyURL}/Page`;
const getCompanyHistoryURL = `${companyURL}/CompanyHistory`;
const createURL = `${companyURL}/Create`;
const updateURL = `${companyURL}/Update`;
const updateStatusURL = `${companyURL}/Status`;
const deleteCompanyURL = `${companyURL}/Delete`;

export interface IGetCompaniesEndpointResult
  extends IPaginatedDataResult<ICompany> {}

async function getCompanies(
  props: IPaginatedDataInput = {}
): Promise<IGetCompaniesEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);

  const endpointURL = selectEndpointParam(pageURL, params, props);
  const result = await invokeEndpointWithAuth<IGetCompaniesEndpointResult>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export interface IGetCompanyStatusHistoryEndpointInput {
  orgId: string;
}

export type IGetCompanyStatusHistoryEndpointResult =
  Array<ICompanyStatusHistory>;

async function getCompanyHistory(
  props: IGetCompanyStatusHistoryEndpointInput
): Promise<IGetCompanyStatusHistoryEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);

  const endpointURL = selectEndpointParam(getCompanyHistoryURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetCompanyStatusHistoryEndpointResult>({
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

async function createCompany(
  props: ICompanyInput
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: createURL,
    method: "POST",
    data: props,
  });

  return result;
}

async function updateCompany(
  props: ICompanyInput & { companyId: string }
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: updateURL,
    method: "PUT",
    data: props,
  });

  return result;
}

export interface IUpdateCompanyStatusEndpointProps {
  companyId: string;
  reason: string;
  status: string;
}

async function updateCompanyStatus(
  props: IUpdateCompanyStatusEndpointProps
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: updateStatusURL,
    method: "PUT",
    data: props,
  });

  return result;
}

export interface IDeleteCompanyEndpointProps {
  id: string;
  reason: string;
}

async function deleteCompany(
  props: IDeleteCompanyEndpointProps
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: deleteCompanyURL,
    method: "DELETE",
    data: props,
  });

  return result;
}

export default class CompanyAPI {
  public static getCompanies = getCompanies;
  public static getCompanyHistory = getCompanyHistory;
  public static createCompany = createCompany;
  public static updateCompany = updateCompany;
  public static updateCompanyStatus = updateCompanyStatus;
  public static deleteCompany = deleteCompany;
}
