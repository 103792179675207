import assert from "assert";
import React from "react";
import PageError from "../utils/PageError";
import PageLoading from "../utils/PageLoading";

export function useReturnNode<T = any>(props: {
  error?: Error;
  loading?: boolean;
  data?: T;
  run?: () => void;
}) {
  const { error, loading, data, run } = props;
  let returnNode: React.ReactNode = null;
  if (error) {
    returnNode = (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload", onClick: run }]}
      />
    );
  } else if (loading || !data) {
    returnNode = <PageLoading />;
  }

  const assertData = () => {
    assert(props.data);
    return props.data;
  };

  return { returnNode, assertData };
}
