import React from "react";
import EmployeeAPI from "../../api/endpoints/employee";
import { appLoggedInPaths } from "../../definitions/paths";
import usePaginatedData from "../hooks/usePaginatedData";
import useSectionCurrentPage from "../hooks/useSectionCurrentPage";
import PageLoading from "../utils/PageLoading";
import EmployeeFormContainer from "./EmployeeFormContainer";
import EmployeesPage from "./EmployeesPage";

const EmployeesRoot: React.FC<{}> = () => {
  const pageData = usePaginatedData({
    fetch: EmployeeAPI.getEmployees,
    getItemId: (item) => item.EmployeeId,
  });

  const { isInitialized, reloadEverything, getItemById } = pageData;
  const {
    isCreateForm,
    updateItemId,
    gotoUpdateForm,
    gotoCreateForm,
    gotoBase,
  } = useSectionCurrentPage({
    baseURL: appLoggedInPaths.employees,
    hasCreateForm: true,
    hasSelectedItemPage: false,
    hasUpdateForm: true,
    isComponentReady: isInitialized,
  });

  const onCompleteForm = React.useCallback(() => {
    gotoBase();
    reloadEverything();
  }, [gotoBase, reloadEverything]);

  if (isInitialized) {
    if (isCreateForm) {
      return (
        <EmployeeFormContainer
          onCompleteCreateEmployee={onCompleteForm}
          onCompleteUpdateEmployee={onCompleteForm}
        />
      );
    } else if (updateItemId) {
      return (
        <EmployeeFormContainer
          employee={getItemById(updateItemId)!}
          onCompleteCreateEmployee={onCompleteForm}
          onCompleteUpdateEmployee={onCompleteForm}
        />
      );
    } else {
      return (
        <EmployeesPage
          {...pageData}
          onCreateEmployee={gotoCreateForm}
          gotoUpdateForm={gotoUpdateForm}
        />
      );
    }
  }

  return <PageLoading />;
};

export default EmployeesRoot;
