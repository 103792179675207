import { css } from "@emotion/css";
import { Empty, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { appBasePaths, appLoggedInPaths } from "../../definitions/paths";
import SessionSelectors from "../../redux/session/selectors";
import { SessionType } from "../../redux/session/types";
import AnalysisPage from "../analysis/AnalysisPage";
import AuditLogs from "../audit-log/AuditLogs";
import CompaniesRoot from "../company/CompaniesRoot";
import Dashboard from "../dashboard/Dashboard";
import EmployeesRoot from "../employees/EmployeesRoot";
import PoliciesRoot from "../group-policy/PoliciesRoot";
import RequestsRoot from "../requests/RequestsRoot";
import TicketsRoot from "../ticket/TicketsRoot";
import TrackerRoot from "../tracker/TrackerRoot";
import ProfilePage from "../user/ProfilePage";
import AdminNavBar from "./AdminNavBar";
import AppBar from "./AppBar";

const classes = {
  notImplementedRoot: css({
    padding: "24px",
    textAlign: "center",
    display: "flex",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  }),
  root: css({
    display: "grid",
    gridTemplateColumns: "240px auto",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }),
  body: css({
    display: "grid",
    height: "100%",
    overflow: "hidden",
    gridTemplateRows: "64px auto",
  }),
};

const MoveBotApp: React.FC<{}> = (props) => {
  const sessionType = useSelector(SessionSelectors.getSessionType);

  if (sessionType !== SessionType.App) {
    return <Redirect to={appBasePaths.login} />;
  }

  const EMPTY_PAGE = () => (
    <div className={classes.notImplementedRoot}>
      <Empty
        description={
          <Typography.Text type="secondary">
            Select a route to get started.
          </Typography.Text>
        }
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <AdminNavBar />
      <div className={classes.body}>
        <AppBar />
        <Switch>
          <Route path={appLoggedInPaths.dashboard}>
            <Dashboard />
          </Route>
          <Route path={appLoggedInPaths.analysis}>
            <AnalysisPage />
          </Route>
          <Route path={appLoggedInPaths.groupPolicy}>
            <PoliciesRoot />
          </Route>
          <Route path={appLoggedInPaths.company}>
            <CompaniesRoot />
          </Route>
          <Route path={appLoggedInPaths.employees}>
            <EmployeesRoot />
          </Route>
          <Route path={appLoggedInPaths.audit}>
            <AuditLogs />
          </Route>
          <Route path={appLoggedInPaths.profile}>
            <ProfilePage />
          </Route>
          <Route path={appLoggedInPaths.ticket}>
            <TicketsRoot />
          </Route>
          <Route path={appLoggedInPaths.tracking}>
            <TrackerRoot />
          </Route>
          <Route path={appLoggedInPaths.requests}>
            <RequestsRoot />
          </Route>
          <Route path={appBasePaths.app} render={EMPTY_PAGE} />
          <Route
            exact
            path="*"
            render={() => {
              return <Redirect to={appBasePaths.app} />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

export default MoveBotApp;
