import { css } from "@emotion/css";
import { Alert, Space, Steps, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ICreateAdminCompanyInput } from "../../definitions/company";
import { appBasePaths } from "../../definitions/paths";
import FormLogo from "../forms/FormLogo";
import { formClasses } from "../forms/formStyleUtils";
import { utilClassNames } from "../utils/util-class-names";
import SignupCompanyForm, {
  IOutgoingSignupCompanyFormValues,
} from "./SignupCompanyForm";
import SignupUserForm, { ISignupUserFormValues } from "./SignupUserForm";

export interface ISignupFormProps {
  onSubmit: (data: ICreateAdminCompanyInput) => void;
  isSubmitting?: boolean;
  error?: string;
}

const kUserDetailsStep = 1;
const kCompanyDetailsStep = 2;

const SignupForm: React.FC<ISignupFormProps> = (props) => {
  const { isSubmitting, error, onSubmit } = props;
  const [currentStep, setCurrentStep] = React.useState<
    typeof kUserDetailsStep | typeof kCompanyDetailsStep
  >(kUserDetailsStep);
  const [userDetails, setUserDetails] = React.useState<
    ISignupUserFormValues | undefined
  >(undefined);
  const [companyDetails, setCompanyDetails] = React.useState<
    Partial<IOutgoingSignupCompanyFormValues> | undefined
  >(undefined);

  const onSubmitUserDetails = React.useCallback(
    (val: ISignupUserFormValues) => {
      setUserDetails(val);
      setCurrentStep(kCompanyDetailsStep);
    },
    []
  );

  const onBackFromCompanyDetailsForm = React.useCallback(
    (val: Partial<IOutgoingSignupCompanyFormValues>) => {
      setCompanyDetails(val);
      setCurrentStep(kUserDetailsStep);
    },
    []
  );

  const onSubmitCompanyDetails = React.useCallback(
    (val: IOutgoingSignupCompanyFormValues) => {
      setCompanyDetails(val);

      if (userDetails && val) {
        onSubmit({
          name: val.companyName,
          emailAddress: val.companyEmail,
          phoneNumber: val.companyPhoneNumber,
          address: val.address,
          websiteUrl: val.websiteURL,
          facebookUrl: val.facebookURL,
          twitterUrl: val.twitterUrl,
          instagramUrl: val.instagramURL,
          youtubeUrl: val.instagramURL,
          countryCode: val.countryCode,
          moveBotAdmin: userDetails,
        });
      }
    },
    [userDetails, onSubmit]
  );

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <div className={css({ padding: "0px 16px" })}>
        <FormLogo />
      </div>
      <div
        className={css({
          margin: "0px 16px",
        })}
      >
        <Space
          direction="vertical"
          size="large"
          className={css({ width: "100%" })}
        >
          <Typography.Title level={4}>Signup</Typography.Title>
          {error && <Alert message={error} type="error" />}
          <Steps current={currentStep}>
            <Steps.Step title="Your Details" />
            <Steps.Step title="Company Details" />
          </Steps>
        </Space>
      </div>
      <div
        className={css({
          display: "flex",
          justifyContent: "center",
          width: "100%",
        })}
      >
        {currentStep === 1 ? (
          <SignupUserForm
            data={userDetails}
            isSubmitting={isSubmitting}
            onSubmit={onSubmitUserDetails}
            className={css({ maxWidth: "700px" })}
          />
        ) : (
          <SignupCompanyForm
            data={companyDetails}
            onBack={onBackFromCompanyDetailsForm}
            isSubmitting={isSubmitting}
            onSubmit={onSubmitCompanyDetails}
          />
        )}
      </div>
      <div className={formClasses.body}>
        <div className={formClasses.bodyInner}>
          <Typography.Paragraph className={css({ marginBottom: "16px" })}>
            Already have an account?{" "}
            <Link to={appBasePaths.login}>
              <Typography.Text
                className={utilClassNames.typographyWithPrimaryColor}
              >
                Login
              </Typography.Text>
            </Link>
          </Typography.Paragraph>
        </div>
      </div>
    </Space>
  );
};

export default SignupForm;
