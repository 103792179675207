import { useRequest } from "ahooks";
import { message } from "antd";
import uuid from "uuid";
import React from "react";
import TicketAPI from "../../api/endpoints/ticket";
import { messages } from "../../definitions/messages";
import TicketForm, { ITicketFormValues } from "./TicketForm";
import { removeUnusedImages } from "../utils/images";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { wrapWithMessageError } from "../utils/utils";

export interface ITicketFormContainerProps {
  onCompleteCreateTicket: () => void;
}

const TicketFormContainer: React.FC<ITicketFormContainerProps> = (props) => {
  const { onCompleteCreateTicket } = props;
  const [attachmentId, setAttachmentId] = React.useState(uuid.v4);
  const attachmentIdSubmittedImageCountRef = React.useRef(0);

  React.useEffect(() => {
    return () => {
      if (attachmentIdSubmittedImageCountRef.current === 0) {
        removeUnusedImages({ id: attachmentId, isGroupImage: true });
      }

      attachmentIdSubmittedImageCountRef.current = 0;
    };
  }, [attachmentId]);

  const onSubmit = React.useCallback(
    async (data: ITicketFormValues) => {
      wrapWithMessageError(async () => {
        const result = await TicketAPI.createTicket({
          orgId: data.orgId,
          data: {
            attachmentId: data.imageCount ? attachmentId : undefined,
            priority: data.priority,
            subject: data.subject,
            customService: data.customService,
            message: data.message,
            relatedService: data.relatedService,
          },
        });

        message.success(
          getEndpointResultMessage(result) || messages.ticketCreatedSuccess
        );
        attachmentIdSubmittedImageCountRef.current = data.imageCount || 0;
        setAttachmentId(uuid.v4());
        onCompleteCreateTicket();
      });
    },
    [attachmentId, onCompleteCreateTicket]
  );

  const {
    loading: isSubmitting,
    error: submitError,
    run: onSubmitForm,
  } = useRequest(onSubmit, { manual: true });

  return (
    <TicketForm
      key={attachmentId}
      attachmentId={attachmentId}
      onSubmit={onSubmitForm}
      error={submitError?.message}
      isSubmitting={isSubmitting}
    />
  );
};

export default TicketFormContainer;
