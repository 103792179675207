import { IAddress, IAPIAddress } from "./address";

export interface ICreateAdminUserInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  address: IAddress;
  countryCode: string;
}

export type IUpdateAdminUserInput = Omit<ICreateAdminUserInput, "password">;

export interface IUserPasswordChangeInput {
  currentPwd: string;
  newPwd: string;
}

export interface IAuthUser {
  userId: string;
  fullName: string;
  emailAddress: string;
  currentRole: string;
  companyName: string;
}

export interface ISignedInUser {
  EmployeeId: string;
  FullName: string;
  CurrentRole: string;
  EmailAddress: string;
  PhoneNumber: string;
  CountryCode: string;
  Address: string;
  LastLoggedIn: string;
  LastUpdated: string;
  DateCreated: string;
  ProfileRawData: {
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    PhoneNumber: string;
    CountryCode: string;
    Address: IAPIAddress;
  };
}

export const userConstants = {
  maxForgotPasswordTokenLength: 10,
  minPasswordLength: 4,
  maxPasswordLength: 40,
};
