export type StorageType = "localStorage" | "sessionStorage";

export function storageAvailable(type: StorageType) {
  const storage = window[type];

  if (!storage) {
    return false;
  }

  try {
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    );
  }
}

export let appLocalStorage = storageAvailable("localStorage")
  ? window.localStorage
  : null;

export let appSessionStorage = storageAvailable("sessionStorage")
  ? window.sessionStorage
  : null;

type StorageName = StorageType | "local" | "session";

function getStorageType(storageType: StorageName) {
  switch (storageType) {
    case "local":
    case "localStorage":
      return appLocalStorage;

    case "session":
    case "sessionStorage":
    default:
      return appSessionStorage;
  }
}

const defaultStorageType: StorageName = "local";

export function setItem(
  key: string,
  data: string | boolean | number | null | undefined,
  storageType = defaultStorageType
) {
  if (data === null || data === undefined) {
    return;
  }

  const storageObject = getStorageType(storageType);

  if (storageObject) {
    storageObject.setItem(key, String(data));
  }
}

export function removeItem(key: string, storageType = defaultStorageType) {
  const storageObject = getStorageType(storageType);

  if (storageObject) {
    storageObject.removeItem(key);
  }
}

export function getItem(
  key: string,
  storageType = defaultStorageType,
  type: "string" | "number" | "boolean" = "string"
) {
  const storageObject = getStorageType(storageType);

  if (!storageObject) {
    return undefined;
  }

  const data = storageObject.getItem(key);

  if (data) {
    switch (type) {
      case "string":
        return data;

      case "boolean":
        return data === "true" ? true : false;

      case "number":
        return Number(data);
    }
  }

  return data;
}

function makeStorageClass(storageType: StorageName) {
  return class AppSessionStorage {
    static getItem(
      key: string,
      type: "string" | "number" | "boolean" = "string"
    ) {
      return getItem(key, storageType, type);
    }

    static setItem(
      key: string,
      data: string | boolean | number | null | undefined
    ) {
      setItem(key, data, storageType);
    }

    static removeItem(key: string) {
      removeItem(key, storageType);
    }
  };
}

export const AppSessionStorage = makeStorageClass("session");
export const AppLocalStorage = makeStorageClass("local");
