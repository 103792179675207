import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UserAPI from "../../api/endpoints/user";
import SessionStorageFuncs from "../../browser-storage/session";
import SessionActions from "../../redux/session/actions";
import SessionSelectors from "../../redux/session/selectors";
import { SessionType } from "../../redux/session/types";

export default function useSessionType() {
  const dispatch = useDispatch();
  const sessionType = useSelector(SessionSelectors.getSessionType);

  const init = React.useCallback(async () => {
    if (sessionType === SessionType.Uninitialized) {
      const token = SessionStorageFuncs.getUserToken();

      if (token) {
        dispatch(SessionActions.setSessionType(SessionType.Initializing));

        try {
          const userProfile = await UserAPI.getUserProfile();
          const companyProfile = await UserAPI.getCompanyProfile();
          dispatch(
            SessionActions.loginUser({
              token,
              userProfile,
              companyProfile,
              refreshToken: SessionStorageFuncs.getRefreshToken(),
            })
          );
        } catch (error) {
          dispatch(SessionActions.setSessionType(SessionType.Web));
        }
      } else {
        dispatch(SessionActions.setSessionType(SessionType.Web));
      }
    }
  }, [dispatch, sessionType]);

  React.useEffect(() => {
    init();
  }, [init]);

  return sessionType;
}
