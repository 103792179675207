import { List } from "antd";
import React from "react";
import { ITicketActivity } from "../../definitions/ticket";
import ListSkeleton from "../utils/ListSkeleton";
import PageNothingFound from "../utils/PageNothingFound";
import TicketActivity from "./TicketActivity";

export interface ITicketActivitiesProps {
  loading?: boolean;
  activities: Array<ITicketActivity>;
}

const TicketActivities: React.FC<ITicketActivitiesProps> = (props) => {
  const { loading, activities } = props;

  if (loading) {
    return <ListSkeleton />;
  }

  if (activities.length === 0) {
    return <PageNothingFound />;
  }

  return (
    <List
      split
      bordered
      loading={loading}
      itemLayout="horizontal"
      dataSource={activities}
      renderItem={(item, index) => {
        return <TicketActivity key={index} activity={item} />;
      }}
    />
  );
};

export default TicketActivities;
