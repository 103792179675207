import { Pie, PieConfig } from "@ant-design/plots";
import { Select, Space } from "antd";
import { first } from "lodash";
import React from "react";
import { IAnalysisDeliveryCostStat } from "../../definitions/analysis";
import PageNothingFound from "../utils/PageNothingFound";
import { appClasses, appFonts } from "../utils/theme";

export interface IAnalysisDeliveryCostStatsProps {
  stats: IAnalysisDeliveryCostStat[];
}

const AnalysisDeliveryCostStats: React.FC<IAnalysisDeliveryCostStatsProps> = (
  props
) => {
  const { stats } = props;
  const [type, setType] = React.useState(first(stats)?.Type.Name);
  const stat = stats.find((item) => item.Type.Name === type);
  let contentNode: React.ReactNode = null;
  if (!stat) {
    contentNode = <PageNothingFound messageText="Stats not available." />;
  } else {
    const data: Array<{ type: string; value: number }> = [
      {
        type: "Delivery cost",
        value: stat.DeliveryCost,
      },
      {
        type: "Insurance cost",
        value: stat.Insurance,
      },
      {
        type: "Tax",
        value: stat.Tax,
      },
      {
        type: "Total",
        value: Number(stat.Total_.replace("$", "")),
      },
    ];

    const config: PieConfig = {
      data,
      appendPadding: 10,
      angleField: "value",
      colorField: "type",
      radius: 0.9,
      label: {
        type: "inner",
        offset: "-30%",
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: "center",
        },
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
      theme: {
        fontFamily: appFonts.main,
      },
    };

    contentNode = <Pie {...config} />;
  }

  const types = stats.map((item) => item.Type);
  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Select
        value={type}
        placeholder="Select type"
        onChange={setType}
        className={appClasses.controls.select}
      >
        {types.map((item) => (
          <Select.Option key={item.Name} value={item.Name}>
            {item.Name}
          </Select.Option>
        ))}
      </Select>
      {contentNode}
    </Space>
  );
};

export default AnalysisDeliveryCostStats;
