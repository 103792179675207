import { defaultTo, findKey } from "lodash";
import React from "react";
import { messages } from "../../definitions/messages";
import {
  IPaginatedDataInput,
  IPaginatedDataResult,
} from "../../definitions/utils";
import cast from "../../utils/cast";
import { indexArray } from "../../utils/utils";

const DEFAULT_PAGE_SIZE = 10;

export interface IUsePaginatedDataProps<
  T,
  FetchExtra extends Record<string, any>
> {
  defaultPageSize?: number;
  defaultFetchExtra?: FetchExtra;
  fetch: <Input extends IPaginatedDataInput & FetchExtra>(
    input: Input
  ) => Promise<IPaginatedDataResult<T>>;
  getItemId: (item: T) => string;
}

export interface IAppDataPaginationProps {
  page: number;
  pageSize: number;
  total: number;
  setPageSize: (size: number) => void;
  onNavigate: (page: number) => void;
}

export interface IUsePaginatedDataResult<
  T,
  FetchExtra extends Record<string, any>
> extends IAppDataPaginationProps {
  isInitialized: boolean;
  fetchExtra: FetchExtra;
  getPageItems: (page: number) => T[];
  getPageLoading: (page: number) => boolean;
  getPageError: (page: number) => string | undefined;
  reloadPageItems: (page?: number) => void;
  getItemById: (itemId: string) => T | null;
  getItemPageById: (itemId: string) => number | null;
  setFetchExtra: (fetchExtra: FetchExtra, reload?: boolean) => void;
  reloadEverything: (keepCurrentPage?: boolean) => void;
}

function usePaginatedData<
  T,
  FetchExtra extends Record<string, any> = Record<string, any>
>(
  props: IUsePaginatedDataProps<T, FetchExtra>
): IUsePaginatedDataResult<T, FetchExtra> {
  const { defaultFetchExtra, defaultPageSize, fetch, getItemId } = props;
  const [isInitialized, setInitializedState] = React.useState(false);
  const [loadingPagesMap, setLoadingPagesMap] = React.useState<
    Record<number, boolean>
  >({});

  const [data, setData] = React.useState<Record<number, string[]>>({});
  const [dataIndex, setDataIndex] = React.useState<Record<string, T>>({});
  const [errors, setErrors] = React.useState<Record<number, string>>({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(
    defaultPageSize || DEFAULT_PAGE_SIZE
  );

  const [total, setTotal] = React.useState(0);
  const [fetchExtra, setFetchExtra] = React.useState<FetchExtra>(
    cast<FetchExtra>(defaultTo(defaultFetchExtra, {}))
  );

  const getDataForPage = React.useCallback(
    async (pageNumber?: number) => {
      pageNumber = pageNumber ?? page;
      pageNumber = pageNumber === 0 ? 1 : pageNumber;
      setLoadingPagesMap({
        ...loadingPagesMap,
        [pageNumber]: true,
      });

      setErrors({
        ...errors,
        [pageNumber]: "",
      });

      try {
        const fetchResult = await fetch({
          ...fetchExtra,
          pageNumber,
          pageSize,
        });

        setTotal(fetchResult.TotalSize);
        const indexedFetchedData = indexArray(fetchResult.Data, {
          indexer: getItemId,
        });
        setDataIndex({ ...dataIndex, ...indexedFetchedData });
        setData({ ...data, [pageNumber]: Object.keys(indexedFetchedData) });
      } catch (error: any) {
        setErrors({
          ...errors,
          [pageNumber]: error?.message || messages.requestError,
        });
      }

      setLoadingPagesMap({
        ...loadingPagesMap,
        [pageNumber]: false,
      });
    },
    [
      page,
      errors,
      data,
      loadingPagesMap,
      fetchExtra,
      pageSize,
      dataIndex,
      getItemId,
      fetch,
    ]
  );

  const onNavigate = React.useCallback(
    (pageNumber: number) => {
      setPage(pageNumber);

      if (pageNumber === page || !!data[pageNumber]) {
        return;
      }

      getDataForPage(pageNumber);
    },
    [page, data, getDataForPage]
  );

  const getPageItems = React.useCallback(
    (pageNumber: number) => {
      return (data[pageNumber] || []).map((itemId) => dataIndex[itemId]);
    },
    [data, dataIndex]
  );

  const getPageLoading = React.useCallback(
    (pageNumber: number) => {
      return !!loadingPagesMap[pageNumber];
    },
    [loadingPagesMap]
  );

  const getPageError = React.useCallback(
    (pageNumber: number) => {
      return errors[pageNumber];
    },
    [errors]
  );

  const getItemById = React.useCallback(
    (itemId: string) => {
      return dataIndex[itemId] || null;
    },
    [dataIndex]
  );

  const getItemPageById = React.useCallback(
    (itemId: string) => {
      const itemPage = findKey(data, (ids) => ids.includes(itemId));
      return Number.isNaN(Number(itemPage)) ? null : Number(itemPage);
    },
    [data]
  );

  const reloadEverything = React.useCallback(
    (keepCurrentPage?: boolean) => {
      setErrors({});
      setLoadingPagesMap({});
      setTotal(0);
      setData([]);

      if (keepCurrentPage) {
        getDataForPage(page);
      } else {
        setPage(0);
        setInitializedState(false);
      }
    },
    [page, getDataForPage]
  );

  const externalSetFetchExtra = React.useCallback(
    (incomingFetchExtra: FetchExtra, reload = false) => {
      setFetchExtra(incomingFetchExtra);

      if (reload) {
        reloadEverything();
      }
    },
    [reloadEverything]
  );

  const onChangePageSize = React.useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize);
      reloadEverything();
    },
    [reloadEverything]
  );

  React.useEffect(() => {
    if (!isInitialized) {
      setInitializedState(true);
      setPage(1);
      getDataForPage(1);
    }
  }, [isInitialized, getDataForPage]);

  const result: IUsePaginatedDataResult<T, FetchExtra> = {
    page,
    total,
    pageSize,
    isInitialized,
    fetchExtra,
    onNavigate,
    getPageItems,
    reloadEverything,
    getItemById,
    getItemPageById,
    getPageLoading,
    getPageError,
    setPageSize: onChangePageSize,
    setFetchExtra: externalSetFetchExtra,
    reloadPageItems: getDataForPage,
  };

  return result;
}

export default usePaginatedData;
