import { css, cx } from "@emotion/css";
import React from "react";

export interface IScrollContainerProps {
  className?: string;
  style?: React.CSSProperties;
  innerClassName?: string;
  innerStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

const classes = {
  root: css({
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }),
  inner: css({
    width: "100%",
    height: "100%",
    overflow: "auto",
  }),
};

const ScrollContainer: React.FC<IScrollContainerProps> = (props) => {
  const { className, style, innerClassName, innerStyle, children } = props;
  return (
    <div className={cx(classes.root, className)} style={style}>
      <div className={cx(classes.inner, innerClassName)} style={innerStyle}>
        {children}
      </div>
    </div>
  );
};

export default ScrollContainer;
