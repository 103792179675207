import { useHistory, useRouteMatch } from "react-router";
import React from "react";
import { appAuxiliaryPaths } from "../../definitions/paths";

export interface IUseSectionCurrentPageProps {
  hasCreateForm?: boolean;
  hasUpdateForm?: boolean;
  hasSelectedItemPage?: boolean;
  baseURL: string;
  isComponentReady: boolean;
}

export interface IUseSectionCurrentPageResult {
  isCreateForm: boolean;
  selectedItemId?: string;
  updateItemId?: string;
  gotoCreateForm: () => void;
  gotoBase: () => void;
  gotoUpdateForm: (id: string) => void;
  gotoSelectedItem: (id: string) => void;
}

interface IPath01RouteMatch {
  path01: string;
}

const FORM_SUBPATH = appAuxiliaryPaths.form.split("/")[1]; // should resolve to 'form'

export default function useSectionCurrentPage(
  props: IUseSectionCurrentPageProps
): IUseSectionCurrentPageResult {
  const {
    hasCreateForm,
    hasUpdateForm,
    hasSelectedItemPage,
    baseURL,
    isComponentReady: areItemsLoaded,
  } = props;

  const history = useHistory();
  const path01RouteMatch = useRouteMatch<IPath01RouteMatch>({
    path: `${baseURL}/:path01`,
    exact: true,
  });

  const path02RouteMatch = useRouteMatch<IPath01RouteMatch>({
    path: `${baseURL}${appAuxiliaryPaths.form}/:path01`,
    exact: true,
  });

  const updateItemId = path02RouteMatch?.params.path01;
  const isCreateForm = path01RouteMatch?.params.path01 === FORM_SUBPATH;
  const selectedItemId = !isCreateForm
    ? path01RouteMatch?.params.path01
    : undefined;

  React.useEffect(() => {
    if (updateItemId && (!hasUpdateForm || !areItemsLoaded)) {
      history.push(baseURL);
    }
  }, [updateItemId, areItemsLoaded, hasUpdateForm, history, baseURL]);

  React.useEffect(() => {
    if (isCreateForm && !hasCreateForm) {
      history.push(baseURL);
    }
  }, [isCreateForm, hasCreateForm, history, baseURL]);

  React.useEffect(() => {
    if (selectedItemId && (!hasSelectedItemPage || !areItemsLoaded)) {
      history.push(baseURL);
      return;
    }
  }, [selectedItemId, hasSelectedItemPage, history, baseURL, areItemsLoaded]);

  const gotoCreateForm = React.useCallback(() => {
    history.push(baseURL + appAuxiliaryPaths.form);
  }, [history, baseURL]);

  const gotoUpdateForm = React.useCallback(
    (id: string) => {
      history.push(baseURL + appAuxiliaryPaths.form + "/" + id);
    },
    [history, baseURL]
  );

  const gotoSelectedItem = React.useCallback(
    (id: string) => {
      history.push(baseURL + "/" + id);
    },
    [history, baseURL]
  );

  const gotoBase = React.useCallback(() => {
    history.push(baseURL);
  }, [history, baseURL]);

  return {
    isCreateForm,
    selectedItemId,
    updateItemId,
    gotoCreateForm,
    gotoUpdateForm,
    gotoBase,
    gotoSelectedItem,
  };
}
