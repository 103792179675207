import { css, cx } from "@emotion/css";
import { Alert, Button, Form, Input, Space, Typography } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { ICompany } from "../../definitions/company";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import StatusInput from "../forms/StatusInput";
import { formValidationSchemas } from "../forms/validation";

export interface ICompanyStatusFormValues {
  status: string;
  reason: string;
}

export interface ICompanyStatusFormProps {
  initialStatus?: string | null;
  isSubmitting?: boolean;
  error?: string;
  company: ICompany;
  onSave: (value: ICompanyStatusFormValues) => void | Promise<void>;
  onCancel: () => void;
}

const CompanyStatusForm: React.FC<ICompanyStatusFormProps> = (props) => {
  const { initialStatus, error, company, isSubmitting, onSave, onCancel } =
    props;
  const formik = useFormik({
    initialValues: { reason: "", status: initialStatus || "" },
    validationSchema: yup.object().shape({
      reason: formValidationSchemas.strRequired,
      status: formValidationSchemas.strRequired,
    }),
    onSubmit: (data) => {
      onSave({
        reason: data.reason,
        status: data.status,
      });
    },
  });

  const statusNode = (
    <Form.Item
      required
      label="Status"
      help={
        formik.touched?.status &&
        formik.errors?.status && (
          <FunErrorMessages message={formik.errors.status}>
            <FormError error={formik.errors.status} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <StatusInput
        disabled={isSubmitting}
        value={formik.values.status}
        onChange={(val) => formik.setFieldValue("status", val)}
      />
    </Form.Item>
  );

  const reasonNode = (
    <Form.Item
      required
      label="Reason"
      help={
        formik.touched?.reason &&
        formik.errors?.reason && (
          <FunErrorMessages message={formik.errors.reason}>
            <FormError error={formik.errors.reason} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        autoFocus
        name="reason"
        value={formik.values.reason}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter your reason"
        disabled={isSubmitting}
        autoSize={{
          minRows: 2,
          maxRows: 4,
        }}
      />
    </Form.Item>
  );

  return (
    <Space
      direction="vertical"
      size="large"
      className={cx(formClasses.body, css({ padding: 0 }))}
    >
      <Typography.Title level={4}>{company.Name}</Typography.Title>
      {error && <Alert message={error} type="error" />}
      <form onSubmit={formik.handleSubmit}>
        {statusNode}
        {reasonNode}
        <Form.Item className={css({ marginTop: "32px !important" })}>
          <div className={css({ display: "flex" })}>
            <div className={css({ flex: 1, marginRight: "16px" })}>
              <Button
                danger
                htmlType="button"
                loading={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </form>
    </Space>
  );
};

export default CompanyStatusForm;
