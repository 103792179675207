import { appConstants, BYTES } from "./utils";

export const messages = {
  // generic
  loading: "Loading",
  URLExample: "Example, http://www.movebot.com",
  notSignedIn: "User is not signed in",
  loginAgain: "Please login again",
  statusUpdated: "Status updated",
  codeSent: "Code Sent!",
  requestSuccessful: "Request successful",
  signupSuccessful: "Signup successful",
  imageDeleted: "Image deleted",

  // error
  errorOccurred: "An error occurred",
  requestError: "Error completing request",
  initComponentError: "Error initializing component, please reload the page",

  // form
  requiredField: "Field is required",

  // user error messages
  emailRequired: "Please enter a valid email address",
  nameRequired: "Name is required",
  firstNameRequired: "First name is required",
  lastNameRequired: "Last name is required",
  emailAddressDoesNotMatch: "The email addresses you entered do not match",
  passwordDoesNotMatch: "The passwords you entered do not match",
  alphanumericKeysOnly: "Please enter only alphanumeric characters",
  socialMediaInvalid: "Allowed characters are A-Z, a-z, 0-9, @, -, _, .",
  provideValidPasswordWithoutSymbols:
    "Enter only alpanumeric characters with any of the allowed symbols",
  provideValidPasswordWithSymbols:
    "Enter only alpanumeric characters with any of the allowed symbols [!()?_`~#$^&*+=]",
  validPasswordSymbols: "!()?_`~#$^&*+=",
  passwordMinCharacters: "Please enter a minimum of 7 characters",
  userAccessRevoked: "Hi, sorry but your access has been revoked",
  invalidCredentials:
    "You've provided an invalid credential, maybe try loggin in again",
  credentialsExpired: "Your credentials have expired, try logging in again",
  emailAddressNotAvailable: "The email address you entered is not available",
  invalidEmailAddress: "The value you entered is not a valid email address",
  permissionDenied:
    "Hi, sorry, we tried, but you don't have the necessary permissions to do what you've requested",
  userDoesNotExist: "Sorry, but the user does not exist",
  collaboratorDoesNotExist: "Sorry, but the collaborator does not exist",
  invalidLoginCredentials:
    "You've entered an incorrect email address or password",
  apiEmailAddressUnavailableErrorMessage: "This email address is not available",
  phoneNumberInvalid: "Please enter a valid phone number",
  enterValidPhoneNumber: "Please enter a valid phone number",
  zipcodeInvalid: "Please enter a valid zip code",
  URLInvalid: "Please enter a valid URL",
  invalidEmailOrPhoneNumber:
    "Please enter a valid email address or phone number",

  // user form messages
  passwordExtraInfo: "Minimum of 7 characters",

  // user form labels
  nameLabel: "Your Name",
  emailAddressLabel: "Your Email Address",
  changeEmailLabel: "Change Your Email Address",
  confirmEmailAddressLabel: "Confirm Your Email Address",
  passwordLabel: "Your Password",
  newPasswordLabel: "New Password",
  currentPasswordLabel: "Current Password",
  confirmPasswordLabel: "Confirm Your Password",
  colorLabel: "Select Your Preferred Color Avatar",

  // user form placeholders
  namePlaceHolder: "Enter your first name and last name",
  emailAddressPlaceholder: "Enter your email address",
  confirmEmailAddressPlaceholder: "Re-enter your email address",
  passwordPlaceholder: "Enter your new password",
  currentPasswordPlaceholder: "Enter your current password",
  confirmPasswordPlaceholder: "Re-enter your new password",

  // group policy
  loadPoliciesError: "Error loading policies",
  deletePolicyError: "Error deleting policy",
  updatePolicyError: "Error updating policy",
  policyUpdated: "Policy updated",
  policyDeleted: "Policy deleted",
  policyUpdatedWithReloadPrompt:
    "Policy updated. Reload the app to see latest changes.",
  policyCreatedWithReloadPrompt:
    "Policy created. Reload the app to see latest changes.",

  // company
  companyUpdateStatusError: "Error updating company status",
  companyUpdateStatusSuccess: "Company status updated",
  companyDeleteError: "Error deleting company",
  companyDeleteSuccess: "Company deleted",
  companyCreatedSuccess: "Company created",
  companyUpdatedSuccess: "Company updated",

  // employee
  employeeUpdateStatusError: "Error updating employee status",
  employeeUpdateStatusSuccess: "Employee status updated",
  employeeDeleteError: "Error deleting employee",
  employeeDeleteSuccess: "Employee removed",
  employeeCreatedSuccess: "Employee created",
  employeeUpdatedSuccess: "Employee updated",

  // ticket
  ticketCreatedSuccess: "Ticket created",
  ticketUpdatedSuccess: "Ticket updated",
  ticketActivitySubmitted: "Ticket message sent",
  ticketActivityError: "Error sending ticket message",
  ticketStatusUpdateError: "Error updating ticket status",

  // profile
  profileUpdateError: "Error updating profile",
  profileUpdateSuccess: "Profile updated",
  passwordUpdatedSuccess: "Password updated",
  uploadingProfilePicture: "Uploading profile picture",
  profilePictureUploadFailed: "Profile picture upload failed",
  profilePictureUploaded: "Profile picture uploaded successfully",

  // file
  invalidImageType: "You can only upload JPG or PNG file",
  uploadingImage: "Uploading image",
  imageUploadSuccessful: "Image uploaded successfully",
  imageUploadFailed: "Image upload failed",
  imageTooBig: `"Image must smaller than ${
    appConstants.maxImageSize / (BYTES ^ 2)
  }MB"`,

  // movebot
  movebotLogo: "MoveBot logo",
  uploadingMovebotImage: "Uploading Movebot image",
  movebotImageUploadFailed: "Movebot image upload failed",
  movebotImageUploaded: "Movebot image uploaded successfully",

  // fun error messages
  funPassword: "fun-password",
  funSocialMediaHandle: "fun-social-media-handle",
  funName: "fun-name",
};
