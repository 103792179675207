import { css } from "@emotion/css";
import { Space } from "antd";
import { TicketStatus } from "../../definitions/utils";
import SelectTicketStatus from "../ticket/SelectTicketStatus";
import { appDimensions } from "../utils/theme";

export interface IPartnerWithUsRequestsPageControlsProps {
  disabled?: boolean;
  status?: TicketStatus;
  query?: string;
  isSearchMode?: boolean;
  onSelectStatus: (status?: TicketStatus) => void;
  onEnterSearchQuery: (query: string) => void;
}

const classes = {
  controlsRoot: css({
    display: "flex",
    "& .ant-input-affix-wrapper": {
      minHeight: "32px",
    },
  }),
};

const PartnerWithUsRequestsPageControls: React.FC<
  IPartnerWithUsRequestsPageControlsProps
> = (props) => {
  const { disabled, status, isSearchMode, onSelectStatus } = props;

  return (
    <Space direction="vertical" size="middle">
      <div className={classes.controlsRoot}>
        <Space wrap style={{ flex: 1, marginRight: "16px" }}>
          <SelectTicketStatus
            showAll
            status={status}
            disabled={disabled || isSearchMode}
            onChange={onSelectStatus}
            style={{ width: appDimensions.optionsWidth }}
          />
        </Space>
      </div>
    </Space>
  );
};

export default PartnerWithUsRequestsPageControls;
