import { css, cx } from "@emotion/css";
import { Col, Row, Space, Typography } from "antd";
import React from "react";
import ScrollContainer from "../utils/ScrollContainer";
import { appClasses } from "../utils/theme";
import AnalysisBlockItemCountStats from "./AnalysisBlockItemCountStats";
import AnalysisControls from "./AnalysisControls";
import AnalysisDeliveryCostStats from "./AnalysisDeliveryCostStats";
import AnalysisDriversTable from "./AnalysisDriversTable";
import AnalysisDriverStats from "./AnalysisDriverStats";
import AnalysisOrderItemCategoryStats from "./AnalysisOrderItemCategoryStats";
import AnalysisOrderItemCountStats from "./AnalysisOrderItemsCountStats";
import AnalysisOrderItemsGraphStats from "./AnalysisOrderItemsGraphStats";
import AnalysisPaymentMethodCountStats from "./AnalysisPaymentMethodCountStats";
import {
  useAnalysisControls,
  useFetchAnalysisBlockItemStats,
  useFetchAnalysisDeliveryCostStats,
  useFetchAnalysisDriverList,
  useFetchAnalysisDriverStats,
  useFetchAnalysisOrderItemCategoryStats,
  useFetchAnalysisOrderItemStats,
  useFetchAnalysisPaymentMethodStats,
} from "./hooks";

const classes = {
  header: css({
    marginTop: 0,
  }),
  content: css({
    paddingBottom: 32,
  }),
};

const AnalysisItem: React.FC<{
  children: React.ReactNode;
  title: string;
  withScrollContainer?: boolean;
}> = (props) => {
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        backgroundColor: "#f0f0f0",
        padding: "8px",
        borderRadius: 4,
      }}
    >
      <Typography.Title level={5}>{props.title}</Typography.Title>
      {props.withScrollContainer ? (
        <ScrollContainer style={{ height: "360px" }}>
          {props.children}
        </ScrollContainer>
      ) : (
        props.children
      )}
    </Space>
  );
};

const AnalysisMainStats: React.FC<{}> = () => {
  const controls = useAnalysisControls();
  const drivers = useFetchAnalysisDriverList(controls.state);
  const driverStats = useFetchAnalysisDriverStats(controls.state);
  const orderItemStats = useFetchAnalysisOrderItemStats(controls.state);
  const blockItemStats = useFetchAnalysisBlockItemStats(controls.state);
  const paymentMethodStats = useFetchAnalysisPaymentMethodStats(controls.state);
  const orderItemCatStats = useFetchAnalysisOrderItemCategoryStats(
    controls.state
  );
  const deliveryCostStats = useFetchAnalysisDeliveryCostStats(controls.state);

  const driversNode = drivers.returnNode || (
    <AnalysisDriversTable loading={drivers.loading} data={drivers.data || []} />
  );
  const driverStatsNode = driverStats.returnNode || (
    <AnalysisDriverStats stats={driverStats.assertData()} />
  );
  const blockItemStatsNode = blockItemStats.returnNode || (
    <AnalysisBlockItemCountStats stats={blockItemStats.assertData()} />
  );
  const paymentMethodStatsNode = paymentMethodStats.returnNode || (
    <AnalysisPaymentMethodCountStats stats={paymentMethodStats.assertData()} />
  );
  const orderItemCatStatsNode = orderItemCatStats.returnNode || (
    <AnalysisOrderItemCategoryStats stats={orderItemCatStats.assertData()} />
  );
  const deliveryCostStatsNode = deliveryCostStats.returnNode || (
    <AnalysisDeliveryCostStats stats={deliveryCostStats.assertData()} />
  );
  const orderItemGraphStatsNode = orderItemStats.returnNode || (
    <AnalysisOrderItemsGraphStats stats={orderItemStats.assertData()} />
  );
  const orderItemCountStatsNode = orderItemStats.returnNode || (
    <AnalysisOrderItemCountStats stats={orderItemStats.assertData()} />
  );

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <AnalysisControls controls={controls} />
      </div>
      <div className={cx(appClasses.pageContent, classes.content)}>
        <Row wrap gutter={[24, 24]}>
          <Col span={12}>
            <AnalysisItem withScrollContainer title="Drivers">
              {driversNode}
            </AnalysisItem>
          </Col>
          <Col span={12}>
            <AnalysisItem title="Driver Stats">{driverStatsNode}</AnalysisItem>
          </Col>
          <Col span={12}>
            <AnalysisItem title="Block Item Stats">
              {blockItemStatsNode}
            </AnalysisItem>
          </Col>
          <Col span={12}>
            <AnalysisItem title="Order Item Graph Stats">
              {orderItemGraphStatsNode}
            </AnalysisItem>
          </Col>
          <Col span={12}>
            <AnalysisItem title="Order Item Count Stats">
              {orderItemCountStatsNode}
            </AnalysisItem>
          </Col>
          <Col span={12}>
            <AnalysisItem title="Order Item Category Stats">
              {orderItemCatStatsNode}
            </AnalysisItem>
          </Col>
          <Col span={12}>
            <AnalysisItem title="Delivery Cost Stats">
              {deliveryCostStatsNode}
            </AnalysisItem>
          </Col>
          <Col span={12}>
            <AnalysisItem title="Payment Method Stats">
              {paymentMethodStatsNode}
            </AnalysisItem>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AnalysisMainStats;
