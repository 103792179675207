import { css } from "@emotion/css";
import { Divider, List } from "antd";
import React from "react";
import { IGroupPolicy } from "../../definitions/group-policy";
import ListSkeleton from "../utils/ListSkeleton";
import PageNothingFound from "../utils/PageNothingFound";
import PolicyItemContainer, {
  IPolicyItemContainerRenderFnProps,
} from "./PolicyItemContainer";
import PolicyListItem from "./PolicyListItem";

export interface IPolicyListProps {
  loading?: boolean;
  policies: Array<IGroupPolicy>;
  onEdit: (id: string) => void;
  onReloadPage: () => void;
}

const PolicyList: React.FC<IPolicyListProps> = (props) => {
  const { loading, policies, onEdit, onReloadPage } = props;

  if (loading) {
    return <ListSkeleton />;
  }

  if (policies.length === 0) {
    return <PageNothingFound />;
  }

  return (
    <List
      split
      bordered
      loading={loading}
      itemLayout="horizontal"
      dataSource={policies}
      renderItem={(item, index) => {
        const renderPolicyListItem = (
          itemProps: IPolicyItemContainerRenderFnProps
        ) => {
          return (
            <PolicyListItem
              {...itemProps}
              policy={item}
              onEdit={() => onEdit(item.GroupPolicyId)}
              onCompleteUpdatePolicy={onReloadPage}
              onCompleteDeletePolicy={onReloadPage}
              className={
                index === 0
                  ? css({ marginTop: "24px" })
                  : index === policies.length - 1
                  ? css({ marginBottom: "24px" })
                  : undefined
              }
            />
          );
        };

        return (
          <React.Fragment>
            {index > 0 && <Divider />}
            <PolicyItemContainer
              key={item.GroupPolicyId}
              policy={item}
              onCompleteUpdate={onReloadPage}
              render={renderPolicyListItem}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export default PolicyList;
