import { css, cx } from "@emotion/css";
import { Alert, Button, Col, Form, Input, Row } from "antd";
import { CountryData } from "intl-tel-input";
import React from "react";
import * as yup from "yup";
import { messages } from "../../definitions/messages";
import { ICreateAdminUserInput, userConstants } from "../../definitions/user";
import AddressInput from "../forms/AddressInput";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import PhoneInput from "../forms/PhoneInput";
import { formValidationSchemas } from "../forms/validation";
import useFormHelpers from "../hooks/useFormHelpers";

const getValidationSchema = (omitPassword?: boolean) => {
  const objectContent = {
    firstName: formValidationSchemas.strRequired,
    lastName: formValidationSchemas.strRequired,
    emailAddress: formValidationSchemas.emailRequired,
    phoneNumber: formValidationSchemas.requiredField,
    confirmPassword: formValidationSchemas.str,
    password: formValidationSchemas.str,
    address: formValidationSchemas.addressRequired,
  };

  if (!omitPassword) {
    objectContent.password = formValidationSchemas.passwordRequired;
    objectContent.confirmPassword =
      formValidationSchemas.confirmPasswordRequired;
  }

  const validationSchema = yup.object().shape(objectContent);
  return validationSchema;
};

export type ISignupUserFormValues = ICreateAdminUserInput;

interface ISignupUserFormInternalValues extends ISignupUserFormValues {
  confirmPassword: string;
}

export interface ISignupUserProps {
  data?: ISignupUserFormValues;
  onSubmit: (values: ISignupUserFormValues) => void | Promise<void>;
  isSubmitting?: boolean;
  omitPassword?: boolean;
  omitBtns?: boolean;
  btnText?: string;
  error?: string;
  className?: string;
}

const initialValues: ISignupUserFormInternalValues = {
  emailAddress: "",
  firstName: "",
  lastName: "",
  countryCode: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  address: {
    street: "",
    city: "",
    state: "",
    country: "",
  },
};

const SignupUserForm: React.FC<ISignupUserProps> = (props) => {
  const {
    isSubmitting,
    data,
    error,
    omitPassword,
    omitBtns,
    btnText,
    className,
    onSubmit,
  } = props;

  const validationSchema = React.useMemo(
    () => getValidationSchema(omitPassword),
    [omitPassword]
  );

  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      initialValues: {
        ...initialValues,
        confirmPassword: data?.password || "",
        ...data,
      },
      onSubmit: (data) => {
        onSubmit({
          emailAddress: data.emailAddress,
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          phoneNumber: data.phoneNumber,
          countryCode: data.countryCode,
          address: data.address,
        });
      },
    },
  });

  const firstNameNode = (
    <Form.Item
      required
      label="First Name"
      help={
        formik.touched?.firstName &&
        formik.errors?.firstName && (
          <FunErrorMessages message={formik.errors.firstName}>
            <FormError error={formik.errors.firstName} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        autoComplete="given-name"
        name="firstName"
        value={formik.values.firstName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter your first name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const lastNameNode = (
    <Form.Item
      required
      label="Last Name"
      help={
        formik.touched?.lastName &&
        formik.errors?.lastName && (
          <FunErrorMessages message={formik.errors.lastName}>
            <FormError error={formik.errors.lastName} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        autoComplete="given-name"
        name="lastName"
        value={formik.values.lastName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter your last name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const emailNode = (
    <Form.Item
      required
      label="Email Address"
      help={
        formik.touched?.emailAddress &&
        formik.errors?.emailAddress && (
          <FunErrorMessages message={formik.errors.emailAddress}>
            <FormError error={formik.errors.emailAddress} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        autoComplete="email"
        name="emailAddress"
        value={formik.values.emailAddress}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        disabled={isSubmitting}
        placeholder="Enter your email address"
      />
    </Form.Item>
  );

  const passwordNode = !omitPassword ? (
    <Form.Item
      required
      label="Password"
      help={
        formik.touched?.password && formik.errors?.password ? (
          <FunErrorMessages message={formik.errors?.password}>
            <FormError error={formik.errors?.password} />
          </FunErrorMessages>
        ) : (
          messages.passwordExtraInfo
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.Password
        visibilityToggle
        autoComplete="new-password"
        name="password"
        value={formik.values.password}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        disabled={isSubmitting}
        placeholder="Enter new password"
        maxLength={userConstants.maxPasswordLength}
      />
    </Form.Item>
  ) : null;

  const confirmPasswordNode = (
    <Form.Item
      required
      label="Confirm Password"
      help={
        formik.touched?.confirmPassword &&
        formik.errors?.confirmPassword && (
          <FunErrorMessages message={formik.errors.confirmPassword}>
            <FormError error={formik.errors.confirmPassword} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.Password
        visibilityToggle
        autoComplete="new-password"
        name="confirmPassword"
        value={formik.values.confirmPassword}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        disabled={isSubmitting}
        placeholder="Re-enter your new password"
        maxLength={userConstants.maxPasswordLength}
      />
    </Form.Item>
  );

  const phoneNodeOnChange = React.useCallback(
    (val, countryCode?: CountryData) => {
      formik.setFieldValue("phoneNumber", val);
      formik.setFieldValue("countryCode", countryCode?.iso2);
    },
    [formik]
  );

  const phoneCountryCodeNodeOnChange = React.useCallback(
    (code?: CountryData) => {
      formik.setFieldValue("countryCode", code?.iso2, false);
    },
    [formik]
  );

  const phoneNode = (
    <Form.Item
      required
      label="Phone Number"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.phoneNumber &&
        formik.errors?.phoneNumber && (
          <FunErrorMessages message={formik.errors.phoneNumber}>
            <FormError error={formik.errors.phoneNumber} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <PhoneInput
        value={{
          code: formik.values.countryCode,
          number: formik.values.phoneNumber,
        }}
        onChangePhoneNumber={phoneNodeOnChange}
        onChangeCountryCode={phoneCountryCodeNodeOnChange}
        onError={(errorMsg) => formik.setFieldError("phoneNumber", errorMsg)}
        placeholder="Enter your phone number"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const addressNode = (
    <AddressInput
      errors={formik.errors?.address || {}}
      setFieldValue={formik.setFieldValue}
      touched={formik.touched?.address || {}}
      values={formik.values.address}
      fieldName="address"
      disabled={isSubmitting}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
    />
  );

  const actionsNode = (
    <Form.Item>
      <div className={css({ display: "flex", justifyContent: "flex-end" })}>
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          {btnText || "Next"}
        </Button>
      </div>
    </Form.Item>
  );

  return (
    <div className={cx(formClasses.body, className)}>
      <div className={formClasses.bodyInner}>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>{firstNameNode}</Col>
            <Col span={12}>{lastNameNode}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>{emailNode}</Col>
            <Col span={12}>{phoneNode}</Col>
          </Row>
          {addressNode}
          {!omitPassword && passwordNode}
          {!omitPassword && confirmPasswordNode}
          {!omitBtns && actionsNode}
        </form>
      </div>
    </div>
  );
};

export default React.memo(SignupUserForm);
