import { css, cx } from "@emotion/css";
import { Alert, Button, Form, Input, Space, Typography } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import FormError from "../../forms/FormError";
import { formClasses } from "../../forms/formStyleUtils";
import FunErrorMessages from "../../forms/FunErrorMessages";
import { formValidationSchemas } from "../../forms/validation";

export interface IDeleteReasonFormValues {
  reason: string;
}

export interface IDeleteReasonFormProps {
  error?: string;
  isSubmitting?: boolean;
  name: string;
  onSave: (value: IDeleteReasonFormValues) => void | Promise<void>;
  onCancel: () => void;
}

const DeleteReasonForm: React.FC<IDeleteReasonFormProps> = (props) => {
  const { error, isSubmitting, name, onSave, onCancel } = props;
  const formik = useFormik({
    initialValues: { reason: "" },
    validationSchema: yup.object().shape({
      reason: formValidationSchemas.strRequired,
    }),
    onSubmit: (data) => {
      onSave({
        reason: data.reason,
      });
    },
  });

  const reasonNode = (
    <Form.Item
      required
      label="Reason"
      help={
        formik.touched?.reason &&
        formik.errors?.reason && (
          <FunErrorMessages message={formik.errors.reason}>
            <FormError error={formik.errors.reason} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        autoFocus
        name="reason"
        value={formik.values.reason}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter your reason"
        disabled={isSubmitting}
        autoSize={{
          minRows: 2,
          maxRows: 4,
        }}
      />
    </Form.Item>
  );

  return (
    <Space
      direction="vertical"
      size="large"
      className={cx(formClasses.body, css({ padding: 0 }))}
    >
      <Typography.Title level={5}>{name}</Typography.Title>
      {error && <Alert message={error} type="error" />}
      <form onSubmit={formik.handleSubmit}>
        {reasonNode}
        <Form.Item
          className={css({
            marginTop: "32px !important",
            marginBottom: "0px !important",
          })}
        >
          <Space size={"middle"}>
            <Button
              danger
              htmlType="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Delete
            </Button>
          </Space>
        </Form.Item>
      </form>
    </Space>
  );
};

export default DeleteReasonForm;
