import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import CompanyAPI from "../../api/endpoints/company";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { ICompany } from "../../definitions/company";
import { messages } from "../../definitions/messages";
import { wrapWithMessageError } from "../utils/utils";
import DeleteCompanyReasonForm, {
  IDeleteCompanyReasonFormValues,
} from "./DeleteCompanyReasonForm";

export interface IDeleteCompanyReasonFormContainerProps {
  company: ICompany;
  onCompleteDelete: () => void;
  onCancel: () => void;
}

const DeleteCompanyFormContainer: React.FC<
  IDeleteCompanyReasonFormContainerProps
> = (props) => {
  const { company, onCancel, onCompleteDelete } = props;
  const onSubmit = React.useCallback(
    async (data: IDeleteCompanyReasonFormValues) => {
      wrapWithMessageError(async () => {
        const result = await CompanyAPI.deleteCompany({
          id: company.CompanyId,
          reason: data.reason,
        });

        message.success(
          getEndpointResultMessage(result) || messages.companyDeleteSuccess
        );
        onCompleteDelete();
      });
    },
    [company.CompanyId, onCompleteDelete]
  );

  const { loading, error, run } = useRequest(onSubmit, { manual: true });
  return (
    <DeleteCompanyReasonForm
      isSubmitting={loading}
      error={error?.message}
      onSave={run}
      company={company}
      onCancel={onCancel}
    />
  );
};

export default React.memo(DeleteCompanyFormContainer);
