import { cx } from "@emotion/css";
import { Alert, Button, Divider, Form, Input, Typography } from "antd";
import { CountryData } from "intl-tel-input";
import React from "react";
import * as yup from "yup";
import { ICompanyInput } from "../../definitions/company";
import { messages } from "../../definitions/messages";
import AddressInput from "../forms/AddressInput";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import PhoneInput from "../forms/PhoneInput";
import { formValidationSchemas } from "../forms/validation";
import useFormHelpers from "../hooks/useFormHelpers";

const getValidationSchema = (omitPrimaryAdmin?: boolean) => {
  const schemaContent = {
    name: formValidationSchemas.strRequired,
    description: formValidationSchemas.str,
    caC_Number: formValidationSchemas.strRequired,
    companyAcronym: formValidationSchemas.strRequired,
    websiteUrl: formValidationSchemas.str.nullable(),
    primaryAddress: formValidationSchemas.addressRequired,
    primaryAdmin: yup.mixed(),
  };

  if (!omitPrimaryAdmin) {
    schemaContent.primaryAdmin = yup.object().shape({
      firstName: formValidationSchemas.strRequired,
      lastName: formValidationSchemas.strRequired,
      emailAddress: formValidationSchemas.emailRequired,
      phoneNumber: formValidationSchemas.requiredField,
      countryCode: formValidationSchemas.requiredField,
    });
  }

  const validationSchema = yup.object().shape(schemaContent);
  return validationSchema;
};

export type ICompanyFormValues = ICompanyInput;

export interface ICompanyFormProps {
  error?: string;
  isSubmitting?: boolean;
  data?: ICompanyFormValues;
  omitPrimaryAdmin?: boolean;
  onSubmit: (values: ICompanyFormValues) => void | Promise<void>;
}

const CompanyForm: React.FC<ICompanyFormProps> = (props) => {
  const { isSubmitting, data, error, omitPrimaryAdmin, onSubmit } = props;
  const validationSchema = React.useMemo(
    () => getValidationSchema(omitPrimaryAdmin),
    [omitPrimaryAdmin]
  );

  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      initialValues:
        data ||
        ({
          name: "",
          description: "",
          caC_Number: "",
          companyAcronym: "",
          websiteUrl: "",
          primaryAddress: {
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
          },
          primaryAdmin: {
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
            countryCode: "NG",
          },
        } as ICompanyFormValues),
      onSubmit: (data) => {
        onSubmit(data);
      },
    },
  });

  const nameNode = (
    <Form.Item
      required
      label="Company Name"
      help={
        formik.touched?.name &&
        formik.errors?.name && (
          <FunErrorMessages message={formik.errors.name}>
            <FormError error={formik.errors.name} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="name"
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter company name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const descriptionNode = (
    <Form.Item
      label="Company Description"
      help={
        formik.touched?.description &&
        formik.errors?.description && (
          <FunErrorMessages message={formik.errors.description}>
            <FormError error={formik.errors.description} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        name="description"
        value={formik.values.description}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter company description"
        disabled={isSubmitting}
        autoSize={{ minRows: 2 }}
      />
    </Form.Item>
  );

  const cacNumberNode = (
    <Form.Item
      required
      label="CAC Number"
      help={
        formik.touched?.caC_Number &&
        formik.errors?.caC_Number && (
          <FunErrorMessages message={formik.errors.caC_Number}>
            <FormError error={formik.errors.caC_Number} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="caC_Number"
        value={formik.values.caC_Number}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter company CAC number"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const acronymNode = (
    <Form.Item
      required
      label="Company Acronym"
      help={
        formik.touched?.companyAcronym &&
        formik.errors?.companyAcronym && (
          <FunErrorMessages message={formik.errors.companyAcronym}>
            <FormError error={formik.errors.companyAcronym} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="companyAcronym"
        value={formik.values.companyAcronym}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter company acronym"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const websiteNode = (
    <Form.Item
      label="Website"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.websiteUrl &&
        formik.errors?.websiteUrl && (
          <FunErrorMessages message={formik.errors?.websiteUrl}>
            <FormError error={formik.errors?.websiteUrl} />
          </FunErrorMessages>
        )
      }
      extra={messages.URLExample}
      style={{ width: "100%" }}
    >
      <Input
        name="websiteUrl"
        value={formik.values?.websiteUrl}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Company website URL"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const addressNode = (
    <AddressInput
      errors={formik.errors?.primaryAddress || {}}
      setFieldValue={formik.setFieldValue}
      touched={formik.touched?.primaryAddress || {}}
      values={formik.values.primaryAddress}
      fieldName="primaryAddress"
      disabled={isSubmitting}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
    />
  );

  const firstNameNode = (
    <Form.Item
      required
      label="Admin's First Name"
      help={
        formik.touched?.primaryAdmin?.firstName &&
        formik.errors?.primaryAdmin?.firstName && (
          <FunErrorMessages message={formik.errors?.primaryAdmin?.firstName}>
            <FormError error={formik.errors?.primaryAdmin?.firstName} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="primaryAdmin.firstName"
        value={formik.values?.primaryAdmin?.firstName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter admin's first name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const lastNameNode = (
    <Form.Item
      required
      label="Admin's Last Name"
      help={
        formik.touched?.primaryAdmin?.lastName &&
        formik.errors?.primaryAdmin?.lastName && (
          <FunErrorMessages message={formik.errors?.primaryAdmin?.lastName}>
            <FormError error={formik.errors?.primaryAdmin?.lastName} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="primaryAdmin.lastName"
        value={formik.values?.primaryAdmin?.lastName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Enter admin's last name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const emailNode = (
    <Form.Item
      required
      label="Admin's Email Address"
      help={
        formik.touched?.primaryAdmin?.emailAddress &&
        formik.errors?.primaryAdmin?.emailAddress && (
          <FunErrorMessages message={formik.errors.primaryAdmin?.emailAddress}>
            <FormError error={formik.errors.primaryAdmin?.emailAddress} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="primaryAdmin.emailAddress"
        value={formik.values.primaryAdmin?.emailAddress}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        disabled={isSubmitting}
        placeholder="Enter admin's email address"
      />
    </Form.Item>
  );

  const phoneNodeOnChange = React.useCallback(
    (val, countryCode?: CountryData) => {
      formik.setFieldValue("primaryAdmin.phoneNumber", val);
      formik.setFieldValue("primaryAdmin.countryCode", countryCode?.iso2);
    },
    [formik]
  );

  const phoneCountryCodeNodeOnChange = React.useCallback(
    (code?: CountryData) => {
      formik.setFieldValue("primaryAdmin.countryCode", code?.iso2, false);
    },
    [formik]
  );

  const phoneNodeOnError = React.useCallback(
    (errorMsg) => formik.setFieldError("primaryAdmin.phoneNumber", errorMsg),
    [formik]
  );

  const phoneNode = (
    <Form.Item
      required
      label="Admin's Phone Number"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.primaryAdmin?.phoneNumber &&
        formik.errors?.primaryAdmin?.phoneNumber && (
          <FunErrorMessages message={formik.errors.primaryAdmin?.phoneNumber}>
            <FormError error={formik.errors.primaryAdmin?.phoneNumber} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <PhoneInput
        value={{
          code: formik.values?.primaryAdmin?.countryCode,
          number: formik.values?.primaryAdmin?.phoneNumber,
        }}
        onChangePhoneNumber={phoneNodeOnChange}
        onChangeCountryCode={phoneCountryCodeNodeOnChange}
        onError={phoneNodeOnError}
        placeholder="Enter admin's phone number"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const primaryAdminNodes = (
    <React.Fragment>
      <Divider orientation="left">Primary Admin</Divider>
      {firstNameNode}
      {lastNameNode}
      {emailNode}
      {phoneNode}
    </React.Fragment>
  );

  return (
    <div className={cx(formClasses.body)}>
      <div className={formClasses.bodyInner}>
        <Form.Item>
          <Typography.Title level={4}>Company Form</Typography.Title>
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        <form onSubmit={formik.handleSubmit}>
          {nameNode}
          {descriptionNode}
          {cacNumberNode}
          {acronymNode}
          {websiteNode}
          {addressNode}
          {!omitPrimaryAdmin && primaryAdminNodes}
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </Button>
          </Form.Item>
        </form>
      </div>
    </div>
  );
};

export default React.memo(CompanyForm);
