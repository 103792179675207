import { css } from "@emotion/css";
import { Skeleton, Space } from "antd";
import React from "react";

export interface IImageSkeletonProps {
  count?: number;
  width?: number;
  height?: number;
}

const MAX_IMAGES = 2;

const ImageSkeleton: React.FC<IImageSkeletonProps> = (props) => {
  const { count, width, height } = props;
  return (
    <Space
      wrap
      size={["middle", "middle"]}
      className={css({
        "& .ant-skeleton-image": { width, height, borderRadius: "4px" },
      })}
    >
      {Array(count || MAX_IMAGES)
        .fill(1)
        .map((item, index) => (
          <Skeleton.Image key={index} />
        ))}
    </Space>
  );
};

export default ImageSkeleton;
