import { css } from "@emotion/css";
import { Space, SpaceProps, Typography } from "antd";
import React from "react";

export interface ILabeledNodeProps {
  nodeIsText?: boolean;
  emptyNodeText?: string;
  node?: React.ReactNode;
  label: string;
  direction: SpaceProps["direction"];
}

const classes = {
  root: css({
    lineHeight: "1.25 !important",
  }),
};

const LabeledNode: React.FC<ILabeledNodeProps> = (props) => {
  const { label, node, direction, nodeIsText, emptyNodeText } = props;
  let internalNodeContent: React.ReactNode = null;
  const fontSize = 14;

  if (node) {
    internalNodeContent = nodeIsText ? (
      <Typography.Text style={{ fontSize }}>{node}</Typography.Text>
    ) : (
      node
    );
  } else {
    internalNodeContent = (
      <Typography.Text type="secondary" style={{ fontSize }}>
        {emptyNodeText || "N/A"}
      </Typography.Text>
    );
  }

  return (
    <Space
      direction={direction}
      size={direction === "vertical" ? fontSize / 3 : "small"}
      className={classes.root}
    >
      <Typography.Text strong style={{ fontSize }} type="secondary">
        {label}:{" "}
      </Typography.Text>
      {internalNodeContent}
    </Space>
  );
};

export default LabeledNode;
