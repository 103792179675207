import ImageAPI from "../../api/endpoints/image";

export interface IRemoveUnusedImagesProps {
  isGroupImage?: boolean;
  id: string;
}

export async function removeUnusedImages(props: IRemoveUnusedImagesProps) {
  ImageAPI.deleteImages({ isGroupImage: true, id: props.id }).catch((err) => {
    console.log("Error removing unused image", err);
  });
}
