import { css } from "@emotion/css";
import { Form, Input } from "antd";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import { IAddress } from "../../definitions/address";
import { ICountryInfo } from "../../definitions/countries";
import CountryInput from "./CountryInput";
import CountryStatesInput from "./CountryStatesInput";
import DisabledSelect from "./DisabledSelect";
import FormError from "./FormError";
import FunErrorMessages from "./FunErrorMessages";
import StateCitiesInput from "./StateCitiesInput";

export interface IAddressInputProps {
  disabled?: boolean;
  fieldName: string;
  values: IAddress;
  touched: FormikTouched<IAddress>;
  errors: FormikErrors<IAddress>;
  handleBlur?: React.FocusEventHandler;
  handleChange?: React.ChangeEventHandler;
  setFieldValue: (field: string, value: any) => void;
  externalSetCountry?: (country: ICountryInfo) => void;
}

const classes = {
  row: css({}),
  leftCol: css({
    width: "50%",
    paddingRight: "8px",
    boxSizing: "border-box",
    display: "inline-block",
  }),
  rightCol: css({
    width: "50%",
    paddingLeft: "8px",
    boxSizing: "border-box",
    display: "inline-block",
  }),
};

const AddressInput: React.FC<IAddressInputProps> = (props) => {
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    externalSetCountry,
    disabled,
    values,
    touched,
    errors,
    fieldName,
  } = props;

  const [country, setCountry] = React.useState<ICountryInfo | null>(null);

  const streetNode = (
    <Form.Item
      required
      label="Street Address"
      help={
        touched?.street &&
        errors?.street && (
          <FunErrorMessages message={errors.street}>
            <FormError error={errors.street} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        autoSize={{
          minRows: 2,
          maxRows: 4,
        }}
        name={`${fieldName}.street`}
        value={values.street}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
        placeholder="Company street address"
      />
    </Form.Item>
  );

  const cityNode = (
    <Form.Item
      required
      label="City"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        touched?.city &&
        errors?.city && (
          <FunErrorMessages message={errors.city}>
            <FormError error={errors.city} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      {values.country && country && values.state ? (
        <StateCitiesInput
          countryCode={country.Code}
          state={values.state}
          value={values.city}
          disabled={disabled}
          onChange={(val) => {
            setFieldValue(`${fieldName}.zipCode`, "");
            setFieldValue(`${fieldName}.city`, val);
          }}
        />
      ) : (
        <DisabledSelect placeholder="Select country to select state" />
      )}
    </Form.Item>
  );

  const stateNode = (
    <Form.Item
      required
      label="State"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        touched?.state &&
        errors?.state && (
          <FunErrorMessages message={errors.state}>
            <FormError error={errors.state} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      {values.country && country ? (
        <CountryStatesInput
          countryCode={country.Code}
          value={values.state}
          disabled={disabled}
          onChange={(val) => {
            setFieldValue(`${fieldName}.zipCode`, "");
            setFieldValue(`${fieldName}.city`, "");
            setFieldValue(`${fieldName}.state`, val);
          }}
        />
      ) : (
        <DisabledSelect placeholder="Select country and state, to select city" />
      )}
    </Form.Item>
  );

  const zipcodeNode = (
    <Form.Item
      label="Zipcode"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        touched?.zipCode &&
        errors?.zipCode && (
          <FunErrorMessages message={errors.zipCode}>
            <FormError error={errors.zipCode} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <Input
        name={`${fieldName}.zipCode`}
        value={values.zipCode}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Zipcode"
        disabled={disabled}
      />
    </Form.Item>
  );

  const countryNode = (
    <Form.Item
      required
      label="Country"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        touched?.country &&
        errors?.country && (
          <FunErrorMessages message={errors.country}>
            <FormError error={errors.country} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <CountryInput
        value={values.country}
        disabled={disabled}
        onChange={(val) => {
          setFieldValue(`${fieldName}.zipCode`, "");
          setFieldValue(`${fieldName}.city`, "");
          setFieldValue(`${fieldName}.state`, "");
          setFieldValue(`${fieldName}.country`, val.Name);
          setCountry(val);

          if (externalSetCountry) {
            externalSetCountry(val);
          }
        }}
        onInit={(val) => {
          setCountry(val);

          if (externalSetCountry) {
            externalSetCountry(val);
          }
        }}
      />
    </Form.Item>
  );

  return (
    <div>
      {streetNode}
      <div className={classes.row}>
        <div className={classes.leftCol}>{countryNode}</div>
        <div className={classes.rightCol}>{stateNode}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.leftCol}>{cityNode}</div>
        <div className={classes.rightCol}>{zipcodeNode}</div>
      </div>
    </div>
  );
};

export default React.memo(AddressInput);
