import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { messages } from "../../definitions/messages";
import PageNothingFound, { IPageNothingFoundProps } from "./PageNothingFound";

export interface IPageLoadingProps extends IPageNothingFoundProps {}

const PageLoading: React.FC<IPageLoadingProps> = (props) => {
  const { messageText, image, imageStyle } = props;
  return (
    <PageNothingFound
      {...props}
      messageText={messageText || messages.loading}
      image={image || <LoadingOutlined style={{ fontSize: 32 }} />}
      imageStyle={imageStyle || { display: "flex", alignItems: "center" }}
    />
  );
};

export default PageLoading;
