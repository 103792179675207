import React from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { appBasePaths } from "../../definitions/paths";
import ForgotPasswordContainer from "../user/ForgotPasswordContainer";
import LoginContainer from "../user/LoginContainer";
import ResetPasswordContainer from "../user/ResetPasswordContainer";
import SignupContainer from "../user/SignupContainer";
import BannerWithContent from "../utils/BannerWithContent";
import MoveBotApp from "./MoveBotApp";

const AppRoutes: React.FC<{}> = (props) => {
  return (
    <Switch>
      <Route path={appBasePaths.signup}>
        <BannerWithContent>
          <SignupContainer />
        </BannerWithContent>
      </Route>
      <Route path={appBasePaths.login}>
        <BannerWithContent>
          <LoginContainer />
        </BannerWithContent>
      </Route>
      <Route path={appBasePaths.forgotPassword}>
        <BannerWithContent>
          <ForgotPasswordContainer />
        </BannerWithContent>
      </Route>
      <Route path={appBasePaths.changePassword}>
        <BannerWithContent>
          <ResetPasswordContainer />
        </BannerWithContent>
      </Route>
      {/* <Route path={appBasePaths.confirmPhoneNumber}>
        <BannerWithContent>
          <ConfirmPhoneNumberContainer />
        </BannerWithContent>
      </Route> */}
      <Route path={appBasePaths.app}>
        <MoveBotApp />
      </Route>
      <Route
        exact
        path="*"
        render={() => {
          return <Redirect to={appBasePaths.login} />;
        }}
      />
    </Switch>
  );
};

export default AppRoutes;
