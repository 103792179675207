import { css } from "@emotion/css";
import { Space, Typography, Button, Input } from "antd";
import React from "react";
import { TicketPriority } from "../../definitions/ticket";
import { TicketStatus } from "../../definitions/utils";
import {
  IPageViewToggleButtonsProps,
  PageViewToggleButtons,
} from "../utils/PageViewMode";
import { appColors, appDimensions } from "../utils/theme";
import SelectTicketPriority from "./SelectTicketPriority";
import SelectTicketStatus from "./SelectTicketStatus";
import TicketsSelectOrganization from "./TicketsSelectOrganization";

export interface ITicketsPageControlsProps extends IPageViewToggleButtonsProps {
  disabled?: boolean;
  orgId?: string;
  status?: TicketStatus;
  priority?: TicketPriority;
  query?: string;
  isSearchMode?: boolean;
  onCreateTicket: () => void;
  onSelectStatus: (status?: TicketStatus) => void;
  onSelectPriority: (status?: TicketPriority) => void;
  onSelectOrganization: (orgId?: string) => void;
  onEnterSearchQuery: (query: string) => void;
}

const classes = {
  controlsRoot: css({
    display: "flex",
    alignItems: "flex-start",
    "& .ant-input-affix-wrapper": {
      minHeight: "32px",
    },
  }),
};

const TicketsPageControls: React.FC<ITicketsPageControlsProps> = (props) => {
  const {
    disabled,
    orgId,
    status,
    priority,
    mode,
    isSearchMode,
    setMode,
    onCreateTicket,
    onSelectOrganization,
    onSelectPriority,
    onSelectStatus,
    onEnterSearchQuery,
  } = props;

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Typography.Title level={3}>Tickets</Typography.Title>
      <div className={classes.controlsRoot}>
        <Space wrap style={{ flex: 1, marginRight: "16px" }}>
          <Input.Search
            allowClear
            disabled={disabled}
            placeholder="Enter search text"
            onSearch={onEnterSearchQuery}
            style={{ width: appDimensions.searchInputWidth }}
          />
          <TicketsSelectOrganization
            orgId={orgId}
            disabled={disabled}
            onChange={onSelectOrganization}
            style={{ width: appDimensions.optionsWidth }}
          />
          <SelectTicketStatus
            showAll
            status={status}
            disabled={disabled || isSearchMode}
            onChange={onSelectStatus}
            style={{ width: appDimensions.optionsWidth }}
          />
          <SelectTicketPriority
            priority={priority}
            disabled={disabled || isSearchMode}
            onChange={onSelectPriority}
            style={{ width: appDimensions.optionsWidth }}
          />
        </Space>
        <Space wrap size="middle">
          <PageViewToggleButtons mode={mode} setMode={setMode} />
          <Button
            type="primary"
            style={{ backgroundColor: appColors.primaryColor }}
            onClick={onCreateTicket}
          >
            Create Ticket
          </Button>
        </Space>
      </div>
    </Space>
  );
};

export default TicketsPageControls;
