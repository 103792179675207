import { LeftOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Alert, Button, Col, Form, Input, Row, Space } from "antd";
import { CountryData } from "intl-tel-input";
import React from "react";
import * as yup from "yup";
import { IAddress } from "../../definitions/address";
import AddressInput from "../forms/AddressInput";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import PhoneInput from "../forms/PhoneInput";
import { formValidationSchemas } from "../forms/validation";
import useFormHelpers from "../hooks/useFormHelpers";

const validationSchema = yup.object().shape({
  companyName: formValidationSchemas.strRequired,
  companyEmail: formValidationSchemas.emailRequired,
  companyPhoneNumber: formValidationSchemas.requiredField,
  address: formValidationSchemas.addressRequired,
  websiteURL: formValidationSchemas.str.nullable(),
  facebookURL: formValidationSchemas.str.nullable(),
  youtubeURL: formValidationSchemas.str.nullable(),
  instagramURL: formValidationSchemas.str.nullable(),
  twitterURL: formValidationSchemas.str.nullable(),
});

export interface IIncomingSignupCompanyFormValues {
  companyName: string;
  companyEmail: string;
  companyPhoneNumber: string;
  address: IAddress;
  websiteURL?: string;
  facebookURL?: string;
  youtubeURL?: string;
  instagramURL?: string;
  twitterUrl?: string;
}

export interface IOutgoingSignupCompanyFormValues {
  companyName: string;
  companyEmail: string;
  companyPhoneNumber: string;
  address: IAddress;
  countryCode: string;
  websiteURL?: string;
  facebookURL?: string;
  youtubeURL?: string;
  instagramURL?: string;
  twitterUrl?: string;
}

export interface ISignupCompanyFormProps {
  onSubmit: (values: IOutgoingSignupCompanyFormValues) => void | Promise<void>;
  onBack: (values: Partial<IOutgoingSignupCompanyFormValues>) => void;
  isSubmitting?: boolean;
  data?: Partial<IIncomingSignupCompanyFormValues>;
  error?: string;
  omitBackBtn?: boolean;
  saveBtnText?: string;
}

const initialValues: IOutgoingSignupCompanyFormValues = {
  companyName: "",
  companyEmail: "",
  companyPhoneNumber: "",
  address: {
    street: "",
    city: "",
    state: "",
    country: "",
  },
  countryCode: "",
};

const Signup: React.FC<ISignupCompanyFormProps> = (props) => {
  const {
    onSubmit,
    error,
    isSubmitting,
    omitBackBtn,
    saveBtnText,
    data,
    onBack,
  } = props;

  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      initialValues: {
        ...initialValues,
        ...data,
      },
      onSubmit: (data) => {
        onSubmit(data);
      },
    },
  });

  const companyNameNode = (
    <Form.Item
      required
      label="Company Name"
      help={
        formik.touched?.companyName &&
        formik.errors?.companyName && (
          <FunErrorMessages message={formik.errors.companyName}>
            <FormError error={formik.errors.companyName} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="companyName"
        value={formik.values.companyName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Company name"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const companyEmailNode = (
    <Form.Item
      required
      label="Company Email"
      help={
        formik.touched?.companyEmail &&
        formik.errors?.companyEmail && (
          <FunErrorMessages message={formik.errors.companyEmail}>
            <FormError error={formik.errors.companyEmail} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        name="companyEmail"
        value={formik.values.companyEmail}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Company email"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const phoneNodeOnChange = React.useCallback(
    (val, countryCode?: CountryData) => {
      formik.setFieldValue("companyPhoneNumber", val);
      formik.setFieldValue("countryCode", countryCode?.iso2);
    },
    [formik]
  );

  const phoneCountryCodeNodeOnChange = React.useCallback(
    (code?: CountryData) => {
      formik.setFieldValue("countryCode", code?.iso2, false);
    },
    [formik]
  );

  const companyPhoneNode = (
    <Form.Item
      required
      label="Company Phone Number"
      help={
        formik.touched?.companyPhoneNumber &&
        formik.errors?.companyPhoneNumber && (
          <FunErrorMessages message={formik.errors.companyPhoneNumber}>
            <FormError error={formik.errors.companyPhoneNumber} />
          </FunErrorMessages>
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <PhoneInput
        value={{
          code: formik.values.countryCode,
          number: formik.values.companyPhoneNumber,
        }}
        onChangePhoneNumber={phoneNodeOnChange}
        onChangeCountryCode={phoneCountryCodeNodeOnChange}
        onError={(errorMsg) =>
          formik.setFieldError("companyPhoneNumber", errorMsg)
        }
        placeholder="Company phone number"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const addressNode = (
    <AddressInput
      errors={formik.errors?.address || {}}
      setFieldValue={formik.setFieldValue}
      touched={formik.touched?.address || {}}
      values={formik.values.address}
      fieldName="address"
      disabled={isSubmitting}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
    />
  );

  const websiteNode = (
    <Form.Item
      label="Website"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.websiteURL &&
        formik.errors?.websiteURL && (
          <FunErrorMessages message={formik.errors.websiteURL}>
            <FormError error={formik.errors.websiteURL} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <Input
        name="websiteURL"
        value={formik.values.websiteURL}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Company website"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const facebookNode = (
    <Form.Item
      label="Facebook Handle"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.facebookURL &&
        formik.errors?.facebookURL && (
          <FunErrorMessages message={formik.errors.facebookURL}>
            <FormError error={formik.errors.facebookURL} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <Input
        name="facebookURL"
        value={formik.values.facebookURL}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Company Facebook handle"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const youtubeNode = (
    <Form.Item
      label="Youtube Handle"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.youtubeURL &&
        formik.errors?.youtubeURL && (
          <FunErrorMessages message={formik.errors.youtubeURL}>
            <FormError error={formik.errors.youtubeURL} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <Input
        name="youtubeURL"
        value={formik.values.youtubeURL}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Company Youtube handle"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const instagramNode = (
    <Form.Item
      label="Instagram Handle"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.instagramURL &&
        formik.errors?.instagramURL && (
          <FunErrorMessages message={formik.errors.instagramURL}>
            <FormError error={formik.errors.instagramURL} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <Input
        name="instagramURL"
        value={formik.values.instagramURL}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Company Instagram handle"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  const twitterNode = (
    <Form.Item
      label="Twitter Handle"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched?.twitterUrl &&
        formik.errors?.twitterUrl && (
          <FunErrorMessages message={formik.errors.twitterUrl}>
            <FormError error={formik.errors.twitterUrl} />
          </FunErrorMessages>
        )
      }
      style={{ width: "100%" }}
    >
      <Input
        name="twitterUrl"
        value={formik.values.twitterUrl}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Company Twitter handle"
        disabled={isSubmitting}
      />
    </Form.Item>
  );

  return (
    <div className={formClasses.body}>
      <div className={formClasses.bodyInner}>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        <form onSubmit={formik.handleSubmit}>
          {companyNameNode}
          <Row gutter={16}>
            <Col span={12}>{companyEmailNode}</Col>
            <Col span={12}>{companyPhoneNode}</Col>
          </Row>
          {addressNode}
          {websiteNode}
          <Row gutter={16}>
            <Col span={12}>{twitterNode}</Col>
            <Col span={12}>{youtubeNode}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>{facebookNode}</Col>
            <Col span={12}>{instagramNode}</Col>
          </Row>
          <Form.Item>
            <div
              className={css({
                display: "flex",
              })}
            >
              {!omitBackBtn && (
                <div
                  className={css({
                    display: "flex",
                    marginRight: "16px",
                  })}
                >
                  <Button
                    htmlType="button"
                    onClick={() => onBack(formik.values)}
                  >
                    <Space>
                      <LeftOutlined />
                      <span>Back</span>
                    </Space>
                  </Button>
                </div>
              )}
              <div
                className={css({
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 1,
                })}
              >
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  {saveBtnText || "Create Account"}
                </Button>
              </div>
            </div>
          </Form.Item>
        </form>
      </div>
    </div>
  );
};

export default React.memo(Signup);
