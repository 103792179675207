import { AlertOutlined } from "@ant-design/icons";
import { Badge, Button, Empty, List, Popover, Typography } from "antd";
import { css } from "@emotion/css";
import React from "react";
import { appColors } from "../utils/theme";

export interface IAppNotificationsProps {}

const classes = {
  content: css({
    width: "320px",
  }),
};

const AppNotifications: React.FC<IAppNotificationsProps> = (props) => {
  const [notifications] = React.useState<string[]>([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    React.useState(0);

  // const onNewNotification = React.useCallback(
  //   (message: string) => {
  //     setNotifications([message, ...notifications]);
  //   },
  //   [notifications]
  // );

  const markNotificationRead = React.useCallback(() => {
    setUnreadNotificationsCount(0);
  }, []);

  // React.useEffect(() => {
  //   defaultHub.addListener(HubEventNames.RecieveMessage, onNewNotification);
  //   return () => {
  //     defaultHub.removeListener(
  //       HubEventNames.RecieveMessage,
  //       onNewNotification
  //     );
  //   };
  // }, [onNewNotification]);

  const content = (
    <div className={classes.content}>
      {notifications.length === 0 ? (
        <Empty description="No notifications!" />
      ) : (
        <List
          bordered
          header={null}
          footer={null}
          dataSource={notifications}
          renderItem={(message) => (
            <List.Item>
              <Typography.Text>{message}</Typography.Text>
            </List.Item>
          )}
        />
      )}
    </div>
  );

  return (
    <Popover
      content={content}
      title="Notifications"
      trigger="click"
      onVisibleChange={markNotificationRead}
    >
      <Button
        type="text"
        icon={
          <Badge
            count={unreadNotificationsCount}
            style={{ color: appColors.primaryColor }}
          >
            <AlertOutlined />
          </Badge>
        }
      />
    </Popover>
  );
};

export default AppNotifications;
