import useRequest from "@ahooksjs/use-request";
import { Card, Space, Typography } from "antd";
import React from "react";
import DashboardAPI from "../../api/endpoints/dashboard";
import { DashboardTimeFilterValue } from "../../definitions/dashboard";
import CountList from "../utils/CountList";
import LabeledCount from "../utils/LabeledCount";
import PageError from "../utils/PageError";
import PageLoading from "../utils/PageLoading";

export interface IDashboardStaticCountsProps {
  time: DashboardTimeFilterValue;
}

const DashboardStaticCounts: React.FC<IDashboardStaticCountsProps> = (
  props
) => {
  const { time } = props;
  const getData = React.useCallback(async () => {
    return await DashboardAPI.getStaticCounts({ time });
  }, [time]);

  const { error, data, loading, run } = useRequest(getData, {
    refreshDeps: [time],
  });
  let contentNode: React.ReactNode = null;

  const renderCount = (label: string, value: number) => {
    return (
      <Card>
        <Space direction="vertical">
          <Typography.Text strong style={{ fontSize: "32px" }}>
            {value}
          </Typography.Text>
          <Typography.Text type="secondary">{label}</Typography.Text>
        </Space>
      </Card>
    );
  };

  if (error) {
    contentNode = (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload", onClick: run }]}
      />
    );
  } else if (loading || !data) {
    contentNode = <PageLoading />;
  } else {
    contentNode = (
      <CountList>
        <LabeledCount
          label="Total MoveBot Companies"
          count={data.TotalMoveBotCompanys}
        />
        <LabeledCount
          label="Total MoveBot Drivers"
          count={data.TotalMoveBotDrivers}
        />
        <LabeledCount
          label="Total Successful Transactions"
          count={data.TotalSuccessfulTransaction}
        />
        <LabeledCount
          label="Total Transactions"
          count={data.TotalTransaction}
        />
      </CountList>
    );
  }

  return (
    <Space
      direction="vertical"
      style={{ width: "100%", boxSizing: "border-box" }}
    >
      <Typography.Title level={5}>Static Counts</Typography.Title>
      {contentNode}
    </Space>
  );
};

export default DashboardStaticCounts;
