import { Typography } from "antd";
import React from "react";
import { appClasses } from "../utils/theme";
import DashboardStatisticFilterInput, {
  IDashboardStatisticFilterInputProps,
} from "./DashboardStatisticFilterInput";

export interface IDashboardControlsProps
  extends IDashboardStatisticFilterInputProps {}

const DashboardControls: React.FC<IDashboardControlsProps> = (props) => {
  return (
    <div className={appClasses.controls.root}>
      <div className={appClasses.controls.leftControls}>
        <Typography.Title level={3}>Dashboard</Typography.Title>
      </div>
      <div>
        <DashboardStatisticFilterInput {...props} />
      </div>
    </div>
  );
};

export default DashboardControls;
