import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import CompanyAPI from "../../api/endpoints/company";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { ICompany, ICompanyInput } from "../../definitions/company";
import { messages } from "../../definitions/messages";
import cast from "../../utils/cast";
import { wrapWithMessageError } from "../utils/utils";
import CompanyForm, { ICompanyFormValues } from "./CompanyForm";

export interface ICompanyFormContainerProps {
  company?: ICompany;
  onCompleteCreateCompany: () => void;
  onCompleteUpdateCompany: () => void;
}

function getCompanyFormValues(company: ICompany): ICompanyFormValues {
  const input = {
    name: company.Name,
    description: company.Description,
    caC_Number: company.CompanyCACNumber,
    companyAcronym: company.CompanyAcronym,
    websiteUrl: company.CompanyWebsite,
    primaryAddress: {
      street: company.CompanyAddress_.Street,
      city: company.CompanyAddress_.City,
      state: company.CompanyAddress_.State,
      zipCode: company.CompanyAddress_.ZipCode,
      country: company.CompanyAddress_.Country,
    },
  };

  return cast<ICompanyFormValues>(input);
}

const CompanyFormContainer: React.FC<ICompanyFormContainerProps> = (props) => {
  const { company, onCompleteCreateCompany, onCompleteUpdateCompany } = props;
  const onSubmit = React.useCallback(
    async (data: ICompanyInput) => {
      wrapWithMessageError(async () => {
        if (company) {
          const result = await CompanyAPI.updateCompany({
            ...data,
            companyId: company.CompanyId,
          });
          message.success(
            getEndpointResultMessage(result) || messages.companyUpdatedSuccess
          );
          onCompleteUpdateCompany();
        } else {
          const result = await CompanyAPI.createCompany(data);
          message.success(
            getEndpointResultMessage(result) || messages.companyCreatedSuccess
          );
          onCompleteCreateCompany();
        }
      });
    },
    [company, onCompleteCreateCompany, onCompleteUpdateCompany]
  );

  const {
    loading: isSubmitting,
    error: submitError,
    run: onSubmitForm,
  } = useRequest(onSubmit, { manual: true });

  return (
    <CompanyForm
      onSubmit={onSubmitForm}
      error={submitError?.message}
      isSubmitting={isSubmitting}
      data={company ? getCompanyFormValues(company) : undefined}
      omitPrimaryAdmin={!!company}
    />
  );
};

export default CompanyFormContainer;
