import { css } from "@emotion/css";
import { List, Space, Typography } from "antd";
import React from "react";
import { IGroupPolicy } from "../../definitions/group-policy";
import { pluralize } from "../../utils/utils";
import { appColors } from "../utils/theme";

export interface IPolicyListItemRoleAndPermissionsProps {
  policy: IGroupPolicy;
}

const PolicyListItemRoleAndPermissions: React.FC<IPolicyListItemRoleAndPermissionsProps> =
  (props) => {
    const { policy } = props;
    const [showPermissions, setShowPermissions] = React.useState(false);

    return (
      <Space direction="vertical">
        <Space>
          <Typography.Text strong>{policy.RoleInfo._RoleType}</Typography.Text>
          {policy.AssignedPolicy.length ? (
            <Space>
              <Typography.Text>&#xB7;</Typography.Text>
              <Typography.Text
                underline
                className={css({
                  cursor: "pointer",
                  color: `${appColors.primaryColor} !important`,
                })}
                onClick={() => setShowPermissions(!showPermissions)}
              >
                {showPermissions ? "Hide" : "Show"}{" "}
                {policy.AssignedPolicy.length}{" "}
                {pluralize("permission", policy.AssignedPolicy.length)}
              </Typography.Text>
            </Space>
          ) : null}
        </Space>
        {showPermissions && (
          <List
            split
            header={null}
            footer={null}
            dataSource={policy.AssignedPolicy}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text strong>{item._PolicyType}</Typography.Text>
              </List.Item>
            )}
          />
        )}
      </Space>
    );
  };

export default PolicyListItemRoleAndPermissions;
