import { Alert, Button, Form, Input, Space, Typography } from "antd";
import React from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { appBasePaths } from "../../definitions/paths";
import FormError from "../forms/FormError";
import { formClasses } from "../forms/formStyleUtils";
import FunErrorMessages from "../forms/FunErrorMessages";
import useFormHelpers from "../hooks/useFormHelpers";
import { messages } from "../../definitions/messages";
import { userConstants } from "../../definitions/user";
import { utilClassNames } from "../utils/util-class-names";
import FormLogo from "../forms/FormLogo";
import { cx } from "@emotion/css";
import { formValidationSchemas } from "../forms/validation";

const validationSchema = yup.object().shape({
  token: formValidationSchemas.forgotPasswordTokenRequired,
  password: formValidationSchemas.passwordRequired,
  confirmPassword: formValidationSchemas.confirmPasswordRequired,
});

export interface IResetPasswordFormValues {
  token: string;
  password: string;
}

export interface IResetPasswordFormProps {
  onSubmit: (values: IResetPasswordFormValues) => void | Promise<void>;
  isSubmitting?: boolean;
  error?: string;
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = (props) => {
  const { isSubmitting, error, onSubmit } = props;

  const { formik } = useFormHelpers({
    formikProps: {
      validationSchema,
      initialValues: { token: "", password: "", confirmPassword: "" },
      onSubmit: (values) => {
        onSubmit({
          token: values.token,
          password: values.password,
        });
      },
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const content = (
    <Space className={formClasses.bodyInner} direction="vertical" size="large">
      <FormLogo />
      <Typography.Title level={4}>Reset Password</Typography.Title>
      {error && <Alert message={error} type="error" />}
      <form onSubmit={handleSubmit}>
        <Form.Item
          required
          label="Token"
          help={
            touched.token && errors.token ? (
              <FunErrorMessages message={formik.errors.token}>
                <FormError error={formik.errors.token} />
              </FunErrorMessages>
            ) : null
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            name="token"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.token}
            placeholder="Enter the token sent to your phone number"
            disabled={isSubmitting}
          />
        </Form.Item>
        <Form.Item
          required
          label="Enter New Password"
          help={
            formik.touched?.password && formik.errors?.password ? (
              <FunErrorMessages message={formik.errors?.password}>
                <FormError error={formik.errors?.password} />
              </FunErrorMessages>
            ) : (
              messages.passwordExtraInfo
            )
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password
            visibilityToggle
            autoComplete="new-password"
            name="password"
            value={formik.values.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            disabled={isSubmitting}
            placeholder="Enter new password"
            maxLength={userConstants.maxPasswordLength}
          />
        </Form.Item>
        <Form.Item
          required
          label="Confirm New Password"
          help={
            formik.touched?.confirmPassword &&
            formik.errors?.confirmPassword && (
              <FunErrorMessages message={formik.errors.confirmPassword}>
                <FormError error={formik.errors.confirmPassword} />
              </FunErrorMessages>
            )
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password
            visibilityToggle
            autoComplete="new-password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            disabled={isSubmitting}
            placeholder="Re-enter your new password"
            maxLength={userConstants.maxPasswordLength}
          />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            {isSubmitting ? "Changing Password..." : "Change Password"}
          </Button>
        </Form.Item>
        <Form.Item>
          <Typography.Text>
            Don't have an account?{" "}
            <Link to={appBasePaths.signup}>
              <Typography.Text
                className={utilClassNames.typographyWithPrimaryColor}
              >
                Sign up
              </Typography.Text>
            </Link>
          </Typography.Text>
        </Form.Item>
      </form>
    </Space>
  );

  return (
    <div className={cx(formClasses.body, formClasses.centeredFormContainer)}>
      {content}
    </div>
  );
};

export default ResetPasswordForm;
