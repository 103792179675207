import {
  AnalysisFrequencyTypes,
  IAnalysisBlockItemStat,
  IAnalysisDeliveryCostStat,
  IAnalysisDriver,
  IAnalysisDriverFeedback,
  IAnalysisDriverStat,
  IAnalysisOrderItemCategoryStat,
  IAnalysisOrderItemStat,
  IAnalysisOrganization,
  IAnalysisPaymentMethodStat,
} from "../../definitions/analysis";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { selectEndpointParam, setEndpointParam } from "../utils";

const auditPath = `${netAddrs.serverAddr}/Analysis`;
const getOrganizationsPath = `${auditPath}/GetOrganization`;
const getDriversPath = `${auditPath}/GetDrivers`;
const getDriverStatsPath = `${auditPath}/GetDriverStats`;
const getDriverFeedbackPath = `${auditPath}/GetDriverFeedback`;
const getOrderItemStatsPath = `${auditPath}/GetOrderItemStats`;
const getBlockItemStatsPath = `${auditPath}/GetBlockItemStats`;
const getPaymentMethodStatsPath = `${auditPath}/GetPaymentMethodStats`;
const getOrderItemCategoryStatsPath = `${auditPath}/GetOrderItemCatStats`;
const getDeliveryCostStatsPath = `${auditPath}/GetDeliveryCostStats`;

export type IAnalysisGetOrganizationsEndpointResult =
  Array<IAnalysisOrganization>;

async function getOrganizationList(): Promise<IAnalysisGetOrganizationsEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IAnalysisGetOrganizationsEndpointResult>({
      path: getOrganizationsPath,
      method: "GET",
    });
  return result;
}

export type IAnalysisGetDriversEndpointResult = Array<IAnalysisDriver>;
export interface IAnalysisGetDriverListEndpointParams {
  orgId?: string;
  freqTypes?: AnalysisFrequencyTypes;
}

async function getDriverList(
  props: IAnalysisGetDriverListEndpointParams = {}
): Promise<IAnalysisGetDriversEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  setEndpointParam(params, "freqTypes", props.freqTypes);
  const endpointURL = selectEndpointParam(getDriversPath, params, props);
  const result =
    await invokeEndpointWithAuth<IAnalysisGetDriversEndpointResult>({
      path: endpointURL,
      method: "GET",
    });
  return result;
}

export type IAnalysisGetDriverStatsEndpointResult = Array<IAnalysisDriverStat>;
export interface IAnalysisGetDriverStatsEndpointParams {
  orgId?: string;
  freqTypes?: AnalysisFrequencyTypes;
}

async function getDriverStats(
  props: IAnalysisGetDriverStatsEndpointParams = {}
): Promise<IAnalysisGetDriverStatsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  setEndpointParam(params, "freqTypes", props.freqTypes);
  const endpointURL = selectEndpointParam(getDriverStatsPath, params, props);
  const result =
    await invokeEndpointWithAuth<IAnalysisGetDriverStatsEndpointResult>({
      path: endpointURL,
      method: "GET",
    });
  return result;
}

export type IAnalysisGetDriverFeedbackEndpointResult =
  Array<IAnalysisDriverFeedback>;

export interface IAnalysisGetDriverFeedbackEndpointParams {
  orgId?: string;
  driverId?: string;
}

async function getDriverFeedback(
  props: IAnalysisGetDriverFeedbackEndpointParams = {}
): Promise<IAnalysisGetDriverFeedbackEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  setEndpointParam(params, "driverId", props.driverId);
  const endpointURL = selectEndpointParam(getDriverFeedbackPath, params, props);
  const result =
    await invokeEndpointWithAuth<IAnalysisGetDriverFeedbackEndpointResult>({
      path: endpointURL,
      method: "GET",
    });
  return result;
}

export type IAnalysisGetOrderItemStatsEndpointResult = IAnalysisOrderItemStat;
export interface IAnalysisGetOrderItemStatsEndpointParams {
  orgId?: string;
  freqTypes?: AnalysisFrequencyTypes;
}

async function getOrderItemStats(
  props: IAnalysisGetOrderItemStatsEndpointParams = {}
): Promise<IAnalysisGetOrderItemStatsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  setEndpointParam(params, "freqTypes", props.freqTypes);
  const endpointURL = selectEndpointParam(getOrderItemStatsPath, params, props);
  const result =
    await invokeEndpointWithAuth<IAnalysisGetOrderItemStatsEndpointResult>({
      path: endpointURL,
      method: "GET",
    });
  return result;
}

export type IAnalysisGetBlockItemStatsEndpointResult = IAnalysisBlockItemStat;

export interface IAnalysisGetBlockItemStatsEndpointParams {
  orgId?: string;
}

async function getBlockItemStats(
  props: IAnalysisGetBlockItemStatsEndpointParams = {}
): Promise<IAnalysisGetBlockItemStatsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  const endpointURL = selectEndpointParam(getBlockItemStatsPath, params, props);
  const result =
    await invokeEndpointWithAuth<IAnalysisGetBlockItemStatsEndpointResult>({
      path: endpointURL,
      method: "GET",
    });
  return result;
}

export type IAnalysisGetPaymentMethodStatsEndpointResult =
  IAnalysisPaymentMethodStat;

export interface IAnalysisGetPaymentMethodStatsEndpointParams {
  orgId?: string;
}

async function getPaymentMethodStats(
  props: IAnalysisGetPaymentMethodStatsEndpointParams = {}
): Promise<IAnalysisGetPaymentMethodStatsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  const endpointURL = selectEndpointParam(
    getPaymentMethodStatsPath,
    params,
    props
  );
  const result =
    await invokeEndpointWithAuth<IAnalysisGetPaymentMethodStatsEndpointResult>({
      path: endpointURL,
      method: "GET",
    });
  return result;
}

export type IAnalysisGetOrderItemCategoryStatsEndpointResult =
  Array<IAnalysisOrderItemCategoryStat>;

export interface IAnalysisGetOrderItemCategoryStatsEndpointParams {
  orgId?: string;
  freqTypes?: AnalysisFrequencyTypes;
}

async function getOrderItemCategoryStats(
  props: IAnalysisGetOrderItemCategoryStatsEndpointParams = {}
): Promise<IAnalysisGetOrderItemCategoryStatsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  setEndpointParam(params, "freqTypes", props.freqTypes);
  const endpointURL = selectEndpointParam(
    getOrderItemCategoryStatsPath,
    params,
    props
  );
  const result =
    await invokeEndpointWithAuth<IAnalysisGetOrderItemCategoryStatsEndpointResult>(
      {
        path: endpointURL,
        method: "GET",
      }
    );
  return result;
}

export type IAnalysisGetDeliveryCostStatsEndpointResult =
  Array<IAnalysisDeliveryCostStat>;

export interface IAnalysisGetDeliveryCostStatsEndpointParams {
  orgId?: string;
  freqTypes?: AnalysisFrequencyTypes;
}

async function getDeliveryCostStats(
  props: IAnalysisGetDeliveryCostStatsEndpointParams = {}
): Promise<IAnalysisGetDeliveryCostStatsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  setEndpointParam(params, "freqTypes", props.freqTypes);
  const endpointURL = selectEndpointParam(
    getDeliveryCostStatsPath,
    params,
    props
  );
  const result =
    await invokeEndpointWithAuth<IAnalysisGetDeliveryCostStatsEndpointResult>({
      path: endpointURL,
      method: "GET",
    });
  return result;
}

export default class AnalysisAPI {
  static getOrganizationList = getOrganizationList;
  static getDriverList = getDriverList;
  static getDriverStats = getDriverStats;
  static getDriverFeedback = getDriverFeedback;
  static getOrderItemStats = getOrderItemStats;
  static getBlockItemStats = getBlockItemStats;
  static getPaymentMethodStats = getPaymentMethodStats;
  static getOrderItemCategoryStats = getOrderItemCategoryStats;
  static getDeliveryCostStats = getDeliveryCostStats;
}
