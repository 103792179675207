import { List, Space, Typography } from "antd";
import React from "react";
import { ITrackerEvent } from "../../definitions/tracker";

export interface ITrackerEventProps {
  className?: string;
  event: ITrackerEvent;
}

const TrackerEvent: React.FC<ITrackerEventProps> = (props) => {
  const { event, className } = props;
  return (
    <List.Item className={className}>
      <Space direction="vertical">
        <Typography.Text>{event.EventDate_}</Typography.Text>
        <Typography.Text>{event.Description}</Typography.Text>
      </Space>
    </List.Item>
  );
};

export default TrackerEvent;
