import { css } from "@emotion/css";
import React from "react";
import { appClasses } from "../utils/theme";
import AnalysisControls from "./AnalysisControls";
import AnalysisFeedbackTable from "./AnalysisFeedbackTable";
import { useAnalysisControls, useFetchFeedback } from "./hooks";

const classes = {
  header: css({
    marginTop: 0,
  }),
};

const AnalysisFeedbackStats: React.FC<{}> = () => {
  const controls = useAnalysisControls({ enableDrivers: true });
  const feedbacks = useFetchFeedback(controls.state);

  const feedbacksNode = feedbacks.returnNode || (
    <AnalysisFeedbackTable
      loading={feedbacks.loading}
      data={feedbacks.data || []}
    />
  );

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <AnalysisControls enableDrivers controls={controls} title="Feedback" />
      </div>
      <div className={appClasses.pageContent}>{feedbacksNode}</div>
    </div>
  );
};

export default AnalysisFeedbackStats;
