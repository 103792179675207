import { Col, Row } from "antd";
import React from "react";
import LabeledNode, { ILabeledNodeProps } from "./LabeledNode";

export interface ILabeledNodeListProps {
  emptyNodeText?: string;
  gutter?: number;
  span?: number;
  direction: ILabeledNodeProps["direction"];
  list: Array<Omit<ILabeledNodeProps, "direction"> & { span?: number }>;
}

const DEFAULT_GUTTER = 16;
const DEFAULT_SPAN = 8;

const LabeledNodeList: React.FC<ILabeledNodeListProps> = (props) => {
  const { emptyNodeText, direction, list, gutter, span } = props;
  return (
    <Row gutter={[gutter || DEFAULT_GUTTER, 16]}>
      {list.map((item, i) => {
        return (
          <Col
            key={`${item.label + i}`}
            span={item.span || span || DEFAULT_SPAN}
          >
            <LabeledNode
              {...item}
              direction={direction}
              emptyNodeText={item.emptyNodeText || emptyNodeText}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default LabeledNodeList;
