import { useRequest } from "ahooks";
import { message } from "antd";
import { noop } from "lodash";
import React from "react";
import UserAPI from "../../api/endpoints/user";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { IAdminCompany } from "../../definitions/company";
import { messages } from "../../definitions/messages";
import { wrapWithMessageError } from "../utils/utils";
import SignupCompanyForm, {
  IIncomingSignupCompanyFormValues,
  IOutgoingSignupCompanyFormValues,
} from "./SignupCompanyForm";

export interface IAdminCompanyFormContainerProps {
  company: IAdminCompany;
  onCompleteUpdateAdminCompany: () => void | Promise<void>;
}

function getAdminCompanyFormValues(
  company: IAdminCompany
): IIncomingSignupCompanyFormValues {
  return {
    companyName: company.CompanyName,
    companyEmail: company.EmailAddress,
    companyPhoneNumber: company.PhoneNumber,
    address: {
      street: company.RawAddress.Street,
      city: company.RawAddress.City,
      state: company.RawAddress.State,
      zipCode: company.RawAddress.ZipCode,
      country: company.RawAddress.Country,
    },
    websiteURL: company.WebsiteUrl,
    facebookURL: company.FacebookUrl,
    youtubeURL: company.YoutubeUrl,
    instagramURL: company.InstagramUrl,
    twitterUrl: company.TwitterUrl,
  };
}

const AdminCompanyFormContainer: React.FC<IAdminCompanyFormContainerProps> = (
  props
) => {
  const { company, onCompleteUpdateAdminCompany } = props;
  const onSubmit = React.useCallback(
    async (data: IOutgoingSignupCompanyFormValues) => {
      wrapWithMessageError(async () => {
        const result = await UserAPI.updateCompanyProfile({
          name: data.companyName,
          emailAddress: data.companyEmail,
          phoneNumber: data.companyPhoneNumber,
          address: data.address,
          websiteUrl: data.websiteURL,
          facebookUrl: data.facebookURL,
          twitterUrl: data.twitterUrl,
          instagramUrl: data.instagramURL,
          youtubeUrl: data.instagramURL,
          countryCode: data.countryCode,
        });

        message.success(
          getEndpointResultMessage(result) || messages.companyUpdatedSuccess
        );
        await onCompleteUpdateAdminCompany();
      });
    },
    [onCompleteUpdateAdminCompany]
  );

  const {
    loading: isSubmitting,
    error: submitError,
    run: onSubmitForm,
  } = useRequest(onSubmit, { manual: true });

  return (
    <SignupCompanyForm
      omitBackBtn
      saveBtnText="Save"
      onSubmit={onSubmitForm}
      error={submitError?.message}
      isSubmitting={isSubmitting}
      onBack={noop}
      data={getAdminCompanyFormValues(company)}
    />
  );
};

export default AdminCompanyFormContainer;
