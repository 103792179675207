import {
  IEmployee,
  IEmployeeInput,
  IEmployeeStatusHistory,
} from "../../definitions/employee";
import {
  IPaginatedDataInput,
  IPaginatedDataResult,
} from "../../definitions/utils";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { IEndpointMessageResult } from "../types";
import { selectEndpointParam, setEndpointParam } from "../utils";

const employeeURL = `${netAddrs.serverAddr}/Employee`;
const pageURL = `${employeeURL}/Page`;
const getStatusHistoryURL = `${employeeURL}/History`;
const createURL = `${employeeURL}/Create`;
const updateURL = `${employeeURL}/Update`;
const updateStatusURL = `${employeeURL}/Status`;
const deleteEmployeeURL = `${employeeURL}/Delete`;

export interface IGetEmployeesEndpointResult
  extends IPaginatedDataResult<IEmployee> {}

export interface IGetEmployeesEndpointParams extends IPaginatedDataInput {
  roleType?: string;
}

async function getEmployees(
  props: IGetEmployeesEndpointParams = {}
): Promise<IGetEmployeesEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "roleType", props.roleType);

  const endpointURL = selectEndpointParam(pageURL, params, props);
  const result = await invokeEndpointWithAuth<IGetEmployeesEndpointResult>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export interface IGetEmployeeStatusHistoryEndpointInput {
  employeeId: string;
}

export type IGetEmployeeStatusHistoryEndpointResult =
  Array<IEmployeeStatusHistory>;

async function getEmployeeHistory(
  props: IGetEmployeeStatusHistoryEndpointInput
): Promise<IGetEmployeeStatusHistoryEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "employeeId", props.employeeId);

  const endpointURL = selectEndpointParam(getStatusHistoryURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetEmployeeStatusHistoryEndpointResult>({
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

async function createEmployee(
  props: IEmployeeInput
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: createURL,
    method: "POST",
    data: props,
  });

  return result;
}

export interface IUpdateEmployeeEndpointInput {
  employeeId: string;
  data: Partial<IEmployeeInput>;
}

async function updateEmployee(
  props: IUpdateEmployeeEndpointInput
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "employeeId", props.employeeId);

  const endpointURL = selectEndpointParam(updateURL, params, props);
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: endpointURL,
    method: "PUT",
    data: props.data,
  });

  return result;
}

export interface IUpdateEmployeeStatusEndpointProps {
  employeeId: string;
  showStatusReasonToUser: boolean;
  reason: string;
  statusType: string;
}

async function updateEmployeeStatus(
  props: IUpdateEmployeeStatusEndpointProps
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: updateStatusURL,
    method: "PUT",
    data: props,
  });

  return result;
}

export interface IDeleteEmployeeEndpointProps {
  id: string;
  reason: string;
}

async function deleteEmployee(
  props: IDeleteEmployeeEndpointProps
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: deleteEmployeeURL,
    method: "DELETE",
    data: props,
  });

  return result;
}

export default class EmployeeAPI {
  public static getEmployees = getEmployees;
  public static getEmployeeHistory = getEmployeeHistory;
  public static createEmployee = createEmployee;
  public static updateEmployee = updateEmployee;
  public static updateEmployeeStatus = updateEmployeeStatus;
  public static deleteEmployee = deleteEmployee;
}
