import React from "react";
import useRequest from "@ahooksjs/use-request";
import { Line } from "@ant-design/charts";
import moment from "moment";
import PageLoading from "../utils/PageLoading";
import DashboardAPI from "../../api/endpoints/dashboard";
import PageError from "../utils/PageError";
import { DashboardTimeFilterValue } from "../../definitions/dashboard";
import { Space, Typography } from "antd";

export interface IDashboardOrderCreationStaticsProps {
  time: DashboardTimeFilterValue;
}

const DashboardOrderCreationStatics: React.FC<
  IDashboardOrderCreationStaticsProps
> = (props) => {
  const { time } = props;
  const getData = React.useCallback(async () => {
    return await DashboardAPI.getOrderCreationStatics({ time });
  }, [time]);

  const { error, data, loading, run } = useRequest(getData, {
    refreshDeps: [time],
  });
  let contentNode: React.ReactNode = null;

  if (error) {
    contentNode = (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload", onClick: run }]}
      />
    );
  } else if (loading || !data) {
    contentNode = <PageLoading />;
  } else {
    const processed = data.map((item) => ({
      TotalCreated: item.TotalCreated,
      DateCreated: moment(item.DateCreated).format("YYYY-MM-DD"),
    }));

    const config: React.ComponentProps<typeof Line> = {
      data: processed,
      padding: "auto",
      xField: "DateCreated",
      yField: "TotalCreated",
      xAxis: {
        tickCount: 5,
      },
    };

    contentNode = <Line {...config} />;
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Title level={5}>Order Creation Statistics</Typography.Title>
      {contentNode}
    </Space>
  );
};

export default DashboardOrderCreationStatics;
