import React from "react";

export interface IUseIsComponentMountedResult {
  isMounted: () => boolean;
}

export default function useIsComponentMounted(): IUseIsComponentMountedResult {
  const mountState = React.useRef(true);
  const isMounted = () => mountState.current;

  React.useEffect(() => {
    mountState.current = true;
    return () => {
      mountState.current = false;
    };
  }, []);

  return { isMounted };
}
