import React from "react";
import { Table, TableColumnType, Tag } from "antd";
import { ITicket } from "../../definitions/ticket";
import SelectTicketStatusContainer from "./SelectTicketStatusContainer";
import { Link } from "react-router-dom";
import { appLoggedInPaths } from "../../definitions/paths";
import TableSkeleton from "../utils/TableSkeleton";

export interface ITicketTableProps {
  loading?: boolean;
  data: ITicket[];
  onCompleteUpdateStatus: () => void;
}

const TicketTable: React.FC<ITicketTableProps> = (props) => {
  const { loading, data, onCompleteUpdateStatus } = props;
  const columns: TableColumnType<ITicket>[] = [
    {
      title: "Ticket Number",
      dataIndex: "TicketNumber",
      key: "TicketNumber",
      render: (text, record) => {
        return (
          <Link to={`${appLoggedInPaths.ticket}/${record.TicketNumber}`}>
            {text}
          </Link>
        );
      },
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, ticket) => (
        <SelectTicketStatusContainer
          useTags
          bordered={false}
          onCompleteUpdate={onCompleteUpdateStatus}
          status={ticket.Status.Name}
          statusColor={ticket.Status.Color}
          ticketId={ticket.TicketId}
        />
      ),
      width: 200,
    },
    {
      title: "Subject",
      dataIndex: "Subject",
      key: "Subject",
      width: 200,
    },
    {
      title: "Priority",
      dataIndex: "Priority",
      key: "Priority",
      width: 200,
      render: (text, record) => (
        <Tag style={{ color: record.Priority.Color }}>
          {record.Priority.Name}
        </Tag>
      ),
    },
    {
      title: "Organization",
      dataIndex: "OrganizationName",
      key: "OrganizationName",
      width: 200,
    },
    {
      title: "Requester",
      dataIndex: "RequesterName",
      key: "RequesterName",
      width: 200,
    },
    {
      title: <div style={{ width: "200px" }}>Date Created</div>,
      dataIndex: "DateCreated_",
      key: "DateCreated_",
      width: 200,
    },
    {
      title: "Last Updated",
      dataIndex: "LastUpdated",
      key: "LastUpdated",
      width: 200,
    },
  ];

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      rowKey={(row) => row.TicketId}
      pagination={false}
    />
  );
};

export default TicketTable;
