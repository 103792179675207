import { IEnum } from "./utils";

export interface IRole {
  RoleId: string;
  RoleType: number;
  _RoleType: string;
}

export interface IRolePermission {
  PolicyId: string;
  PolicyType: number;
  _PolicyType: string;
}

export enum GroupPolicyStatus {
  Active = "Active",
  Deactivated = "Deactivated",
}

export interface IGroupPolicy {
  Status: IEnum;
  GroupPolicyId: string;
  RoleInfo: IRole;
  GroupName: string;
  Description: string;
  DateCreated: string;
  DateCreated_: string;
  LastUpdated: string;
  UpdatedBy: string;
  AssignedPolicy: Array<IRolePermission>;
}

export interface IShortGroupPolicy {
  GroupPolicyId: string;
  GroupPolicyName: string;
  Status: IEnum;
}
