import { Col, Row } from "antd";
import React from "react";

export interface ICountListProps {
  children?: React.ReactNode;
}

const CountList: React.FC<ICountListProps> = (props) => {
  const { children } = props;
  return (
    <Row wrap gutter={[16, 16]}>
      {React.Children.map(children, (node) => (
        <Col span={12}>{node}</Col>
      ))}
    </Row>
  );
};

export default CountList;
