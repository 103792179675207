import { BankOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Space, Table, TableColumnType, Tooltip } from "antd";
import React from "react";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import { ICompany } from "../../definitions/company";
import TableSkeleton from "../utils/TableSkeleton";
import CompanyStatus from "./CompanyStatus";
import DeleteCompanyAction from "./DeleteCompanyAction";

export interface ICompanyTableProps {
  loading?: boolean;
  data: ICompany[];
  onCompleteUpdateStatus: () => void;
  onCompleteDelete: () => void;
  onEdit: (id: string) => void;
}

const CompanyTable: React.FC<ICompanyTableProps> = (props) => {
  const { data, loading, onCompleteUpdateStatus, onCompleteDelete, onEdit } =
    props;

  const columns: TableColumnType<ICompany>[] = [
    {
      title: "",
      dataIndex: "CompanyId",
      key: "company-image",
      render(id, record) {
        return (
          <Avatar
            icon={<BankOutlined />}
            src={getLoadSingleImagePath(id)}
            alt={`${record.Name}'s avatar picture`}
            shape="circle"
          />
        );
      },
      width: 50,
    },
    {
      title: "Acronym",
      dataIndex: "CompanyAcronym",
      key: "CompanyAcronym",
    },
    {
      title: "CAC Number",
      dataIndex: "CompanyCACNumber",
      key: "CompanyCACNumber",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Email",
      dataIndex: "EmailAddress",
      key: "EmailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "PhoneNumber",
      key: "PhoneNumber",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record) => {
        return (
          <CompanyStatus
            company={record}
            onCompleteUpdateStatus={onCompleteUpdateStatus}
          />
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record) => (
        <Space size="small">
          <Tooltip title="edit">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => onEdit(record.CompanyId)}
            />
          </Tooltip>
          <DeleteCompanyAction
            company={record}
            onCompleteDelete={onCompleteDelete}
          />
        </Space>
      ),
      width: 100,
    },
  ];

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      rowKey={(row) => row.CompanyId}
      pagination={false}
    />
  );
};

export default CompanyTable;
