import { IEnum, IStatus } from "./utils";

export interface ITicket {
  TicketId: string;
  OrganizationId: string;
  OrganizationName: string;
  Status: IStatus;
  TicketNumber: string;
  Subject: string;
  Message: string;
  Priority: IEnum;
  RequesterName: string;
  RequesterEmail: string;
  Department: string;
  DateCreated: string;
  AttachmentImageCount: number;
  AttachmentId: string;
  LastUpdated: string;
  DateCreated_: string;
}

export enum TicketPriority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Urgent = "Urgent",
}

export interface ITicketActivity {
  Message: string;
  RespondantId: string;
  Timestamp: string;
  Timestamp_: string;
  Title: string;
  Header: string;
  Recipient: string;
  AttachmentId: string;
  AttachmentImageCount: number;
}

export interface ITicketInput {
  subject: string;
  message: string;
  priority: TicketPriority;
  relatedService?: string;
  customService?: string;
  attachmentId?: string;
}

export interface ITicketActivityInput {
  attachmentId?: string;
  message: string;
  ticketId: string;
}

export interface ITicketOrganization {
  OrganizationId: string;
  OrganizationName: string;
}

export const ticketPriorityColors: Record<TicketPriority, string> = {
  Urgent: "#f5222d",
  High: "#fa541c",
  Medium: "#52c41a",
  Low: "#fadb14",
};

export const TICKET_RELATED_SERVICES_OTHERS_OPTION = "others";
