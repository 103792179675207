import { cx, css } from "@emotion/css";
import { useRequest } from "ahooks";
import { Button, notification, Result, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import UserAPI from "../../api/endpoints/user";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { messages } from "../../definitions/messages";
import { appBasePaths } from "../../definitions/paths";
import { utilClassNames } from "../utils/util-class-names";
import { wrapWithMessageError } from "../utils/utils";
import ForgotPasswordForm, {
  IForgotPasswordFormValues,
} from "./ForgotPasswordForm";

interface ISentRequestDetails {
  message: string;
  phoneNumberOrEmail: string;
}

const ForgotPasswordContainer: React.FC<{}> = () => {
  const [sentDetails, setSentDetails] =
    React.useState<ISentRequestDetails | null>(null);

  const onSubmit = React.useCallback(
    async (data: IForgotPasswordFormValues) => {
      wrapWithMessageError(async () => {
        const result = await UserAPI.forgotPassword({
          phoneNumberOrEmail: data.emailOrPhoneNumber,
        });

        // TODO: what result is sent if the email or phone doesn't exist?

        notification.success({
          message: "Code Sent!",
          description: getEndpointResultMessage(result),
          duration: 10,
        });
      });
    },
    []
  );

  const { loading, error, run } = useRequest(onSubmit, { manual: true });

  if (sentDetails) {
    return (
      <div
        className={css({
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          maxWidth: "500px",
        })}
      >
        <Result
          status="success"
          title={sentDetails.message || messages.codeSent}
          subTitle={
            <Typography.Paragraph>
              We sent a token to{" "}
              <Typography.Text strong>
                {sentDetails.phoneNumberOrEmail}
              </Typography.Text>
              . Click the link below to change your password with the token.
              <br />
              <Link to={appBasePaths.changePassword}>
                <Typography.Text
                  className={cx(
                    utilClassNames.typographyWithPrimaryColor,
                    css({
                      textDecoration: "underline",
                    })
                  )}
                >
                  Change password
                </Typography.Text>
              </Link>
            </Typography.Paragraph>
          }
          extra={[
            <Button key="send-again" onClick={() => setSentDetails(null)}>
              Send Again
            </Button>,
          ]}
        />
      </div>
    );
  }

  return (
    <ForgotPasswordForm
      isSubmitting={loading}
      error={error?.message}
      onSubmit={run}
    />
  );
};

export default React.memo(ForgotPasswordContainer);
