import React from "react";
import { Select } from "antd";

export interface IDisabledSelectProps {
  placeholder: string;
}

const DisabledSelect: React.FC<IDisabledSelectProps> = (props) => {
  const { placeholder } = props;
  return <Select disabled placeholder={placeholder}></Select>;
};

export default DisabledSelect;
