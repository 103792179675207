import React from "react";
import ResourcesAPI from "../../api/endpoints/resources";
import { ICountryInfo } from "../../definitions/countries";
import { messages } from "../../definitions/messages";

function useCountries() {
  const [fetching, setFetching] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [countries, setCountries] = React.useState<Array<ICountryInfo> | null>(
    null
  );

  const getCountries = React.useCallback(async () => {
    try {
      setError(null);
      setFetching(true);
      const countries = await ResourcesAPI.getCountryCodesObj();
      setCountries(countries);
    } catch (error: any) {
      setError(error?.message || messages.initComponentError);
    }

    setFetching(false);
  }, []);

  React.useEffect(() => {
    getCountries();
  }, [getCountries]);

  return {
    fetching,
    error,
    countries,
  };
}

export default useCountries;
