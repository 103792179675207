import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import { useHistory } from "react-router";
import UserAPI from "../../api/endpoints/user";
import { appBasePaths } from "../../definitions/paths";
import ResetPasswordForm, {
  IResetPasswordFormValues,
} from "./ResetPasswordForm";

const ResetPasswordContainer: React.FC<{}> = () => {
  const history = useHistory();
  const onSubmit = React.useCallback(
    async (data: IResetPasswordFormValues) => {
      const result = await UserAPI.changePasswordWithCode({
        tokenCode: data.token,
        newPassword: data.password,
      });

      message.success(result.message || "Password changed successfully");
      history.push(appBasePaths.login);
    },
    [history]
  );

  const { loading, error, run } = useRequest(onSubmit, { manual: true });
  return (
    <ResetPasswordForm
      onSubmit={run}
      error={error?.message}
      isSubmitting={loading}
    />
  );
};

export default React.memo(ResetPasswordContainer);
