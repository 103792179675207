import { Divider, Space } from "antd";
import React from "react";
import UserProfileFormContainer from "./UserProfileFormContainer";
import AdminCompanyFormContainer from "./AdminCompanyFormContainer";
import UpdateUserPasswordFormContainer from "./UpdateUserPasswordFormContainer";
import { useDispatch, useSelector } from "react-redux";
import SessionSelectors from "../../redux/session/selectors";
import SessionActions from "../../redux/session/actions";
import { messages } from "../../definitions/messages";
import UserAPI from "../../api/endpoints/user";
import { logoutOnError } from "../utils/utils";
import { css } from "@emotion/css";
import ImageAndUploadAvatar from "../utils/ImageAndUploadAvatar";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import { appDimensions } from "../utils/theme";
import Logo from "../utils/Logo";
import { IAppState } from "../../redux/types";
import { ISessionState } from "../../redux/session/types";

export interface IProfilePageProps {}

const classes = {
  row: css({
    display: "flex",
    width: "100%",
  }),
  section: css({
    width: "100%",
    maxWidth: "700px",
    margin: "auto",
    display: "flex",
  }),
  avatar: css({
    width: appDimensions.imageWidth,
    height: appDimensions.imageWidth,
  }),
};

const ProfilePage: React.FC<IProfilePageProps> = (props) => {
  const user = useSelector(SessionSelectors.assertGetUserProfile);
  const company = useSelector(SessionSelectors.assertGetCompanyProfile);
  const sessionData = useSelector<IAppState, ISessionState>(
    (state) => state.session
  );

  const dispatch = useDispatch();
  const onCompleteUpdateProfile = React.useCallback(async () => {
    await logoutOnError(dispatch, async () => {
      const updatedProfile = await UserAPI.getUserProfile();
      dispatch(
        SessionActions.update({
          userProfile: updatedProfile,
        })
      );
    });
  }, [dispatch]);

  const onCompleteUpdateAdminCompany = React.useCallback(async () => {
    await logoutOnError(dispatch, async () => {
      const updatedCompany = await UserAPI.getCompanyProfile();
      dispatch(
        SessionActions.update({
          companyProfile: updatedCompany,
        })
      );
    });
  }, [dispatch]);

  const onCompleteUpdatePassword = React.useCallback(() => {
    dispatch(SessionActions.logoutUser());
  }, [dispatch]);

  const onUploadUserAvatar = React.useCallback(() => {
    dispatch(
      SessionActions.update({
        avatarLastUpdatedAt: Date.now().toString(),
      })
    );
  }, [dispatch]);

  const onUploadCompanyAvatar = React.useCallback(() => {
    dispatch(
      SessionActions.update({
        companyAvatarLastUpdatedAt: Date.now().toString(),
      })
    );
  }, [dispatch]);

  const userAvatarSrc = getLoadSingleImagePath(
    user.EmployeeId,
    null,
    null,
    sessionData.userAvatarLastUpdatedAt
  );

  const companyAvatarSrc = getLoadSingleImagePath(
    company.CompanyId,
    null,
    null,
    sessionData.companyAvatarLastUpdatedAt
  );

  return (
    <div>
      <Divider orientation="left">Your Profile</Divider>
      <Space size="large" className={classes.section} align="start">
        <ImageAndUploadAvatar
          id={user.EmployeeId}
          messages={{
            uploading: messages.uploadingProfilePicture,
            successful: messages.profilePictureUploaded,
            failed: messages.profilePictureUploadFailed,
          }}
          alt={`${user.FullName}'s profile picture`}
          src={userAvatarSrc}
          width={appDimensions.imageWidth}
          onCompleteUpload={onUploadUserAvatar}
          className={classes.avatar}
          imageKey={userAvatarSrc}
        />
        <UserProfileFormContainer
          profile={user}
          onCompleteUpdateProfile={onCompleteUpdateProfile}
        />
      </Space>
      <Divider orientation="left">Company Profile</Divider>
      <Space size="large" className={classes.section} align="start">
        <ImageAndUploadAvatar
          id={company.CompanyId}
          fallbackNode={
            <Logo className={css({ width: appDimensions.imageWidth })} />
          }
          messages={{
            uploading: messages.uploadingMovebotImage,
            successful: messages.movebotImageUploaded,
            failed: messages.movebotImageUploadFailed,
          }}
          alt={messages.movebotLogo}
          src={companyAvatarSrc}
          width={appDimensions.imageWidth}
          onCompleteUpload={onUploadCompanyAvatar}
          className={classes.avatar}
          imageKey={companyAvatarSrc}
        />
        <AdminCompanyFormContainer
          company={company}
          onCompleteUpdateAdminCompany={onCompleteUpdateAdminCompany}
        />
      </Space>
      <Divider orientation="left">Password</Divider>
      <Space size="large" className={classes.section} align="start">
        <div className={classes.avatar}></div>
        <div style={{ padding: "16px 16px" }}>
          <UpdateUserPasswordFormContainer
            onCompleteUpdatePassword={onCompleteUpdatePassword}
          />
        </div>
      </Space>
    </div>
  );
};

export default React.memo(ProfilePage);
