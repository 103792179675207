import { Column } from "@ant-design/charts";
import React from "react";
import { IAnalysisOrderItemCategoryStat } from "../../definitions/analysis";

export interface IAnalysisOrderItemCategoryStatsProps {
  stats: IAnalysisOrderItemCategoryStat[];
}

const AnalysisOrderItemCategoryStats: React.FC<
  IAnalysisOrderItemCategoryStatsProps
> = (props) => {
  const { stats } = props;
  const config: React.ComponentProps<typeof Column> = {
    data: stats,
    xField: "CategoryName",
    yField: "Count",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      CategoryName: {
        alias: "Category Name",
      },
    },
  };

  return <Column {...config} />;
};

export default AnalysisOrderItemCategoryStats;
