import { useRequest } from "ahooks";
import { Select, Typography } from "antd";
import React from "react";
import GroupPolicyAPI from "../../api/endpoints/group-policy";
import {
  GroupPolicyStatus,
  IShortGroupPolicy,
} from "../../definitions/group-policy";
import InlineState from "../utils/InlineState";
import MiddleDot from "../utils/MiddleDot";

export interface IPolicyInputProps {
  value?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  className?: string;
  roleType?: string;
  allowEmpty: boolean;
  onChange: (val?: IShortGroupPolicy) => void;
}

const kEmptyKey = "empty";

const PolicyInput: React.FC<IPolicyInputProps> = (props) => {
  const {
    value,
    roleType,
    disabled,
    multiSelect,
    className,
    allowEmpty,
    onChange,
  } = props;

  const load = React.useCallback(
    async (inputRoleType?: string) => {
      return await GroupPolicyAPI.getShortGroupPolicies({
        roleType: inputRoleType || roleType,
      });
    },
    [roleType]
  );

  const { loading, error, data, run } = useRequest(load);

  React.useEffect(() => {
    run(roleType);
  }, [roleType, run]);

  const optionNodes: React.ReactNode[] = [];

  if (allowEmpty) {
    optionNodes.push(
      <Select.Option key={kEmptyKey} value={kEmptyKey}>
        Empty
      </Select.Option>
    );
  }

  data?.forEach((permission) =>
    optionNodes.push(
      <Select.Option
        key={permission.GroupPolicyId}
        value={permission.GroupPolicyId}
        disabled={!(permission.Status.Name === GroupPolicyStatus.Active)}
      >
        {permission.GroupPolicyName}
        {!(permission.Status.Name === GroupPolicyStatus.Active) && (
          <span>
            <MiddleDot />
            <Typography.Text type="danger">Inactive</Typography.Text>
          </span>
        )}
      </Select.Option>
    )
  );

  return (
    <InlineState
      loading={loading}
      error={error?.message}
      onReload={run}
      loadingText="Loading group policies..."
    >
      <Select
        showSearch
        mode={multiSelect ? "multiple" : undefined}
        maxTagCount="responsive"
        maxTagTextLength={15}
        value={value}
        style={{ width: "100%" }}
        placeholder="Select group policy"
        onChange={(val) => {
          if (val === kEmptyKey) {
            onChange();
          } else {
            const permission = data?.find((item) => item.GroupPolicyId === val);

            if (permission) {
              onChange(permission);
            }
          }
        }}
        disabled={disabled}
        className={className}
      >
        {optionNodes}
      </Select>
    </InlineState>
  );
};

export default PolicyInput;
