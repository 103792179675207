import useRequest from "@ahooksjs/use-request";
import { Space, Table, TableColumnType, Typography } from "antd";
import React from "react";
import DashboardAPI from "../../api/endpoints/dashboard";
import { IDashboardRecentOrderUpdate } from "../../definitions/dashboard";
import PageError from "../utils/PageError";
import TableSkeleton from "../utils/TableSkeleton";

const columns: TableColumnType<IDashboardRecentOrderUpdate>[] = [
  {
    title: "Tracking Number",
    dataIndex: "TrackingNumber",
    key: "TrackingNumber",
    width: 200,
  },
  {
    title: "Company Abbreviation",
    dataIndex: "CompanyAbbreviation",
    key: "CompanyAbbreviation",
    width: 200,
  },
  {
    title: "Estimated Arrival Date",
    dataIndex: "EstimatedArrivalDate",
    key: "EstimatedArrivalDate",
    width: 200,
  },
  {
    title: "Delivery Location",
    dataIndex: "DeliveryLocation",
    key: "DeliveryLocation",
    width: 200,
  },
];

const DashboardRecentOrdersUpdate: React.FC<{}> = () => {
  const { error, data, loading, run } = useRequest(
    DashboardAPI.getRecentOrderUpdate
  );
  let contentNode: React.ReactNode = null;

  if (error) {
    contentNode = (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload", onClick: run }]}
      />
    );
  } else if (loading || !data) {
    contentNode = <TableSkeleton columns={columns} />;
  } else {
    contentNode = (
      <Table
        bordered
        size="small"
        dataSource={data}
        columns={columns}
        rowKey={(row) => row.TrackingNumber}
        pagination={false}
      />
    );
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Title level={5}>Recent Orders</Typography.Title>
      {contentNode}
    </Space>
  );
};

export default DashboardRecentOrdersUpdate;
