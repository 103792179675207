import {
  IPartnerWithUsRequest,
  ITalkToUsRequest,
} from "../../definitions/requests";
import {
  IPaginatedDataInput,
  IPaginatedDataResult,
  TicketStatus,
} from "../../definitions/utils";
import { netAddrs } from "../addr";
import { invokeEndpointWithAuth } from "../invokeREST";
import { selectEndpointParam, setEndpointParam } from "../utils";

const requestsURL = `${netAddrs.serverAddr}/LandingPage`;
const getPartnersURL = `${requestsURL}/PartnersInfo`;
const searchPartnersURL = `${requestsURL}/SearchPartnersInfo`;
const getTalkToUsRequestsURL = `${requestsURL}/TalkToUsInfo`;
const searchTalkToUsRequestsURL = `${requestsURL}/SearchTalkToUsInfo`;

// TODO: Ask Kingsley if requests have their own page?
// const messageTalkToUsURL = `${landingPageURL}/TalkToUs`;
// const messagePartnerURL = `${landingPageURL}/PartnerWithUs`;

export interface IGetPartnerWithUsRequestsEndpointResult
  extends IPaginatedDataResult<IPartnerWithUsRequest> {}

export interface IGetPartnerWithUsRequestsEndpointParams
  extends IPaginatedDataInput {
  status?: TicketStatus;
}

async function getPartnerWithUsRequests(
  props: IGetPartnerWithUsRequestsEndpointParams = {}
): Promise<IGetPartnerWithUsRequestsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "status", props.status);

  const endpointURL = selectEndpointParam(getPartnersURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetPartnerWithUsRequestsEndpointResult>({
      path: endpointURL,
      method: "GET",
    });

  return result;
}

export interface ISearchPartnerWithUsRequestsEndpointParams {
  query: string;
}

async function searchPartnerWithUsRequests(
  props: ISearchPartnerWithUsRequestsEndpointParams
): Promise<IPartnerWithUsRequest[]> {
  const params = new URLSearchParams();
  setEndpointParam(params, "query", props.query);
  const endpointURL = selectEndpointParam(searchPartnersURL, params, props);
  const result = await invokeEndpointWithAuth<IPartnerWithUsRequest[]>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export interface IGetTalkToUseRequestsEndpointResult
  extends IPaginatedDataResult<ITalkToUsRequest> {}

export interface IGetTalkToUsRequestsEndpointParams
  extends IPaginatedDataInput {
  status?: TicketStatus;
}

async function getTalkToUsRequests(
  props: IGetTalkToUsRequestsEndpointParams = {}
): Promise<IGetTalkToUseRequestsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "status", props.status);

  const endpointURL = selectEndpointParam(
    getTalkToUsRequestsURL,
    params,
    props
  );
  const result =
    await invokeEndpointWithAuth<IGetTalkToUseRequestsEndpointResult>({
      path: endpointURL,
      method: "GET",
    });

  return result;
}

export interface ISearchTalkToUsRequestsEndpointParams {
  query: string;
}

async function searchTalkToUsRequests(
  props: ISearchTalkToUsRequestsEndpointParams
): Promise<ITalkToUsRequest[]> {
  const params = new URLSearchParams();
  setEndpointParam(params, "query", props.query);
  const endpointURL = selectEndpointParam(
    searchTalkToUsRequestsURL,
    params,
    props
  );

  const result = await invokeEndpointWithAuth<ITalkToUsRequest[]>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export default class RequestsAPI {
  static getPartnerWithUsRequests = getPartnerWithUsRequests;
  static searchPartnerWithUsRequests = searchPartnerWithUsRequests;
  static getTalkToUsRequests = getTalkToUsRequests;
  static searchTalkToUsRequests = searchTalkToUsRequests;
}
